import React, { useEffect, useState } from 'react'
import { TextField, CircularProgress, InputAdornment } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useCheckUsernameExist } from 'hooks/api/userHooks'

/* eslint-disable react/jsx-props-no-spreading */
export function UsernameField({ initialUsername = '' }) {
  const [isChecking, setIsChecking] = useState(false)
  const {
    setError,
    clearErrors,
    register,
    formState: { errors },
    watch,
    setValue,
    trigger,
  } = useFormContext()

  const username = watch('username')
  const { trigger: checkUsernameExist } = useCheckUsernameExist(username)

  useEffect(() => {
    const handleUsernameExistenceCheck = async () => {
      if (username && username !== initialUsername) {
        setIsChecking(true)
        try {
          const data = await checkUsernameExist()
          setIsChecking(false)
          if (data.username_exists) {
            setError('username', {
              type: 'manual',
              message: 'Username already taken',
            })
          } else if (!errors.username) {
            clearErrors('username')
          }
        } catch {
          setIsChecking(false)
          setError('username', {
            type: 'manual',
            message: 'Username already taken',
          })
        }
      }
    }

    const typingTimeout = setTimeout(handleUsernameExistenceCheck, 300)

    return () => clearTimeout(typingTimeout)
  }, [
    username,
    initialUsername,
    setError,
    clearErrors,
    checkUsernameExist,
    errors.username,
  ])

  useEffect(() => {
    setValue('username', initialUsername)
  }, [initialUsername, setValue])

  const handleUsernameChange = async (e) => {
    const newUsername = e.target.value.replace(/\s+/g, '_').toLowerCase()
    setValue('username', newUsername)
    clearErrors('username')
    const isValid = await trigger('username')

    if (isValid) {
      setTimeout(() => {
        checkUsernameExist()
      }, 300)
    }
  }

  return (
    <TextField
      required
      fullWidth
      error={!!errors.username}
      helperText={errors.username ? errors.username.message : ''}
      label="Username"
      value={username}
      InputProps={{
        readOnly: watch('readOnly'),
        endAdornment: isChecking && (
          <InputAdornment position="end">
            <CircularProgress size={20} />
          </InputAdornment>
        ),
      }}
      {...register('username', {
        required: 'Username is required',
        minLength: {
          value: 3,
          message: 'Username must be at least 3 characters long',
        },
        maxLength: {
          value: 30,
          message: 'Username cannot exceed 30 characters',
        },
        pattern: {
          value: /^[A-Za-z0-9_]+$/,
          message:
            'Username can only contain latin letters, numbers, and underscores',
        },
        setValueAs: (v) => v.replace(/\s+/g, '_').toLowerCase(),
      })}
      onChange={handleUsernameChange}
    />
  )
}
