import React, { useState, useEffect } from 'react'

import { Grid, Paper, Typography } from '@mui/material'
import Pagination from '@mui/material/Pagination'

import { useOrganizations } from 'hooks/api/organizationHooks'
import { useCurrentUser } from 'hooks/cookies'
import { MainWrapper } from 'components/layout/MainWrapper/MainWrapper'
import { useThemeSwitcher } from 'components/ThemeProvider/ThemeProvider'
import { SelectTournamentType } from 'components/SelectTournamentType/SelectTournamentType'

import styles from './CreateTournament.module.scss'

export function CreateTournament() {
  const { themeMode } = useThemeSwitcher()
  const themeClass = themeMode === 'dark' ? styles.darkTheme : styles.lightTheme

  const limit = 6
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(0)
  const offset = (currentPage - 1) * limit

  const [user] = useCurrentUser()

  const { data } = useOrganizations({ adminId: user?.id, limit, offset })
  const count = Math.ceil(total / limit)

  useEffect(() => {
    if (data?.total) {
      setTotal(data.total)
    }
  }, [data])

  const handlePageChange = (_, page) => {
    setCurrentPage(page)
  }

  const [openDialog, setOpenDialog] = useState(false)
  const [selectedOrganization, setSelectedOrganization] = useState(null)

  const handleOpenDialog = (organization) => {
    setSelectedOrganization(organization)
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setSelectedOrganization(null)
    setOpenDialog(false)
  }

  return (
    <MainWrapper>
      <Grid mb={4} container spacing={2}>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography variant="h5">
            Select organization for new tournament
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {data?.data.map((organization) => (
              <Grid item xs={12} md={4} key={organization.id}>
                <OrganizationBlock
                  organization={organization}
                  onSelect={handleOpenDialog}
                  themeClass={themeClass}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Pagination
            count={count}
            page={currentPage}
            shape="rounded"
            onChange={handlePageChange}
            siblingCount={0}
            size="medium"
          />
        </Grid>
      </Grid>

      <SelectTournamentType
        openSelectTournamentTypeDialog={openDialog}
        handleCloseSelectTournamentTypeDialog={handleCloseDialog}
        organizationId={selectedOrganization?.id}
      />
    </MainWrapper>
  )
}

function OrganizationBlock({ organization, onSelect, themeClass }) {
  return (
    <Grid
      onClick={() => onSelect(organization)}
      className={styles.organizationBlockLink}
    >
      <Paper className={`${styles.organizationBlock} ${themeClass}`}>
        <Typography variant="h6">{organization.name}</Typography>
        <Grid className={styles.organizationType}>
          <Typography variant="body2">
            {organization.has_permanent_location ? 'club' : 'community'}
          </Typography>
        </Grid>
      </Paper>
    </Grid>
  )
}
