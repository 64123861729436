import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import { useCookies } from 'react-cookie'

import {
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
  Checkbox,
  Alert,
  FormControlLabel,
} from '@mui/material'

import styles from 'styles/main.module.scss'

import { useRegister } from 'hooks/api/authHooks'

import { MainWrapper } from 'components/layout/MainWrapper/MainWrapper'
import { UsernameField } from 'components/Fields/Username'
import { GenderField } from 'components/Fields/Gender'

/* eslint-disable react/jsx-props-no-spreading */
export function Register() {
  const navigate = useNavigate()
  const [cookies, setCookies] = useCookies(['user'])

  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (cookies.user) {
      setOpenSnackbar(true)
    }
  }, [cookies.user])

  const { trigger } = useRegister()

  const formOptions = cookies.user
    ? {
        defaultValues: JSON.parse(atob(cookies.user)),
      }
    : {}

  const methods = useForm(formOptions)

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = methods

  const isOrganizationAdmin = watch('isOrganizationAdmin', false)

  const handleRegister = (data) => {
    const { name, surname, username, gender, phone, email, password } = data

    const processedData = {
      name,
      surname: surname || null,
      username,
      gender,
      phone: phone || null,
      email: email || null,
      password,
    }

    trigger(processedData, {
      onSuccess: (data) => {
        setCookies('user', btoa(JSON.stringify(data)), {
          path: '/',
        })
        if (isOrganizationAdmin) {
          navigate('/create-organization')
        } else {
          navigate('/')
        }
      },
      onError: (data) => setErrorMessage(data.info.error),
    })
  }

  return (
    <MainWrapper
      maxWidth="sm"
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    >
      <FormProvider {...methods}>
        <FormControl
          component="form"
          onSubmit={handleSubmit(handleRegister)}
          fullWidth
          noValidate
        >
          <Grid container spacing={2} mt={{ xs: 2, md: 20 }}>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Typography variant="h5">Register</Typography>
            </Grid>

            {openSnackbar && (
              <Grid item xs={12}>
                <Alert severity="info">
                  User not found. Please proceed with registration.
                </Alert>
              </Grid>
            )}

            <Grid item xs={12} md={6}>
              <TextField
                required
                style={{ width: '100%' }}
                error={!!errors.name}
                helperText={errors.name ? errors.name.message : ''}
                label="Name"
                {...register('name', { required: 'Name is required' })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                style={{ width: '100%' }}
                label="Surname"
                {...register('surname')}
              />
            </Grid>

            <Grid item xs={12}>
              <UsernameField />
            </Grid>

            <Grid item xs={12}>
              <GenderField />
            </Grid>

            <Grid item xs={12}>
              <TextField
                style={{ width: '100%' }}
                label="Phone"
                {...register('phone')}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                style={{ width: '100%' }}
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ''}
                label="Email"
                {...register('email', {
                  pattern: {
                    value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                })}
                onChange={(e) => {
                  const transformedValue = e.target.value
                    .replace(/\s+/g, '_')
                    .toLowerCase()
                  e.target.value = transformedValue
                  register('email').onChange(e)
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                style={{ width: '100%' }}
                error={!!errors.password}
                helperText={errors.password ? errors.password.message : ''}
                type="password"
                label="Password"
                {...register('password', { required: 'Password is required' })}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <FormControlLabel
                control={<Checkbox {...register('privacyPolicy')} />}
                label={
                  <Typography variant="body1">
                    I agree to the{' '}
                    <Link to="/privacy-policy" className={styles.greyLink}>
                      Privacy Policy
                    </Link>
                  </Typography>
                }
              />
            </Grid>

            <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }} />
            <Grid item xs={12} md={4}>
              <Button
                style={{ width: '100%' }}
                type="submit"
                disabled={
                  !watch('privacyPolicy') || Object.keys(errors).length > 0
                }
                variant="contained"
              >
                Register
              </Button>
            </Grid>
            <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }} />

            <Grid item xs={12} textAlign="right">
              <Link to="/login" className={styles.greyLink}>
                Already have an account? Login
              </Link>
            </Grid>
          </Grid>
        </FormControl>
      </FormProvider>
    </MainWrapper>
  )
}
