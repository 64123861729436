import { useCookies } from 'react-cookie'

import { decodeFromBase64 } from 'utils'

export function useCurrentUser() {
  const [cookies, setCookies, clearCookies] = useCookies(['user'])
  if (cookies.user) {
    const user = JSON.parse(decodeFromBase64(cookies.user))
    return [user, setCookies, clearCookies]
  }
  return [undefined, setCookies, clearCookies]
}

export function useForceUpdateUserCookie() {
  const [, setCookies, clearCookies] = useCookies(['user'])

  const updateCookie = (userData) => {
    if (userData) {
      setCookies('user', btoa(JSON.stringify(userData)), {
        path: '/',
      })
    } else {
      clearCookies('user', { path: '/' })
    }
  }

  return updateCookie
}
