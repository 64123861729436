import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'

import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  List,
  ListItem,
  Tab,
  Typography,
} from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import EditIcon from '@mui/icons-material/Edit'
import InfoIcon from '@mui/icons-material/Info'
import ListAltIcon from '@mui/icons-material/ListAlt'

import {
  MainWrapper,
} from 'components/layout/MainWrapper/MainWrapper'
import { AmericanoTeamLeaderboardTable } from 'components/LeaderboardTable/LeaderboardTable'
import {
  TournamentRegisteredTeams,
  TournamentRegisteredWaitlistTeams,
  TournamentRegisteredLeftPlayers,
} from 'components/TournamentRegisteredPlayers/TournamentRegisteredPlayers'
import { useThemeSwitcher } from 'components/ThemeProvider/ThemeProvider'
import { DeleteTournamentDialog } from 'components/DeleteTournamentDialog/DeleteTournamentDialog'
import { TournamentInfo } from 'components/TournamentInfo/TournamentInfo'
import { UpdateTournamentInfo } from 'components/TournamentInfo/UpdateTournamentInfo'
import {
  RoundCourt,
  PlayersSkippingRound,
} from 'components/RoundCourt/RoundCourt'

import {
  useAmericanoTeamLeaderboard,
  useFinishTournament,
  useUpdateTournament,
  usePseudoDeleteTournament,
  useIncreaseTournamentCycleCount,
} from 'hooks/api/tournamentHooks'

import { useOrganization } from 'hooks/api/organizationHooks'

import { useCurrentUser } from 'hooks/cookies'

import { isAdmin, getPlayersWhoSkipARound } from 'utils'

/* eslint-disable react/jsx-props-no-spreading */
export function AmericanoTeamInProgress({ data: tournamentData, mutate }) {
  const navigate = useNavigate()

  const { themeMode } = useThemeSwitcher()

  const {
    organization_id,
    teams,
    waitlist_teams,
    left_users,
    schedule,
  } = tournamentData

  const score_type = 'points'
  const getLocalStorageKey = (tournamentId) =>
    `currentRoundNumber_${tournamentId}`
  const clearOldLocalStorageData = () => {
    const now = new Date().getTime()
    for (let i = 0; i < localStorage.length; i += 1) {
      const key = localStorage.key(i)
      if (key.startsWith('currentRoundNumber_')) {
        const timestamp = parseInt(localStorage.getItem(`${key}_timestamp`), 10)
        if (now - timestamp > 24 * 60 * 60 * 1000) {
          // 24 hours
          localStorage.removeItem(key)
          localStorage.removeItem(`${key}_timestamp`)
        }
      }
    }
  }

  const { data: organizationData } = useOrganization(organization_id)

  const { tournamentId } = useParams()
  const tournamentIdInt = parseInt(tournamentId, 10)
  const [currentUser] = useCurrentUser()

  const admin = isAdmin(currentUser)
  const organizationAdmin =
    currentUser?.administered_organizations?.data?.length >= 1
  const adminOrOrganizationAdmin = admin || organizationAdmin

  const initialLeaderBoardOffMode =
    localStorage.getItem('leaderBoardOffMode') === 'true'

  const initialRoundNumber = (() => {
    const storedValue = localStorage.getItem(getLocalStorageKey(tournamentId))
    if (storedValue) {
      const parsedValue = parseInt(storedValue, 10)
      if (!Number.isNaN(parsedValue)) {
        return parsedValue
      }
    }
    return tournamentData?.round_number || 0
  })()

  const [currentUpdatedScheduleRound, setCurrentUpdatedScheduleRound] =
    useState(initialRoundNumber)

  const [leaderBoardOffMode, setLeaderBoardOffMode] = useState(
    initialLeaderBoardOffMode
  )
  const [updatedRound, setUpdatedRound] = useState()
  const [openFinishTournamentModal, setOpenFinishTournamentModal] =
    useState(false)
  const [openDeleteTournamentModal, setOpenDeleteTournamentModal] =
    useState(false)
  const [openIncreaseCycleCountModal, setOpenIncreaseCycleCountModal] =
    useState(false)
  const [tabNumber, setTabValue] = useState('1')

  const initialAddressIndexRef = useRef(0)
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(0)

  const [errorMessage, setErrorMessage] = useState('')

  const tournamentAddressId = tournamentData?.address?.id

  const playersWhoSkipARound = getPlayersWhoSkipARound({
    round: schedule?.rounds[currentUpdatedScheduleRound],
    teams,
  })

  useEffect(() => {
    if (organizationData?.addresses && tournamentAddressId) {
      const addressIndex = organizationData.addresses.findIndex(
        (address) => address.id === tournamentAddressId
      )

      setSelectedAddressIndex(addressIndex >= 0 ? addressIndex : 0)
      initialAddressIndexRef.current = addressIndex >= 0 ? addressIndex : 0
    }
  }, [organizationData, tournamentAddressId])

  useEffect(() => {
    localStorage.setItem('currentRoundNumber', currentUpdatedScheduleRound)
  }, [currentUpdatedScheduleRound])

  useEffect(() => {
    clearOldLocalStorageData()
  }, [])

  useEffect(() => {
    localStorage.setItem(
      getLocalStorageKey(tournamentId),
      currentUpdatedScheduleRound.toString()
    )
    localStorage.setItem(
      `${getLocalStorageKey(tournamentId)}_timestamp`,
      new Date().getTime().toString()
    )
  }, [currentUpdatedScheduleRound, tournamentId])

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    setValue,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      organization_name: tournamentData?.organization_name || '',
      readOnly: true,
      type: tournamentData?.type || 'americano_team',
      tournamentName: tournamentData?.name || '',
      americanoMaxScore: tournamentData?.americano_max_score || 0,
      startTime: tournamentData?.start_time
        ? tournamentData?.start_time
        : Date.now(),
      endTime: tournamentData?.end_time ? tournamentData?.end_time : Date.now(),
      description: tournamentData?.description || '',
      maxUsers: tournamentData?.max_users || 0,
      cycleCount: tournamentData?.cycle_count || 1,
    },
  })

  const collectChanges = () => {
    const currentValues = {
      name: watch('name'),
      cycle_count: watch('cycleCount'),
      start_time: watch('startTime'),
      end_time: watch('endTime'),
      description: watch('description'),
    }

    const changes = Object.keys(currentValues).reduce((acc, key) => {
      if (currentValues[key] !== tournamentData[key]) {
        if (typeof currentValues[key] === 'number') {
          acc[key] = Number(currentValues[key])
        } else {
          acc[key] = currentValues[key]
        }
      }
      return acc
    }, {})

    return changes
  }

  const handleChangeTab = (_, tabNumber) => {
    setTabValue(tabNumber)
  }

  const {
    data: tournamentLeaderboardData,
    loading: tournamentLeaderboardLoading,
    mutate: mutateLeaderboard,
  } = useAmericanoTeamLeaderboard(tournamentId)

  const { trigger: finishTournamentTrigger } = useFinishTournament()
  const { trigger: updateTournamentTrigger } = useUpdateTournament()
  const { trigger: pseudoDeleteTournamentTrigger } = usePseudoDeleteTournament()
  const { trigger: increaseCycleCountTrigger } =
    useIncreaseTournamentCycleCount()

  useEffect(() => {
    if (tournamentData?.round_number) {
      setUpdatedRound(
        tournamentData?.schedule.rounds[tournamentData.round_number]
      )
    }

    mutateLeaderboard()
  }, [tournamentData?.round_number])

  useEffect(() => {
    localStorage.setItem('leaderBoardOffMode', leaderBoardOffMode.toString())
  }, [leaderBoardOffMode])

  const toggleLeaderBoardOffMode = () => {
    setLeaderBoardOffMode((prevMode) => !prevMode)
  }

  if (tournamentLeaderboardLoading) {
    return <MainWrapper>loading...</MainWrapper>
  }

  const handleFinishTournament = (tournamentId) => {
    finishTournamentTrigger(
      {
        id: parseInt(tournamentId, 10),
      },
      {
        onSuccess: () => {
          mutate()
          window.location.reload()
        },
        onError: (data) => setErrorMessage(data.info.error),
      }
    )
  }

  const handleUpdateTournament = () => {
    const changes = collectChanges()

    updateTournamentTrigger(
      {
        id: Number(tournamentId),
        ...changes,
      },
      {
        onSuccess: () => {
          mutate()
          setValue('readOnly', true)
        },
        onError: (data) => setErrorMessage(data.info),
      }
    )
  }

  const handleCancelUpdate = () => {
    reset()
  }

  const handlePseudoDeleteTournament = () => {
    const tournamentIDInt = parseInt(tournamentId, 10)

    pseudoDeleteTournamentTrigger(
      {
        id: tournamentIDInt,
      },
      {
        onSuccess: () => {
          navigate('/')
        },
      }
    )
  }

  const handleOpenFinishTournamentModal = () => {
    setOpenFinishTournamentModal(true)
  }

  const handleOpenDeleteTournamentModal = () => {
    setOpenDeleteTournamentModal(true)
  }

  const handleCloseFinishTournamentModal = () => {
    setOpenFinishTournamentModal(false)
  }

  const handleCloseDeleteTournamentModal = () => {
    setOpenDeleteTournamentModal(false)
  }

  const handleConfirmFinishTournament = (tournamentId) => {
    handleFinishTournament(tournamentId)
    setOpenFinishTournamentModal(false)
  }

  const handleConfirmDeleteTournament = () => {
    handlePseudoDeleteTournament()
    setOpenDeleteTournamentModal(false)
  }

  const handleAddressChange = (event) => {
    setSelectedAddressIndex(event.target.value)
  }

  const handleMoreRoundsClick = () => {
    setOpenIncreaseCycleCountModal(true)
  }

  const handleCloseConfirmIncreaseCycleCountDialog = () => {
    setOpenIncreaseCycleCountModal(false)
  }

  const handleIncreaseCycleCount = () => {
    increaseCycleCountTrigger(
      { id: tournamentIdInt },
      {
        onSuccess: () => {
          mutate().then((updatedData) => {
            if (
              updatedData &&
              updatedData.schedule &&
              updatedData.schedule.rounds &&
              updatedData.schedule.rounds.length >= currentUpdatedScheduleRound
            ) {
              setCurrentUpdatedScheduleRound((prev) => prev + 1)
            }
          })
        },
        onError: (data) => setErrorMessage(data.info),
      }
    )
  }

  const handleConfirmIncreaseCycleCount = () => {
    handleIncreaseCycleCount()
    setOpenIncreaseCycleCountModal(false)
  }

  const handleMutateData = () => {
    mutate()
    mutateLeaderboard()
  }

  return (
    <MainWrapper errorMessage={errorMessage} setErrorMessage={setErrorMessage}>
      <FormProvider
        register={register}
        handleSubmit={handleSubmit}
        control={control}
        formState={{ errors }}
        watch={watch}
        reset={reset}
        setValue={setValue}
      >
        <FormControl
          component="form"
          onSubmit={handleSubmit(handleUpdateTournament)}
          fullWidth
        >
          <Grid item md={2} sx={{ display: { xs: 'none', md: 'block' } }} />
          <Grid item xs={12} md={4} mt={2} mb={2} sx={{ textAlign: 'center' }}>
            <Typography variant="h5">{watch('tournamentName')}</Typography>
          </Grid>

          <Container maxWidth="md" sx={{ padding: 0 }}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={tabNumber}>
                <Container
                  sx={{
                    '@media (min-width: 0px)': {
                      paddingLeft: 0,
                      paddingRight: 0,
                    },
                  }}
                >
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChangeTab}>
                      <Tab
                        label="Schedule"
                        value="1"
                        sx={{ paddingLeft: 0 }}
                        icon={<ListAltIcon />}
                        iconPosition="start"
                      />
                      <Tab
                        label="Info"
                        value="2"
                        icon={<InfoIcon />}
                        iconPosition="start"
                      />
                    </TabList>
                  </Box>
                </Container>

                <TabPanel value="1" sx={{ padding: 0 }}>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    mt={1}
                  >
                    <Grid
                      item
                      xs={12}
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      <Button
                        onClick={toggleLeaderBoardOffMode}
                        variant="contained"
                      >
                        {leaderBoardOffMode
                          ? 'Show Leaderboard'
                          : 'Hide Leaderboard'}
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    md={2}
                    sx={{ display: { xs: 'none', md: 'block' } }}
                  />

                  <Grid mt={{ xs: 0, md: 0 }} container spacing={2} mb={4}>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: leaderBoardOffMode ? 'none' : 'block' }}
                    >
                      <AmericanoTeamLeaderboardTable
                        leaderboard={tournamentLeaderboardData}
                        themeMode={themeMode}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      mt={leaderBoardOffMode ? 0 : 2}
                      sx={{ textAlign: 'center' }}
                    >
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        {adminOrOrganizationAdmin ? (
                          <>
                            <Grid item xs={3} sx={{ textAlign: 'left' }}>
                              <ArrowBackIcon
                                onClick={() => {
                                  setCurrentUpdatedScheduleRound(
                                    (prev) => prev - 1
                                  )
                                }}
                                disabled={currentUpdatedScheduleRound === 0}
                                style={{
                                  cursor: 'pointer',
                                  visibility:
                                    currentUpdatedScheduleRound === 0
                                      ? 'hidden'
                                      : 'visible',
                                }}
                              />
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: 'center' }}>
                              <Typography variant="h5">
                                {`Round: ${currentUpdatedScheduleRound + 1} / ${
                                  schedule.rounds.length
                                }`}
                              </Typography>
                            </Grid>
                            {currentUpdatedScheduleRound + 1 >=
                            schedule.rounds.length ? (
                              <Grid
                                item
                                xs={3}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                <Button
                                  onClick={handleMoreRoundsClick}
                                  type="button"
                                  color="primary"
                                  endIcon={<ArrowForwardIcon />}
                                  sx={{
                                    minWidth: 'auto',
                                  }}
                                >
                                  More Rounds
                                </Button>
                              </Grid>
                            ) : (
                              <Grid item xs={3} sx={{ textAlign: 'right' }}>
                                <ArrowForwardIcon
                                  onClick={() => {
                                    setCurrentUpdatedScheduleRound(
                                      (prev) => prev + 1
                                    )
                                  }}
                                  disabled={
                                    currentUpdatedScheduleRound + 1 >=
                                    schedule.rounds.length
                                  }
                                  style={{
                                    cursor: 'pointer',
                                    visibility:
                                      currentUpdatedScheduleRound + 1 >=
                                      schedule.rounds.length
                                        ? 'hidden'
                                        : 'visible',
                                  }}
                                />
                              </Grid>
                            )}
                          </>
                        ) : (
                          <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <Typography variant="h5">
                              {`Round: ${currentUpdatedScheduleRound + 1} / ${
                                schedule.rounds.length
                              }`}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container spacing={2} justifyContent="center">
                        {schedule.rounds[
                          currentUpdatedScheduleRound
                        ].courts.map((_, courtNumber) => (
                          <RoundCourt
                            key={courtNumber}
                            isAdmin={adminOrOrganizationAdmin}
                            roundNumber={currentUpdatedScheduleRound}
                            courtNumber={courtNumber}
                            tournamentData={tournamentData}
                            setUpdatedRound={setUpdatedRound}
                            updatedRound={updatedRound}
                            updateMode={false}
                            scoreType={score_type}
                            themeMode={themeMode}
                            playersWhoSkipARound={playersWhoSkipARound}
                            mutateData={handleMutateData}
                          />
                        ))}
                      </Grid>

                      <Grid item xs={12} mt={2}>
                        <PlayersSkippingRound
                          playersWhoSkipARound={playersWhoSkipARound}
                          themeMode={themeMode}
                        />
                      </Grid>
                    </Grid>

                    {adminOrOrganizationAdmin && (
                      <>
                        <Grid
                          item
                          md={4}
                          sx={{ display: { xs: 'none', md: 'block' } }}
                        />
                        <Grid item xs={12} md={4}>
                          <Button
                            style={{ width: '100%' }}
                            onClick={() => {
                              handleOpenFinishTournamentModal()
                            }}
                            type="button"
                            color="success"
                            disabled={false}
                            endIcon={<CheckCircleIcon />}
                            variant="contained"
                          >
                            Finish tournament
                          </Button>
                        </Grid>
                        <Grid
                          item
                          md={4}
                          sx={{ display: { xs: 'none', md: 'block' } }}
                        />
                      </>
                    )}
                  </Grid>
                </TabPanel>

                <TabPanel value="2" sx={{ padding: 0 }}>
                  {watch('readOnly') ? (
                    <Grid container spacing={2} mb={4}>
                      <Grid item xs={12} mt={2}>
                        <TournamentInfo
                          tournamentData={tournamentData}
                          organizationData={organizationData}
                          themeMode={themeMode}
                        />
                      </Grid>

                      {adminOrOrganizationAdmin && (
                        <>
                          <Grid
                            item
                            md={4}
                            sx={{ display: { xs: 'none', md: 'block' } }}
                          />
                          <Grid item xs={12} md={4}>
                            <Button
                              fullWidth
                              onClick={() => setValue('readOnly', false)}
                              type="button"
                              startIcon={<EditIcon />}
                              variant="contained"
                            >
                              Edit
                            </Button>
                          </Grid>
                          <Grid
                            item
                            md={4}
                            sx={{ display: { xs: 'none', md: 'block' } }}
                          />
                        </>
                      )}

                      <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <Typography variant="h5">Registered Teams</Typography>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <TournamentRegisteredTeams
                          onlySwapActions
                          playerCanInteract={false}
                          teams={teams}
                          waitlistTeams={waitlist_teams}
                          maxTeams={teams.length}
                          isAdmin={adminOrOrganizationAdmin}
                          currentUser={currentUser}
                          tournamentId={tournamentIdInt}
                          organizationId={organization_id}
                          exceptionIds={[
                            ...teams.flatMap((team) =>
                              team.users.map((u) => u.id)
                            ),
                            ...waitlist_teams.flatMap((team) =>
                              team.users.map((u) => u.id)
                            ),
                          ]}
                          themeMode={themeMode}
                        />
                      </Grid>

                      {waitlist_teams?.length > 0 && (
                        <>
                          <Grid
                            item
                            xs={12}
                            mt={1}
                            sx={{ textAlign: 'center' }}
                          >
                            <Typography variant="h5">Waitlist</Typography>
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <TournamentRegisteredWaitlistTeams
                              readOnly
                              waitlistTeams={waitlist_teams}
                              isAdmin={adminOrOrganizationAdmin}
                              currentUser={currentUser}
                              themeMode={themeMode}
                            />
                          </Grid>
                        </>
                      )}

                      {left_users && left_users.length > 0 && (
                        <>
                          <Grid
                            item
                            xs={12}
                            mt={1}
                            sx={{ textAlign: 'center' }}
                          >
                            <Typography variant="h5">Players Left</Typography>
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <TournamentRegisteredLeftPlayers
                              leftPlayers={left_users}
                              themeMode={themeMode}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  ) : (
                    <Grid container spacing={2} mb={4}>
                      <Grid item xs={12} mt={2}>
                        <UpdateTournamentInfo
                          organizationData={organizationData}
                          selectedAddressIndex={selectedAddressIndex}
                          handleAddressChange={handleAddressChange}
                          themeMode={themeMode}
                          isAdmin={adminOrOrganizationAdmin}
                        />
                      </Grid>

                      {adminOrOrganizationAdmin && (
                        <>
                          <Grid
                            item
                            md={4}
                            sx={{ display: { xs: 'none', md: 'block' } }}
                          />
                          <Grid item xs={12} md={4}>
                            <Button
                              color="error"
                              fullWidth
                              onClick={handleOpenDeleteTournamentModal}
                              variant="contained"
                            >
                              Delete tournament
                            </Button>
                          </Grid>
                          <Grid
                            item
                            md={4}
                            sx={{ display: { xs: 'none', md: 'block' } }}
                          />

                          <Grid item xs={2} />
                          <Grid item xs={4}>
                            <Button
                              fullWidth
                              onClick={handleCancelUpdate}
                              type="button"
                              variant="contained"
                            >
                              Cancel
                            </Button>
                          </Grid>
                          <Grid item xs={4}>
                            <Button
                              fullWidth
                              type="submit"
                              disabled={!Object.keys(collectChanges()).length}
                              variant="contained"
                            >
                              Submit
                            </Button>
                          </Grid>
                          <Grid item xs={2} />
                        </>
                      )}
                    </Grid>
                  )}
                </TabPanel>
              </TabContext>
            </Box>
          </Container>
        </FormControl>
      </FormProvider>

      <Dialog
        open={openFinishTournamentModal}
        onClose={handleCloseFinishTournamentModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to finish the tournament?
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Once finished, the following will be locked:
          </Typography>
          <List>
            <ListItem>
              <Typography variant="body2">- Rounds results</Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2">
                - You won&apos;t be able to delete the tournament
              </Typography>
            </ListItem>
          </List>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseFinishTournamentModal} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleConfirmFinishTournament(tournamentId)}
            color="primary"
            variant="contained"
            autoFocus
          >
            Finish Tournament
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openIncreaseCycleCountModal}
        onClose={handleCloseConfirmIncreaseCycleCountDialog}
      >
        <DialogTitle>
          Do you want to add more rounds to the tournament?
        </DialogTitle>
        <DialogContent dividers>
          <Typography>
            Its can&apos;t be undone, but you can finish tournament in any
            moment
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseConfirmIncreaseCycleCountDialog}
            color="primary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmIncreaseCycleCount}
            color="success"
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <DeleteTournamentDialog
        openDeleteTournamentModal={openDeleteTournamentModal}
        handleCloseDeleteTournamentModal={handleCloseDeleteTournamentModal}
        handleConfirmDeleteTournament={handleConfirmDeleteTournament}
      />
    </MainWrapper>
  )
}
