import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Button, Grid, Box } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { MainWrapper } from 'components/layout/MainWrapper/MainWrapper';
import notFoundStyles from './NotFound.module.scss';

export function NotFound({ message = 'Page not found' }) {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleGoHome = () => {
    navigate('/');
  };

  const isFirstPage = window.history.length <= 2;

  return (
    <MainWrapper>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh" // Высота вьюпорта, чтобы центрировать вертикально
        className={notFoundStyles.container}
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <ErrorOutlineIcon className={notFoundStyles.icon} />
          </Grid>
          <Grid item>
            <Typography variant="h4" className={notFoundStyles.title} style={{ textAlign: "center" }}>
              Oops! {message}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" className={notFoundStyles.subtitle}>
              We can&apos;t seem to find the page you&apos;re looking for.
            </Typography>
          </Grid>
          <Grid item>
            {isFirstPage ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleGoHome}
                className={notFoundStyles.button}
              >
                Take me home
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleGoBack}
                className={notFoundStyles.button}
              >
                Take me back
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </MainWrapper>
  );
}
