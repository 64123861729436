import React, { useEffect, useState } from 'react'
import {
  Alert,
  Collapse,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  FormControlLabel,
} from '@mui/material'
import { useSearchUsers } from 'hooks/api/userHooks'

import styles from 'styles/main.module.scss'

/* eslint-disable react/jsx-props-no-spreading */
export function UserSearch({
  organizationId,
  callback,
  exceptionIds = [],
  searchString = '',
  setSearchString,
  onlyOrganizationMembers,
  setOnlyOrganizationMembers,
  themeMode,
  open,
}) {
  const [players, setPlayers] = useState([])
  const [errorMessage, setErrorMessage] = useState('')

  const { trigger: searchUsersTrigger } = useSearchUsers({
    searchString,
    limit: Math.max(exceptionIds.length, 5),
    offset: 0,
    organizationId: onlyOrganizationMembers ? organizationId : null,
    is_deleted: false,
  })

  useEffect(() => {
    if (open) {
      searchUsersTrigger(
        {},
        {
          onSuccess: (users) => {
            setPlayers(
              users.data.filter((u) => !exceptionIds.some((id) => u.id === id))
            )
          },
          onError: (e) => setErrorMessage(e.info.error),
        }
      )
    }
  }, [
    open,
    searchString,
    onlyOrganizationMembers,
    searchUsersTrigger,
    exceptionIds,
  ])

  return (
    <>
      <Collapse in={Boolean(errorMessage)}>
        <Alert
          severity="error"
          onClose={() => {
            setErrorMessage('')
          }}
        >
          Error: {errorMessage}
        </Alert>
      </Collapse>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9} mt={1}>
          <TextField
            id="outlined-search"
            label="Player name / surname / username"
            type="search"
            variant="outlined"
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={onlyOrganizationMembers}
                onChange={(e) => setOnlyOrganizationMembers(e.target.checked)}
              />
            }
            label="Only Organization Members"
          />
        </Grid>
        <Grid item xs={12}>
          <TableUsers
            users={players}
            callback={callback}
            themeMode={themeMode}
          />
        </Grid>
      </Grid>
    </>
  )
}

function TableUsers({ users, callback, themeMode }) {
  const themeClass = themeMode === 'dark' ? styles.darkTheme : styles.lightTheme

  return (
    <TableContainer component={Paper} className={themeClass}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Surname</TableCell>
            <TableCell>Username</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.length > 0 ? (
            users.map((p) => (
              <TableRow
                key={p.id}
                onClick={() => {
                  callback(p, true)
                }}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.3)', // This gives a light hover effect
                  },
                }}
              >
                <TableCell>{p.name}</TableCell>
                <TableCell>{p.surname}</TableCell>
                <TableCell>{p.username}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3} align="center">
                No players found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
