import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Grid, Pagination, Typography } from '@mui/material'
import { useOrganizations } from 'hooks/api/organizationHooks'
import { useCurrentUser } from 'hooks/cookies'
import { MainWrapper } from 'components/layout/MainWrapper/MainWrapper'
import { OrganizationBlock } from 'components/OrganizationBlock/OrganizationBlock'
import { useThemeSwitcher } from 'components/ThemeProvider/ThemeProvider'

export function Organizations() {
  const { themeMode } = useThemeSwitcher()

  const limit = 6

  const [currentClubsPage, setCurrentClubsPage] = useState(1)
  const [clubsTotal, setClubsTotal] = useState(0)
  const clubsOffset = (currentClubsPage - 1) * limit

  const [currentCommunitiesPage, setCurrentCommunitiesPage] = useState(1)
  const [communitiesTotal, setCommunitiesTotal] = useState(0)
  const communitiesOffset = (currentCommunitiesPage - 1) * limit

  const [user] = useCurrentUser()
  const navigate = useNavigate()

  const { data: clubsData } = useOrganizations({
    has_permanent_location: true,
    limit,
    offset: clubsOffset,
  })
  const clubsPageCount = Math.ceil(clubsTotal / limit)

  const { data: communitiesData } = useOrganizations({
    has_permanent_location: false,
    limit,
    offset: communitiesOffset,
  })
  const communitiesPageCount = Math.ceil(communitiesTotal / limit)

  useEffect(() => {
    if (clubsData?.total) {
      setClubsTotal(clubsData.total)
    }
  }, [clubsData])

  useEffect(() => {
    if (communitiesData?.total) {
      setCommunitiesTotal(communitiesData.total)
    }
  }, [communitiesData])

  const handleClick = () => {
    navigate('/create-organization')
  }

  const handleClubsPageChange = (_, page) => {
    setCurrentClubsPage(page)
  }

  const handleCommunitiesPageChange = (_, page) => {
    setCurrentCommunitiesPage(page)
  }

  return (
    <MainWrapper maxWidth="md">
      <Grid mb={4} container spacing={2}>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography variant="h5">Clubs</Typography>
        </Grid>
        {user?.id && (
          <>
            <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }} />
            <Grid item xs={12} md={4}>
              <Button
                style={{ width: '100%' }}
                onClick={() => handleClick()}
                type="submit"
                variant='contained'
              >
                Create Organization
              </Button>
            </Grid>
            <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }} />
          </>
        )}

        {clubsData?.data.length > 0 ? (
          <>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {clubsData?.data.map((organization) => (
                  <Grid item xs={12} md={4} key={organization.id}>
                    <OrganizationBlock
                      organization={organization}
                      themeMode={themeMode}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Pagination
                count={clubsPageCount}
                page={currentClubsPage}
                shape="rounded"
                onChange={handleClubsPageChange}
                siblingCount={0}
                size="medium"
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12} sx={{ textAlign: 'center', color: 'grey' }}>
            <Typography variant="body1">No clubs found</Typography>
          </Grid>
        )}

        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography variant="h5">Communities</Typography>
        </Grid>

        {communitiesData?.data.length > 0 ? (
          <>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {communitiesData?.data.map((organization) => (
                  <Grid item xs={12} md={4} key={organization.id}>
                    <OrganizationBlock
                      organization={organization}
                      themeMode={themeMode}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Pagination
                count={communitiesPageCount}
                page={currentCommunitiesPage}
                shape="rounded"
                onChange={handleCommunitiesPageChange}
                siblingCount={0}
                size="medium"
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12} sx={{ textAlign: 'center', color: 'grey' }}>
            <Typography variant="body1">No organizations found</Typography>
          </Grid>
        )}
      </Grid>
    </MainWrapper>
  )
}
