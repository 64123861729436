import React, { useEffect, useState } from 'react'
import GeoNames from 'geonames.js'
import { FormControl, InputLabel, Select, MenuItem, Grid } from '@mui/material'

const geoNames = new GeoNames({
  username: process.env.REACT_APP_GEONAMES_USERNAME,
  lan: 'en',
  encoding: 'JSON',
})

export function CountryCitySelector({
  setLocationDetails,
  oldCountry = '',
  oldCity = '',
}) {
  const [countries, setCountries] = useState([])
  const [selectedCountry, setSelectedCountry] = useState('')
  const [cities, setCities] = useState([])
  const [selectedCity, setSelectedCity] = useState('')

  useEffect(() => {
    geoNames
      .countryInfo({})
      .then((res) => {
        setCountries(res.geonames)
        if (oldCountry) {
          const country = res.geonames.find((c) => c.countryName === oldCountry)
          if (country) {
            setSelectedCountry(country.countryCode)
          }
        }
      })
      .catch(console.error)
  }, [oldCountry])

  useEffect(() => {
    if (selectedCountry) {
      geoNames
        .search({
          country: selectedCountry,
          featureClass: 'P',
          style: 'full',
        })
        .then((res) => {
          setCities(res.geonames)
          if (oldCity) {
            const city = res.geonames.find((c) => c.toponymName === oldCity)
            if (city) {
              setSelectedCity(city.name)
            }
          }
        })
        .catch(console.error)
    } else {
      setCities([])
    }
  }, [selectedCountry, oldCity])

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value)
    setSelectedCity('')

    setLocationDetails((prevDetails) => ({
      ...prevDetails,
      countryName: countries.find((c) => c.countryCode === event.target.value)
        .countryName,
    }))
  }

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value)

    const city = cities.find((c) => c.name === event.target.value)
    if (city) {
      setLocationDetails((prevDetails) => ({
        ...prevDetails,
        cityName: city.toponymName,
        lat: parseFloat(city.lat),
        lng: parseFloat(city.lng),
        timezone: city?.timezone?.timeZoneId,
      }))
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>Country</InputLabel>
          <Select
            value={selectedCountry}
            label="Country"
            onChange={handleCountryChange}
          >
            {countries.map((country) => (
              <MenuItem key={country.countryCode} value={country.countryCode}>
                {country.countryName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth disabled={!selectedCountry}>
          <InputLabel>City</InputLabel>
          <Select value={selectedCity} label="City" onChange={handleCityChange}>
            {cities.map((city) => (
              <MenuItem key={city.geonameId} value={city.name}>
                {city.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}
