import React, { useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import {
  Alert,
  Button,
  Collapse,
  Checkbox,
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
} from '@mui/material'

import { useCreateUser, useSearchUsers } from 'hooks/api/userHooks'

import styles from 'styles/main.module.scss'
import { UsernameField } from 'components/Fields/Username'
import { GenderField } from 'components/Fields/Gender'

/* eslint-disable react/jsx-props-no-spreading */
export function RegisterUserToTournamentDialog({
  organizationId,
  callback,
  exceptionIds,
  open,
  onClose,
  themeMode,
}) {
  const [searchString, setSearchString] = useState('')
  const [onlyOrganizationMembers, setOnlyOrganizationMembers] = useState(true)
  const [players, setPlayers] = useState([])
  const [tabIndex, setTabIndex] = useState(0)
  const [errorMessage, setErrorMessage] = useState('')

  const methods = useForm()
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = methods

  const limit = exceptionIds.length + 5

  const { trigger: searchUsersTrigger } = useSearchUsers({
    searchString,
    limit,
    offset: 0,
    organizationId: onlyOrganizationMembers ? organizationId : null,
    is_deleted: false,
  })
  const { trigger: createUserTrigger } = useCreateUser()

  const searchUsers = () => {
    searchUsersTrigger(
      {},
      {
        onSuccess: (users) => {
          setPlayers(
            users.data.filter((u) => !exceptionIds.some((id) => u.id === id))
          )
        },
        onError: (e) => setErrorMessage(e.info.error),
      }
    )
  }

  useEffect(() => {
    if (open) {
      searchUsers()
    }
  }, [open, searchString, onlyOrganizationMembers, exceptionIds])

  const handleOnClose = () => {
    setPlayers([])
    setSearchString('')
    reset()
    onClose()
  }

  const handleTabChanged = (_, newTabIndex) => {
    setTabIndex(newTabIndex)
  }

  const handleRegisterUser = (data) => {
    createUserTrigger(
      {
        name: data.name,
        surname: data.surname,
        username: data.username,
        gender: data.gender,
        is_admin: false,
        is_organization_admin: false,
        is_active: true,
      },
      {
        onSuccess: (user) => {
          callback(user, true)
          handleOnClose()
        },
        onError: (e) => setErrorMessage(e.info.error),
      }
    )
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      sx={{
        marginTop: {
          xs: 0,
          md: 15,
        },
        '& .MuiDialog-container': {
          alignItems: 'start',
        },
      }}
      open={open}
      onClose={handleOnClose}
    >
      <Collapse in={Boolean(errorMessage)}>
        <Alert
          severity="error"
          onClose={() => {
            setErrorMessage('')
          }}
        >
          Error: {errorMessage}
        </Alert>
      </Collapse>

      <DialogContent>
        <Tabs value={tabIndex} onChange={handleTabChanged} centered>
          <Tab label="Select user" />
          <Tab label="New user" />
        </Tabs>

        {
          [
            <Grid container spacing={2}>
              <Grid item xs={12} md={9} mt={1}>
                <TextField
                  id="outlined-search"
                  label="Player name / surname / username"
                  type="search"
                  variant="outlined"
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={onlyOrganizationMembers}
                      onChange={(e) =>
                        setOnlyOrganizationMembers(e.target.checked)
                      }
                    />
                  }
                  label="Only Organization Members"
                />
              </Grid>
              <Grid item xs={12}>
                <TableUsers
                  users={players}
                  callback={callback}
                  handleOnClose={handleOnClose}
                  searchUsers={searchUsers}
                  themeMode={themeMode}
                />
              </Grid>
            </Grid>,
            <FormProvider {...methods}>
              <FormControl
                component="form"
                onSubmit={handleSubmit(handleRegisterUser)}
                fullWidth
              >
                <Grid container spacing={2}>
                  <Grid item container spacing={2} xs={12} mt={0}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        required
                        style={{ width: '100%' }}
                        error={!!errors.name}
                        helperText={errors.name ? errors.name.message : ''}
                        label="Name"
                        {...register('name', { required: 'Name is required' })}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        style={{ width: '100%' }}
                        label="Surname"
                        {...register('surname')}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <UsernameField initialUsername="" />
                  </Grid>

                  <Grid item xs={12}>
                    <GenderField />
                  </Grid>

                  <Grid
                    item
                    md={4}
                    sx={{ display: { xs: 'none', md: 'block' } }}
                  />
                  <Grid item xs={12} md={4}>
                    <Button
                      style={{ width: '100%' }}
                      type="submit"
                      variant="contained"
                      disabled={Object.keys(errors).length > 0}
                    >
                      Create user
                    </Button>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    sx={{ display: { xs: 'none', md: 'block' } }}
                  />
                </Grid>
              </FormControl>
            </FormProvider>,
          ][tabIndex]
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose} color="primary" variant="contained">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function TableUsers({ users, callback, handleOnClose, themeMode }) {
  const themeClass = themeMode === 'dark' ? styles.darkTheme : styles.lightTheme

  return (
    <TableContainer component={Paper} className={themeClass}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Surname</TableCell>
            <TableCell>Username</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((p) => (
            <TableRow
              key={p.id}
              onClick={() => {
                callback(p, true)
                handleOnClose()
              }}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.3)', // This gives a light hover effect
                },
              }}
            >
              <TableCell>{p.name}</TableCell>
              <TableCell>{p.surname}</TableCell>
              <TableCell>{p.username}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
