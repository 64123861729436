import React from 'react'
import Typography from '@mui/material/Typography'
import styles from './ScoreDisplay.module.scss'

export function ScoreDisplay({ team1Score, team2Score }) {
  return (
    <div className={styles.scoreContainer}>
      <Typography variant="h4" className={styles.score}>
        {team1Score || 0}
      </Typography>
      <Typography variant="h4" className={styles.hyphen}>
        -
      </Typography>
      <Typography variant="h4" className={styles.secondScore}>
        {team2Score || 0}
      </Typography>
    </div>
  )
}
