import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material'

import { useCreateOrganization } from 'hooks/api/organizationHooks'
import { useRefreshToken } from 'hooks/api/authHooks'
import { useAllRolePolicies } from 'hooks/api/roleHooks'

import { MainWrapper } from 'components/layout/MainWrapper/MainWrapper'
import { useThemeSwitcher } from 'components/ThemeProvider/ThemeProvider'
import { LocationSelector } from 'components/LocationSelector/LocationSelector'

import { refreshJwtAndFetchPolicies } from 'utils'

/* eslint-disable react/jsx-props-no-spreading */
export function CreateOrganization() {
  const { themeMode } = useThemeSwitcher()
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState('')

  const { trigger: createOrganizationTrigger } = useCreateOrganization()

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      hasPermanentLocation: false,
    },
  })

  const { trigger: refreshTokenTrigger } = useRefreshToken()
  const { trigger: allRolePoliciesTrigger } = useAllRolePolicies()

  const handleRedirect = (path) => {
    window.location.href = path
  }

  const handleOrganizationCreate = (data) => {
    const formData = {
      name: data.name,
      phone: data.phone,
      website: data.website,
      description: data.description,
      has_permanent_location: data.hasPermanentLocation,
      location_id: data.locationId,
    }

    const filledFields = Object.fromEntries(
      Object.entries(formData).filter(([, value]) => value !== undefined)
    )

    createOrganizationTrigger(filledFields, {
      onSuccess: (data) => {
        refreshJwtAndFetchPolicies(refreshTokenTrigger, allRolePoliciesTrigger, handleRedirect)

        navigate(`/organizations/${data.id}`)
      },
      onError: (data) => setErrorMessage(data.info.error),
    })
  }

  return (
    <MainWrapper
      maxWidth="md"
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    >
      <FormControl
        component="form"
        onSubmit={handleSubmit(handleOrganizationCreate)}
        fullWidth
      >
        <Grid container spacing={2} mb={4} alignItems="flex-start">
          <Grid item xs={12} textAlign="center">
            <Typography variant="h5">Create organization</Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              style={{ width: '100%' }}
              error={!!errors.name}
              label="Organization name"
              {...register('name', { required: true })}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              style={{ width: '100%' }}
              error={!!errors.website}
              label="Website"
              {...register('website')}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              style={{ width: '100%' }}
              error={!!errors.phone}
              label="Phone"
              {...register('phone')}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Checkbox
              {...register('hasPermanentLocation')}
              defaultChecked={false}
            />
            <Typography variant="body1">Club with fixed location</Typography>
          </Grid>

          {watch('hasPermanentLocation') && (
            <>
              <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' } }} />
              <Grid item xs={12} md={6}>
                <LocationSelector
                  themeMode={themeMode}
                  currentLocation={watch('location')}
                  onLocationSelect={(location) => {
                    setValue('locationId', location.id, {
                      shouldValidate: true,
                    })
                    setValue('location', location, { shouldValidate: true })
                    setValue('hasPermanentLocation', true, {
                      shouldValidate: true,
                    })
                  }}
                />
              </Grid>
              <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' } }} />
            </>
          )}

          <Grid item xs={12}>
            <TextField
              style={{ width: '100%' }}
              error={!!errors.description}
              label="Description"
              multiline
              {...register('description')}
            />
          </Grid>

          <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }} />
          <Grid item xs={12} md={4}>
            <Button style={{ width: '100%' }} type="submit" variant="contained">
              Create
            </Button>
          </Grid>
          <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }} />
        </Grid>
      </FormControl>
    </MainWrapper>
  )
}
