import React, { useState, useCallback, useEffect } from 'react'
import { Cookies } from 'react-cookie'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { loadStripe } from '@stripe/stripe-js'
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js'
import {
  useCheckoutSession,
  useCancelSubscription,
  useSubscriptionRemainingTime,
} from 'hooks/api/stripeHooks'
import { useRefreshToken } from 'hooks/api/authHooks'
import { useAllRolePolicies } from 'hooks/api/roleHooks'
import { MainWrapper } from 'components/layout/MainWrapper/MainWrapper'
import { PlanCard } from 'components/PlanCard/PlanCard'
import { getUserRoleIdFromToken, refreshJwtAndFetchPolicies } from 'utils'

import products from 'productsConfig'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)
const cookiesGlobal = new Cookies()

export function Plans() {
  const { trigger: checkoutSessionTrigger } = useCheckoutSession()
  const { trigger: refreshTokenTrigger } = useRefreshToken()
  const { trigger: cancelSubscriptionTrigger } = useCancelSubscription()
  const { trigger: allRolePoliciesTrigger } = useAllRolePolicies()
  const { trigger: remainingSubscriptionTimeTrigger } =
    useSubscriptionRemainingTime()

  const [open, setOpen] = useState(false)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [duration, setDuration] = useState('monthly')
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [userRoleId, setUserRoleId] = useState(null)
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false)
  const [showStripeForm, setShowStripeForm] = useState(true)
  const [remainingDays, setRemainingDays] = useState(null)
  const [isCanceling, setIsCanceling] = useState(false)
  const [cancelSuccess, setCancelSuccess] = useState(false)
  const [remainingTimeData, setRemainingTimeData] = useState({})

  const handleReturnToDefaultPlan = async () => {
    setIsCanceling(true)
    const response = await cancelSubscriptionTrigger()
    const remainingNanoseconds = response.remaining_time
    const remainingDays = Math.floor(
      remainingNanoseconds / (1e9 * 60 * 60 * 24)
    )
    setRemainingDays(remainingDays)
    setIsCanceling(false)
    setCancelSuccess(true)
  }

  const handleOpen = (product) => {
    setSelectedProduct(product)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const fetchClientSecret = useCallback(
    async (priceId) => {
      const session = await checkoutSessionTrigger({
        price_id: priceId,
      })
      return session.client_secret
    },
    [checkoutSessionTrigger]
  )

  const handleComplete = () => {
    setIsRefreshing(true)
    setIsPaymentSuccess(false)
  }

  const options = {
    fetchClientSecret: () =>
      duration === 'monthly'
        ? fetchClientSecret(selectedProduct?.monthly_price.price_id)
        : fetchClientSecret(selectedProduct?.yearly_price.price_id),
    onComplete: handleComplete,
  }

  const handleDurationChange = (_, newDuration) => {
    if (newDuration !== null) {
      setDuration(newDuration)
    }
  }

  const handleRedirect = (path) => {
    window.location.href = path
  }

  useEffect(() => {
    const initialRoleId = getUserRoleIdFromToken(cookiesGlobal.get('jwt'))
    setUserRoleId(initialRoleId)
  }, [])

  useEffect(() => {
    if (userRoleId !== null) {
      if (
        userRoleId !== products.player_basic.connected_role_id &&
        userRoleId !== products.administrator_basic.connected_role_id
      ) {
        remainingSubscriptionTimeTrigger(
          {},
          {
            onSuccess: (remainingTime) => {
              setRemainingTimeData(remainingTime)
            },
            onError: (error) => {
              console.error('Error fetching remaining time:', error)
            },
          }
        )
      }
    }
  }, [userRoleId])

  useEffect(() => {
    if (isRefreshing) {
      const initialRoleId = getUserRoleIdFromToken(cookiesGlobal.get('jwt'))

      const intervalId = setInterval(async () => {
        setShowStripeForm(false)
        const currentRoleId = getUserRoleIdFromToken(cookiesGlobal.get('jwt'))
        setUserRoleId(currentRoleId)

        if (currentRoleId !== initialRoleId) {
          setIsRefreshing(false)
          setIsPaymentSuccess(true)
          clearInterval(intervalId)
          return null
        }
        refreshJwtAndFetchPolicies(
          refreshTokenTrigger,
          allRolePoliciesTrigger,
          handleRedirect
        )

        return null
      }, 2000)

      return () => clearInterval(intervalId)
    }
    return undefined
  }, [isRefreshing, refreshTokenTrigger])

  const handleConfirmDialogOpen = () => {
    setConfirmDialogOpen(true)
  }

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false)
    setRemainingDays(null)
    setCancelSuccess(false)
  }

  const handleConfirmDialogCloseAndReload = () => {
    setConfirmDialogOpen(false)
    setRemainingDays(null)
    setCancelSuccess(false)
    window.location.reload()
  }

  return (
    <MainWrapper maxWidth="md">
      <Grid container spacing={2} mb={4} justifyContent="center">
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography variant="h5" sx={{ mb: 4 }}>
            Choose Your Plan
          </Typography>
          <ToggleButtonGroup
            color="primary"
            value={duration}
            exclusive
            onChange={handleDurationChange}
          >
            <ToggleButton value="monthly">Monthly</ToggleButton>
            <ToggleButton value="yearly">Yearly</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        {userRoleId === null ? (
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100px',
              }}
            >
              <CircularProgress />
            </Box>
          </Grid>
        ) : (
          <>
            {(userRoleId === products.player_basic.connected_role_id ||
              userRoleId === products.player_premium.connected_role_id) && (
              <>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <Typography variant="h5">Player Plans</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <PlanCard
                    product={products.player_basic}
                    duration={duration}
                    userRoleId={userRoleId}
                    actionButtonLabel="Choose this plan"
                    actionButtonColor="primary"
                    actionButtonHandler={handleConfirmDialogOpen}
                    remainingTimeData={remainingTimeData}
                    setRemainingTimeData={setRemainingTimeData}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <PlanCard
                    product={products.player_premium}
                    duration={duration}
                    userRoleId={userRoleId}
                    actionButtonLabel="Choose this plan"
                    actionButtonColor="primary"
                    actionButtonHandler={() =>
                      handleOpen(products.player_premium)
                    }
                    remainingTimeData={remainingTimeData}
                    setRemainingTimeData={setRemainingTimeData}
                  />
                </Grid>
              </>
            )}

            {(userRoleId === products.administrator_basic.connected_role_id ||
              userRoleId ===
                products.administrator_advanced.connected_role_id) && (
              <>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <Typography variant="h5">Administrator Plans</Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                  <PlanCard
                    product={products.administrator_basic}
                    duration={duration}
                    userRoleId={userRoleId}
                    actionButtonLabel="Choose this plan"
                    actionButtonColor="primary"
                    actionButtonHandler={handleConfirmDialogOpen}
                    remainingTimeData={remainingTimeData}
                    setRemainingTimeData={setRemainingTimeData}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <PlanCard
                    product={products.administrator_advanced}
                    duration={duration}
                    userRoleId={userRoleId}
                    actionButtonLabel="Choose this plan"
                    actionButtonColor="primary"
                    actionButtonHandler={() =>
                      handleOpen(products.administrator_advanced)
                    }
                    remainingTimeData={remainingTimeData}
                    setRemainingTimeData={setRemainingTimeData}
                  />
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogContent sx={{ padding: 0 }} dividers>
          {selectedProduct && showStripeForm && (
            <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          )}

          {isRefreshing ? (
            <>
              <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  marginTop: 2,
                  marginBottom: 2,
                }}
              >
                <CircularProgress />
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Waiting for stripe response...
                </Typography>
              </Box>
            </>
          ) : (
            isPaymentSuccess && (
              <>
                <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                >
                  <CheckCircleIcon sx={{ color: 'green', fontSize: 40 }} />
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    Payment received successfully!
                  </Typography>
                </Box>
              </>
            )
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained">
            {isPaymentSuccess || isRefreshing ? 'Close' : 'Cancel'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        fullWidth
        maxWidth="sm"
      >
        {!cancelSuccess && !remainingDays ? (
          <>
            <DialogTitle>
              Are you sure you want to switch to this plan?
            </DialogTitle>

            <DialogContent dividers>
              <Typography variant="body1" color="text.secondary">
                Your subscription will be cancelled after the paid term has
                expired.
                <br />
                You can continue your subscription during this time.
              </Typography>
            </DialogContent>

            <DialogActions>
              <Button
                onClick={handleConfirmDialogClose}
                color="primary"
                variant="contained"
              >
                Cancel
              </Button>
              <Button
                onClick={handleReturnToDefaultPlan}
                color="primary"
                variant="outlined"
                disabled={isCanceling}
              >
                {isCanceling ? <CircularProgress size={24} /> : 'Confirm'}
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle>Success</DialogTitle>

            <DialogContent dividers>
              <Typography variant="body1" color="text.secondary">
                Successfully returned to the free plan.
                <br />
                {remainingDays && (
                  <>
                    You will have <b>{remainingDays} day(s)</b> left on your
                    current subscription.
                  </>
                )}
              </Typography>
            </DialogContent>

            <DialogActions>
              <Button
                onClick={handleConfirmDialogCloseAndReload}
                color="success"
                variant="contained"
              >
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </MainWrapper>
  )
}
