import React from 'react'
import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'

export function ResponseAlert({ type, message, onClose, timeout }) {
  const severity = type === 'success' ? 'success' : 'error'

  return (
    <Collapse in={message !== null} timeout={timeout || 300}>
      <Alert severity={severity} variant="filled" onClose={onClose}>
        {message}
      </Alert>
    </Collapse>
  )
}
