import React, { useRef, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useForm, FormProvider } from 'react-hook-form'

import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  Tab,
  Typography,
} from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'

import InfoIcon from '@mui/icons-material/Info'
import EditIcon from '@mui/icons-material/Edit'
import ListAltIcon from '@mui/icons-material/ListAlt'

import {
  MainWrapper,
  MainWrapperViewMode,
} from 'components/layout/MainWrapper/MainWrapper'
import { AmericanoSingleLeaderboardTable } from 'components/LeaderboardTable/LeaderboardTable'
import { RoundsSchedule } from 'components/RoundsSchedule/RoundsSchedule'
import {
  TournamentRegisteredSinglePlayers,
  TournamentRegisteredSingleWaitlistPlayers,
  TournamentRegisteredLeftPlayers,
} from 'components/TournamentRegisteredPlayers/TournamentRegisteredPlayers'
import { useThemeSwitcher } from 'components/ThemeProvider/ThemeProvider'
import { TournamentInfo } from 'components/TournamentInfo/TournamentInfo'
import { UpdateTournamentInfo } from 'components/TournamentInfo/UpdateTournamentInfo'

import {
  useAmericanoSingleLeaderboard,
  useUpdateTournament,
} from 'hooks/api/tournamentHooks'

import { useOrganization } from 'hooks/api/organizationHooks'

import { useResponsive } from 'hooks/responsive'

import { isAdmin } from 'utils'
import { useCurrentUser } from 'hooks/cookies'

/* eslint-disable react/jsx-props-no-spreading */
export function AmericanoSingleFinished({ data: tournamentData, mutate }) {
  const { isScreenMdOrMore } = useResponsive()

  const { themeMode } = useThemeSwitcher()

  const { data: organizationData } = useOrganization(
    tournamentData?.organization_id
  )

  const [currentUser] = useCurrentUser()

  const admin = isAdmin(currentUser)
  const organizationAdmin =
    currentUser?.administered_organizations?.data?.length >= 1
  const adminOrOrganizationAdmin = admin || organizationAdmin

  const initialViewMode = localStorage.getItem('viewMode') === 'true'
  const initialHistoryOn = localStorage.getItem('historyOn') === 'true'
  const [viewMode, setViewMode] = useState(initialViewMode)
  const [historyOn, setHistoryOn] = useState(initialHistoryOn)
  const [tabNumber, setTabValue] = useState('1')

  const initialAddressIndexRef = useRef(0)
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(0)

  const [errorMessage, setErrorMessage] = useState('')

  const tournamentAddressId = tournamentData?.address?.id

  useEffect(() => {
    if (organizationData?.addresses && tournamentAddressId) {
      const addressIndex = organizationData.addresses.findIndex(
        (address) => address.id === tournamentAddressId
      )

      setSelectedAddressIndex(addressIndex >= 0 ? addressIndex : 0)
      initialAddressIndexRef.current = addressIndex >= 0 ? addressIndex : 0
    }
  }, [organizationData, tournamentAddressId])

  const {
    organization_id,
    users,
    waitlist_users,
    left_users,
    schedule,
    courts,
  } = tournamentData

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    setValue,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      organization_name: tournamentData?.organization_name || '',
      readOnly: true,
      type: tournamentData?.type || 'americano_single',
      tournamentName: tournamentData?.name || '',
      americanoMaxScore: tournamentData?.americano_max_score || 0,
      startTime: tournamentData?.start_time
        ? tournamentData?.start_time
        : Date.now(),
      endTime: tournamentData?.end_time ? tournamentData?.end_time : Date.now(),
      description: tournamentData?.description || '',
      maxUsers: tournamentData?.max_users || 0,
      cycleCount: tournamentData?.cycle_count || 1,
    },
  })

  const collectChanges = () => {
    const currentValues = {
      name: watch('name'),
      start_time: watch('startTime'),
      end_time: watch('endTime'),
      description: watch('description'),
    }

    const changes = Object.keys(currentValues).reduce((acc, key) => {
      if (currentValues[key] !== tournamentData[key]) {
        acc[key] = currentValues[key]
      }
      return acc
    }, {})

    return changes
  }

  const handleChangeTab = (event, tabNumber) => {
    setTabValue(tabNumber)
  }

  const { tournamentId } = useParams()
  const tournamentIdInt = parseInt(tournamentId, 10)
  const {
    data: tournamentLeaderboardData,
    loading: tournamentLeaderboardLoading,
  } = useAmericanoSingleLeaderboard(tournamentId)
  const { trigger: updateTournamentTrigger } = useUpdateTournament()

  useEffect(() => {
    localStorage.setItem('viewMode', viewMode.toString())
  }, [viewMode])

  useEffect(() => {
    localStorage.setItem('historyOn', historyOn.toString())
  }, [historyOn])

  const toggleViewMode = () => {
    setViewMode((prevMode) => !prevMode)
  }

  const toggleHistoryOn = () => {
    setHistoryOn((prevMode) => !prevMode)
  }

  const handleUpdateTournament = () => {
    const changes = collectChanges()

    updateTournamentTrigger(
      {
        id: Number(tournamentId),
        ...changes,
      },
      {
        onSuccess: () => {
          mutate()
          setValue('readOnly', true)
        },
        onError: (data) => setErrorMessage(data.info),
      }
    )
  }

  const handleCancelUpdate = () => {
    reset()
  }

  const handleAddressChange = (event) => {
    setSelectedAddressIndex(event.target.value)
  }

  if (tournamentLeaderboardLoading) {
    return <MainWrapper>loading...</MainWrapper>
  }

  if (tournamentLeaderboardData) {
    return isScreenMdOrMore && viewMode && !adminOrOrganizationAdmin ? (
      <MainWrapperViewMode
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        scale={1.3}
      >
        <Grid item xs={12} mt={4} sx={{ textAlign: 'center' }}>
          <Typography variant="h5">{watch('tournamentName')}</Typography>
        </Grid>

        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          mt={1}
        >
          <Grid item xs={12} md={6} sx={{ textAlign: 'right' }}>
            <Button onClick={toggleViewMode} variant="contained">
              Switch to Regular View
            </Button>
          </Grid>
          <Grid item xs={12} md={6} sx={{ textAlign: 'left' }}>
            <Button onClick={toggleHistoryOn} variant="contained">
              {historyOn ? 'Hide Rounds History' : 'Show Rounds History'}
            </Button>
          </Grid>
        </Grid>

        <Grid item md={2} sx={{ display: { xs: 'none', md: 'block' } }} />
        <Grid mt={2} container spacing={2}>
          <Grid item md={2} sx={{ display: { xs: 'none', md: 'block' } }} />
          <Grid item xs={12} md={8}>
            <AmericanoSingleLeaderboardTable
              leaderboard={tournamentLeaderboardData}
              viewMode={viewMode}
              themeMode={themeMode}
            />
          </Grid>
          <Grid item md={2} sx={{ display: { xs: 'none', md: 'block' } }} />

          {historyOn && (
            <>
              <Grid item md={2} sx={{ display: { xs: 'none', md: 'block' } }} />
              <Grid item xs={12} md={8}>
                {schedule?.rounds?.map((round, idx) => (
                  <RoundsSchedule
                    viewMode={viewMode}
                    organizationCourts={courts}
                    round={round}
                    roundNumber={idx}
                  />
                ))}
              </Grid>
              <Grid item md={2} sx={{ display: { xs: 'none', md: 'block' } }} />
            </>
          )}
        </Grid>
      </MainWrapperViewMode>
    ) : (
      <MainWrapper
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      >
        <FormProvider
          register={register}
          handleSubmit={handleSubmit}
          control={control}
          formState={{ errors }}
          watch={watch}
          reset={reset}
          setValue={setValue}
        >
          <FormControl
            component="form"
            onSubmit={handleSubmit(handleUpdateTournament)}
            fullWidth
          >
            <Grid item xs={12} mt={4} sx={{ textAlign: 'center' }}>
              <Typography variant="h5">{watch('tournamentName')}</Typography>
            </Grid>

            <Container maxWidth="md" sx={{ padding: 0 }}>
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={tabNumber}>
                  <Container
                    sx={{
                      '@media (min-width: 0px)': {
                        paddingLeft: 0,
                        paddingRight: 0,
                      },
                    }}
                  >
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleChangeTab}>
                        <Tab
                          label="Leaderboard"
                          value="1"
                          sx={{ paddingLeft: 0 }}
                          icon={<ListAltIcon />}
                          iconPosition="start"
                        />
                        <Tab
                          label="Info"
                          value="2"
                          icon={<InfoIcon />}
                          iconPosition="start"
                        />
                      </TabList>
                    </Box>
                  </Container>

                  <TabPanel value="1" sx={{ padding: 0 }}>
                    {isScreenMdOrMore && !adminOrOrganizationAdmin && (
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        mt={1}
                      >
                        <Button onClick={toggleViewMode} variant="contained">
                          Switch to Fullscreen view
                        </Button>
                      </Grid>
                    )}

                    <Grid container spacing={2} mt={0}>
                      <Grid item xs={12}>
                        <AmericanoSingleLeaderboardTable
                          leaderboard={tournamentLeaderboardData}
                          viewMode={viewMode}
                          themeMode={themeMode}
                        />
                      </Grid>

                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        mt={2}
                      >
                        <Button onClick={toggleHistoryOn} variant="contained">
                          {historyOn
                            ? 'Hide Rounds History'
                            : 'Show Rounds History'}
                        </Button>
                      </Grid>

                      {historyOn && (
                        <Grid item xs={12}>
                          {schedule?.rounds?.map((round, idx) => (
                            <RoundsSchedule
                              key={idx}
                              viewMode={viewMode}
                              organizationCourts={courts}
                              round={round}
                              roundNumber={idx}
                            />
                          ))}
                        </Grid>
                      )}
                    </Grid>
                  </TabPanel>

                  <TabPanel value="2" sx={{ padding: 0 }}>
                    {watch('readOnly') ? (
                      <Grid container spacing={2} mb={4}>
                        <Grid item xs={12} mt={2}>
                          <TournamentInfo
                            tournamentData={tournamentData}
                            organizationData={organizationData}
                            themeMode={themeMode}
                          />
                        </Grid>

                        {adminOrOrganizationAdmin && (
                          <>
                            <Grid
                              item
                              md={4}
                              sx={{ display: { xs: 'none', md: 'block' } }}
                            />
                            <Grid item xs={12} md={4}>
                              <Button
                                fullWidth
                                onClick={() => setValue('readOnly', false)}
                                type="button"
                                startIcon={<EditIcon />}
                                variant="contained"
                              >
                                Edit
                              </Button>
                            </Grid>
                            <Grid
                              item
                              md={4}
                              sx={{ display: { xs: 'none', md: 'block' } }}
                            />
                          </>
                        )}

                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                          <Typography variant="h5">
                            Registered Players
                          </Typography>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <TournamentRegisteredSinglePlayers
                            onlySwapActions
                            players={users}
                            waitlistPlayers={waitlist_users}
                            maxUsers={users.length}
                            isAdmin={adminOrOrganizationAdmin}
                            currentUser={currentUser}
                            tournamentId={tournamentIdInt}
                            organizationId={organization_id}
                            exceptionIds={[
                              ...users.map((u) => u.id),
                              ...waitlist_users.map((u) => u.id),
                            ]}
                            themeMode={themeMode}
                          />
                        </Grid>

                        {waitlist_users?.length > 0 && (
                          <>
                            <Grid
                              item
                              xs={12}
                              mt={1}
                              sx={{ textAlign: 'center' }}
                            >
                              <Typography variant="h5">Waitlist</Typography>
                            </Grid>

                            <Grid item xs={12} md={12}>
                              <TournamentRegisteredSingleWaitlistPlayers
                                readOnly
                                waitlistPlayers={waitlist_users}
                                isAdmin={adminOrOrganizationAdmin}
                                currentUser={currentUser}
                                themeMode={themeMode}
                              />
                            </Grid>
                          </>
                        )}

                        {left_users && left_users.length > 0 && (
                          <>
                            <Grid
                              item
                              xs={12}
                              mt={1}
                              sx={{ textAlign: 'center' }}
                            >
                              <Typography variant="h5">Players Left</Typography>
                            </Grid>

                            <Grid item xs={12} md={12}>
                              <TournamentRegisteredLeftPlayers
                                leftPlayers={left_users}
                                themeMode={themeMode}
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    ) : (
                      <Grid container spacing={2} mb={4}>
                        <Grid item xs={12} mt={2}>
                          <UpdateTournamentInfo
                            organizationData={organizationData}
                            selectedAddressIndex={selectedAddressIndex}
                            handleAddressChange={handleAddressChange}
                            themeMode={themeMode}
                            isAdmin={adminOrOrganizationAdmin}
                          />
                        </Grid>

                        {adminOrOrganizationAdmin && (
                          <>
                            <Grid item xs={2} />
                            <Grid item xs={4}>
                              <Button
                                fullWidth
                                onClick={handleCancelUpdate}
                                type="button"
                                variant="contained"
                              >
                                Cancel
                              </Button>
                            </Grid>
                            <Grid item xs={4}>
                              <Button
                                fullWidth
                                type="submit"
                                disabled={
                                  !Object.keys(collectChanges()).length ||
                                  Object.keys(errors).length > 0 ||
                                  parseInt(watch('maxUsers'), 10) < users.length
                                }
                                variant="contained"
                              >
                                Submit
                              </Button>
                            </Grid>
                            <Grid item xs={2} />
                          </>
                        )}
                      </Grid>
                    )}
                  </TabPanel>
                </TabContext>
              </Box>
            </Container>
          </FormControl>
        </FormProvider>
      </MainWrapper>
    )
  }
}
