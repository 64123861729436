import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import dayjs from 'dayjs'

import {
  Button,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'

import { Info } from '@mui/icons-material'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'

import { useCreateTournament } from 'hooks/api/tournamentHooks'

import { useOrganization } from 'hooks/api/organizationHooks'

import { ResponseAlert } from 'components/layout/Alert/ResponseAlert'
import { MainWrapper } from 'components/layout/MainWrapper/MainWrapper'
import { CustomDatePicker, CustomTimePicker } from 'components/CustomPicker'
import { useThemeSwitcher } from 'components/ThemeProvider/ThemeProvider'
import { LocationBlock } from 'components/LocationBlock/LocationBlock'
import { LocationSelector } from 'components/LocationSelector/LocationSelector'
import { LocationCourtsSelector } from 'components/LocaitonCourts/LocationCourtsSelector'

/* eslint-disable react/jsx-props-no-spreading */
export function CreateRoundRobinTeam() {
  const tournamentType = 'round_robin_team'
  const navigate = useNavigate()
  const { themeMode } = useThemeSwitcher()

  const { organizationId } = useParams()
  const { data: organizationData } = useOrganization(organizationId)

  const {
    control,
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      cycleCount: 1,
      startTime: dayjs(),
      endTime: dayjs(),
      location: organizationData?.location || null,
      locationId: organizationData?.location_id || null,
    },
  })

  useEffect(() => {
    if (organizationData) {
      setValue('location', organizationData.location || null)
      setValue('locationId', organizationData.location_id || null)
    }
  }, [organizationData, setValue])

  const [courts, setCourts] = useState([])
  const [showTooltip, setShowTooltip] = useState(false)

  const [errorMessage, setErrorMessage] = useState('')

  const { trigger: createAmericanoTeamTrigger } = useCreateTournament()

  const [responseAlert, setResponseAlert] = useState(null)

  useEffect(() => {
    setCourts([])
  }, [watch('locationId')])

  const handleCreateTournament = (data) => {
    createAmericanoTeamTrigger(
      {
        type: tournamentType,
        americano_max_score: Number(data.maxScore),
        max_users: Number(data.maxTeams) * 2,
        cycle_count: Number(data.cycleCount),
        organization_id: Number(organizationId),
        location_id: Number(data.locationId),
        name: data.name,
        start_time: dayjs(data.startTime),
        end_time: dayjs(data.endTime),
        courts,
        description: data.description,
      },
      {
        onSuccess: (data) => navigate(`/tournament/${data.id}`),
        onError: (data) => setErrorMessage(data.info.error),
      }
    )
  }

  // TODO: write suggested time for each game

  return (
    <MainWrapper
      maxWidth="md"
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    >
      {responseAlert && (
        <Grid container spacing={2}>
          <Grid item xs={12} mt={4} sx={{ textAlign: 'center' }}>
            <ResponseAlert
              type={responseAlert?.type}
              message={responseAlert?.message}
              onClose={() => setResponseAlert(null)}
            />
          </Grid>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        mt={{ xs: 2, md: 4 }}
        mb={2}
        sx={{ textAlign: 'center' }}
      >
        <Typography variant="h5">New Round Robin Tournament (Team)</Typography>
      </Grid>

      <FormControl
        component="form"
        onSubmit={handleSubmit(handleCreateTournament)}
      >
        <Grid container spacing={2} mb={4} alignItems="flex-start">
          <Grid item xs={12}>
            <TextField
              required
              style={{ width: '100%' }}
              error={!!errors.name}
              helperText={!!errors.name && 'Please, enter tournament name'}
              label="Tournament Name"
              {...register('name', { required: true })}
            />
          </Grid>

          <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' } }} />
          <Grid item container spacing={2} xs={12} md={6}>
            <Grid item xs={12}>
              {organizationData?.has_permanent_location ? (
                <LocationBlock
                  location={watch('location')}
                  themeMode={themeMode}
                  withoutLink
                />
              ) : (
                <LocationSelector
                  themeMode={themeMode}
                  currentLocation={watch('location')}
                  onLocationSelect={(location) => {
                    setValue('locationId', location.id, {
                      shouldValidate: true,
                    })
                    setValue('location', location, { shouldValidate: true })
                    setValue('hasPermanentLocation', true, {
                      shouldValidate: true,
                    })
                  }}
                />
              )}
            </Grid>
          </Grid>
          <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' } }} />

          {(organizationData?.location || watch('location')) && (
            <Grid item xs={12}>
              <LocationCourtsSelector
                maxCourts={6}
                courts={
                  organizationData?.location
                    ? organizationData?.location.courts
                    : watch('location').courts
                }
                onCourtToggle={(court, isSelected) => {
                  if (isSelected) {
                    setCourts([...courts, court])
                  } else {
                    setCourts(courts.filter((c) => c.id !== court.id))
                  }
                }}
              />
            </Grid>
          )}

          <Grid item container spacing={2} xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={12}>
                <CustomDatePicker
                  sx={{ width: '100%' }}
                  label="Tournament date *"
                  control={control}
                  name="startDate"
                  value={dayjs(watch('startTime'))}
                  readOnly={watch('readOnly')}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTimePicker
                  sx={{ width: '100%' }}
                  label="Start Time *"
                  control={control}
                  name="startTime"
                  value={dayjs(watch('startTime'))}
                  readOnly={watch('readOnly')}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTimePicker
                  sx={{ width: '100%' }}
                  label="End time *"
                  control={control}
                  name="endTime"
                  value={dayjs(watch('endTime'))}
                  readOnly={watch('readOnly')}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>

          <Grid item container spacing={2} xs={12} md={6}>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Grid
                container
                item
                xs={12}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <TextField
                  required
                  style={{ flex: 1 }}
                  error={!!errors.maxTeams}
                  helperText={
                    !!errors.maxTeams &&
                    `Please, enter valid number in range (${
                      courts.length * 2
                    } - ${courts.length * 2 + 1}), or change the courts`
                  }
                  label={`Max Teams (${courts.length * 2} - ${
                    courts.length * 2 + 1
                  })`}
                  type="number"
                  {...register('maxTeams', {
                    required: true,
                    min: courts.length * 2,
                    max: courts.length * 2 + 1,
                  })}
                />
                <IconButton onClick={() => setShowTooltip(!showTooltip)}>
                  <Info color="info" />
                </IconButton>
              </Grid>
              {showTooltip && (
                <Grid item xs={12} p={1}>
                  <Typography variant="caption">
                    Allowed number of teams:
                    <br /> 1 court - 2, 3 teams
                    <br /> 2 courts - 4, 5 teams
                    <br /> 3 courts - 6, 7 teams
                    <br /> 4 courts - 8, 9 teams
                    <br /> 5 courts - 10, 11 teams
                    <br /> 6 courts - 12, 13 teams
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                style={{ width: '100%' }}
                error={!!errors.cycleCount}
                helperText={
                  !!errors.cycleCount &&
                  'Please, enter number equal or more than 1'
                }
                label="Schedule Repetition"
                type="number"
                {...register('cycleCount', { required: true, min: 1 })}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TextField
              sx={{
                width: '100%',
                height: '100%',
                '& .MuiInputBase-root': {
                  height: '100%',
                  alignItems: 'start',
                },
              }}
              error={!!errors.description}
              label="Description"
              multiline
              {...register('description')}
              inputProps={{
                readOnly: watch('readOnly'),
                sx: {
                  maxHeight: '100%',
                },
              }}
              maxRows={10}
            />
          </Grid>

          {courts?.length > 0 && watch('maxTeams') === '' && (
            <Grid
              item
              xs={12}
              mt={5}
              sx={{ textAlign: 'center', color: 'grey' }}
            >
              <Grid
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Info />
                <Typography variant="body1">
                  {(() => {
                    if (courts?.length > 0) {
                      if (watch('maxTeams') === '') {
                        return 'Please, enter number of max teams'
                      }

                      return ''
                    }

                    return 'Add at least 1 court to tournament'
                  })()}
                </Typography>
              </Grid>
            </Grid>
          )}

          <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }} />
          <Grid item mt={2} xs={12} md={4}>
            <Button
              style={{ width: '100%' }}
              type="submit"
              disabled={courts.length <= 0 || watch('maxTeams') === ''}
              variant="contained"
            >
              Create
            </Button>
          </Grid>
          <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }} />
        </Grid>
      </FormControl>
    </MainWrapper>
  )
}
