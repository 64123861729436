import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import {
  Box,
  Grid,
  Pagination,
  TextField,
  Typography,
  CardMedia,
} from '@mui/material'

import dayjs from 'dayjs'

import { MainWrapper } from 'components/layout/MainWrapper/MainWrapper'
import { TournamentBlock } from 'components/TournamentBlock/TournamentBlock'
import { useTournaments } from 'hooks/api/tournamentHooks'
import { useTotalStatistic } from 'hooks/api/statisticHooks'
import { PulsingIcon } from 'components/PulsingIcon/PulsingIcon'
import { useThemeSwitcher } from 'components/ThemeProvider/ThemeProvider'

import { useResponsive } from 'hooks/responsive'

export function Root() {
  const { isScreenMdOrMore } = useResponsive()

  const { themeMode } = useThemeSwitcher()

  const limit = isScreenMdOrMore ? 6 : 3
  const [currentPageCreated, setCurrentPageCreated] = useState(1)
  const [totalCreated, setTotalCreated] = useState(0)
  const offsetCreated = (currentPageCreated - 1) * limit
  const countCreated = Math.ceil(totalCreated / limit)

  const [inProgressPageCreated, setInProgressPageCreated] = useState(1)
  const [inProgressTotalCreated, setInProgressTotalCreated] = useState(0)
  const inProgressOffsetCreated = (inProgressPageCreated - 1) * limit
  const inProgressCountCreated = Math.ceil(inProgressTotalCreated / limit)

  const [currentPageFinished, setCurrentPageFinished] = useState(1)
  const [totalFinished, setTotalFinished] = useState(0)
  const offsetFinished = (currentPageFinished - 1) * limit
  const countFinished = Math.ceil(totalFinished / limit)

  const currentDate = dayjs().format('DD.MM.YYYY')
  const yesterdayDate = dayjs().subtract(1, 'day').format('DD.MM.YYYY')

  const {
    data: createdTournamentsData,
    isLoading: createdTournamentsDataLoading,
  } = useTournaments({
    status: 'created',
    after_date: currentDate,
    limit,
    offset: offsetCreated,
    is_deleted: false,
  })

  const {
    data: inProgressTournamentsData,
    isLoading: inProgressTournamentsDataLoading,
  } = useTournaments({
    status: 'in_progress',
    after_date: yesterdayDate,
    limit,
    offset: inProgressOffsetCreated,
    is_deleted: false,
  })

  const {
    data: finishedTournamentsData,
    isLoading: finishedTournamentsDataLoading,
  } = useTournaments({
    status: 'finished',
    order: 'desc',
    limit,
    offset: offsetFinished,
    is_deleted: false,
  })

  const { data: totalStatisticsData, isLoading: totalStatisticsDataLoading } =
    useTotalStatistic()

  useEffect(() => {
    if (createdTournamentsData?.total) {
      setTotalCreated(createdTournamentsData.total)
    }

    if (inProgressTournamentsData?.total) {
      setInProgressTotalCreated(inProgressTournamentsData.total)
    }

    if (finishedTournamentsData?.total) {
      setTotalFinished(finishedTournamentsData.total)
    }
  }, [
    createdTournamentsData,
    inProgressTournamentsData,
    finishedTournamentsData,
  ])

  const handlePageCreatedChange = (_, page) => {
    setCurrentPageCreated(page)
  }

  const handlePageFinishedChange = (_, page) => {
    setCurrentPageFinished(page)
  }

  if (
    (createdTournamentsDataLoading,
    inProgressTournamentsDataLoading,
    finishedTournamentsDataLoading,
    totalStatisticsDataLoading)
  ) {
    return (
      <MainWrapper>
        <Grid container justifyContent="center" alignItems="center">
          <Box>
            <video
              src="/video.mp4"
              autoPlay
              loop
              muted
              playsInline
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'cover',
              }}
            />
          </Box>
        </Grid>
      </MainWrapper>
    )
  }

  return (
    <MainWrapper maxWidth="md">
      <Grid mb={4} container spacing={2}>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography variant="h5">Welcome to Fun Padel World</Typography>
          <Typography variant="body1" mt={2} sx={{ color: 'grey' }}>
            Join us for competition, camaraderie, and the true spirit of padel.
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography variant="h5">Platform Statistic</Typography>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'center' }}>
          <Grid item container spacing={2} mt={1}>
            <Grid item xs={12}>
              <TextField
                style={{ width: '100%' }}
                label="Total Users Registered"
                value={totalStatisticsData?.user_count || 0}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Link to="/organizations">
                <TextField
                  style={{ width: '100%' }}
                  label="Total Organizations Created"
                  value={totalStatisticsData?.organization_count || 0}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Link to="/tournaments">
                <TextField
                  style={{ width: '100%' }}
                  label="Total Tournaments finished"
                  value={totalStatisticsData?.tournament_count || 0}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Link>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={6}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CardMedia
            component="img"
            image={
              themeMode === 'dark'
                ? '/mascot_dark_theme.png'
                : '/mascot_light_theme.png'
            }
            alt="logo"
            sx={{ maxWidth: '300px', height: 'auto' }}
          />
        </Grid>

        {inProgressTournamentsData?.data.length > 0 && (
          <>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Typography variant="h5">Live tournaments</Typography>
                <PulsingIcon color="error" fontSize="medium" />
              </Box>
            </Grid>
            {inProgressTournamentsData?.data.map((tournament) => (
              <Grid item xs={12} md={4} key={tournament.id}>
                <TournamentBlock
                  tournament={tournament}
                  startTimeFormat="DD.MM.YYYY hh:mm A"
                  themeMode={themeMode}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Pagination
                count={inProgressCountCreated}
                page={inProgressPageCreated}
                shape="rounded"
                onChange={setInProgressPageCreated}
                siblingCount={0}
                size="medium"
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography variant="h5">Open tournaments</Typography>
        </Grid>
        {createdTournamentsData?.data.length > 0 ? (
          <>
            {createdTournamentsData?.data.map((tournament) => (
              <Grid item xs={12} md={4} key={tournament.id}>
                <TournamentBlock
                  showAvailable
                  tournament={tournament}
                  startTimeFormat="DD.MM.YYYY hh:mm A"
                  themeMode={themeMode}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Pagination
                count={countCreated}
                page={currentPageCreated}
                shape="rounded"
                onChange={handlePageCreatedChange}
                siblingCount={0}
                size="medium"
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12} sx={{ textAlign: 'center', color: 'grey' }}>
            <Typography variant="body1">No open tournaments</Typography>
          </Grid>
        )}

        <Grid item mt={2} xs={12} sx={{ textAlign: 'center' }}>
          <Typography variant="h5">Latest finished tournaments</Typography>
        </Grid>

        {finishedTournamentsData?.data.length > 0 ? (
          <>
            {finishedTournamentsData?.data.map((tournament) => (
              <Grid item xs={12} md={4} key={tournament.id}>
                <TournamentBlock
                  tournament={tournament}
                  startTimeFormat="DD.MM.YYYY hh:mm A"
                  themeMode={themeMode}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Pagination
                count={countFinished}
                page={currentPageFinished}
                shape="rounded"
                onChange={handlePageFinishedChange}
                siblingCount={0}
                size="medium"
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12} sx={{ textAlign: 'center', color: 'grey' }}>
            <Typography variant="body1">No finished tournaments</Typography>
          </Grid>
        )}
      </Grid>
    </MainWrapper>
  )
}
