import React from 'react'
import { Avatar, Box, Typography } from '@mui/material'

export function RemainingPlayersAvatar({ count }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 60,
      }}
    >
      <Avatar
        sx={{
          width: 50,
          height: 50,
          border: '1px solid black',
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" sx={{ color: 'black' }}>
          +{count}
        </Typography>
      </Avatar>
      <Typography
        variant="caption"
        sx={{
          fontSize: '0.7rem',
          mt: 0.5,
          width: 'max-content',
          textAlign: 'center',
          whiteSpace: 'nowrap',
          margin: '0 auto',
        }}
      >
        more
      </Typography>
    </Box>
  )
}
