import useSWRMutation from 'swr/mutation'
import { getFetcher, postFetcher } from './fetchers'

export function useCheckoutSession() {
  return useSWRMutation(`/stripe/create_checkout_session`, postFetcher)
}

export function useCancelSubscription() {
  return useSWRMutation(`/stripe/cancel_subscription`, postFetcher)
}

export function useContinueSubscription() {
  return useSWRMutation(`/stripe/continue_subscription`, postFetcher)
}

export function useSubscriptionRemainingTime() {
  return useSWRMutation(`/stripe/subscription_remaining_time`, getFetcher)
}
