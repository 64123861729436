import React from 'react'
import dayjs from 'dayjs'
import { Controller } from 'react-hook-form'

import { MobileDatePicker, MobileTimePicker } from '@mui/x-date-pickers'

/* eslint-disable react/jsx-props-no-spreading */
export function CustomDatePicker({
  control,
  name,
  readOnly = false,
  ignoreValidation = false,
  ...props
}) {
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: ignoreValidation
          ? {}
          : {
              min: (date) => {
                const currentDate = dayjs().startOf('day')
                const inputDate = dayjs(date).startOf('day')

                return inputDate.isSame(currentDate)||
                  inputDate.isAfter(currentDate)
                  ? true
                  : 'Please, enter current or future date'
              },
            },
      }}
      render={({ field: { ref, onBlur, name, ...field }, fieldState }) => (
        <MobileDatePicker
          {...field}
          {...props}
          readOnly={readOnly}
          format="DD.MM.YYYY"
          inputRef={ref}
          slotProps={{
            textField: {
              onBlur,
              name,
              error: !!fieldState.error,
              helperText: fieldState.error?.message,
            },
          }}
        />
      )}
    />
  )
}

export function CustomTimePicker({
  control,
  name,
  readOnly = false,
  ...props
}) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, onBlur, name, ...field }, fieldState }) => (
        <MobileTimePicker
          {...field}
          {...props}
          readOnly={readOnly}
          inputRef={ref}
          openTo="hours"
          views={['hours', 'minutes']}
          slotProps={{
            textField: {
              onBlur,
              name,
              error: !!fieldState.error,
              helperText: fieldState.error?.message,
            },
          }}
        />
      )}
    />
  )
}
