import useSWRMutation from 'swr/mutation'
import { postFetcher } from './fetchers'

export function useLogin() {
  return useSWRMutation('/jwt/login', postFetcher)
}

export function useRegister() {
  return useSWRMutation('/jwt/register', postFetcher)
}

export function useLogout() {
  return useSWRMutation('/jwt/logout', postFetcher)
}

export function useRefreshToken() {
  return useSWRMutation('/jwt/refresh_token', postFetcher)
}
