import React from 'react'

import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'

export function DeleteTournamentDialog({
  openDeleteTournamentModal,
  handleCloseDeleteTournamentModal,
  handleConfirmDeleteTournament,
}) {
  return (
    <Dialog
      open={openDeleteTournamentModal}
      onClose={handleCloseDeleteTournamentModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Are you sure you want to delete the tournament?
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          Once deleted, the tournament will be unavailable
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseDeleteTournamentModal}
          color="primary"
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirmDeleteTournament}
          color="error"
          variant="contained"
          autoFocus
        >
          Delete Tournament
        </Button>
      </DialogActions>
    </Dialog>
  )
}
