import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

import { styled } from '@mui/material/styles'

export const PulsingIcon = styled(FiberManualRecordIcon)({
  animation: 'pulse 1s infinite',
  '@keyframes pulse': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.5,
    },
    '100%': {
      opacity: 1,
    },
  },
})
