import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import {
  Button,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'

import { MainWrapper } from 'components/layout/MainWrapper/MainWrapper'
import { useThemeSwitcher } from 'components/ThemeProvider/ThemeProvider'
import { useAllUsers, useSearchUsers } from 'hooks/api/userHooks'

import styles from 'styles/main.module.scss'

export function Users({ isAdmin }) {
  const { themeMode } = useThemeSwitcher()
  const themeClass = themeMode === 'dark' ? styles.darkTheme : styles.lightTheme
  const navigate = useNavigate()
  const [searchList, setSearchList] = useState([])
  const [searchPlayerName, setSearchPlayerName] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [showSearchResults, setShowSearchResults] = useState(false)

  const limit = 10
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(0)
  const offset = (currentPage - 1) * limit

  const { data } = useAllUsers(isAdmin, limit, offset)
  const count = Math.ceil(total / limit)

  useEffect(() => {
    if (data?.total) {
      setTotal(data.total)
    }
  }, [data])

  const handleCreateUserClick = () => {
    navigate('/create-user')
  }

  const handlePageChange = (_, page) => {
    setCurrentPage(page)
  }

  const { trigger } = useSearchUsers({
    searchString: searchPlayerName,
    is_deleted: false,
  })

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      if (searchPlayerName.length > 2) {
        trigger(
          {},
          {
            onSuccess: (usersData) => {
              setSearchList(usersData)
              setShowSearchResults(true)
            },
            onError: (data) => setErrorMessage(data.info.error),
          }
        )
      } else {
        setSearchList([])
        setShowSearchResults(false)
      }
    }, 500)

    return () => clearTimeout(searchTimeout)
  }, [searchPlayerName, trigger])

  return (
    <MainWrapper
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
      maxWidth="md"
    >
      <Grid mb={4} container spacing={2}>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography variant="h5">Users</Typography>
        </Grid>

        <Grid item md={4} />
        <Grid item xs={12} md={4}>
          <Button
            style={{ width: '100%' }}
            onClick={handleCreateUserClick}
            type="submit"
            variant='contained'
          >
            Create User
          </Button>
        </Grid>
        <Grid item md={4} />
        <Grid item xs={12}>
          <TextField
            id="outlined-search"
            label="Search users"
            type="search"
            variant="outlined"
            value={searchPlayerName}
            onChange={(e) => setSearchPlayerName(e.target.value)}
            fullWidth
          />
        </Grid>
        {showSearchResults ? (
          <ListUsers users={searchList} themeClass={themeClass} />
        ) : (
          <ListUsers users={data} themeClass={themeClass} />
        )}
        {!showSearchResults && (
          <Grid item xs={12}>
            <Pagination
              count={count}
              page={currentPage}
              shape="rounded"
              onChange={handlePageChange}
              siblingCount={0}
              size="medium"
            />
          </Grid>
        )}
      </Grid>
    </MainWrapper>
  )
}

function ListUsers({ users, themeClass }) {
  return (
    <Grid item xs={12}>
      <TableContainer component={Paper} className={themeClass}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.data?.map((user) => (
              <TableRow
                key={user.id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  textDecoration: 'none',
                }}
              >
                <TableCell component="th" scope="row">
                  <Link to={`/users/${user.id}`} className={styles.link}>
                    {user.name} {user.surname}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={`/users/${user.id}`} className={styles.link}>
                    {user.username}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={`/users/${user.id}`} className={styles.link}>
                    {user.email}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={`/users/${user.id}`} className={styles.link}>
                    {user.phone}
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}
