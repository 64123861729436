import React, { useEffect } from 'react'
import { RouterProvider } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import CssBaseline from '@mui/material/CssBaseline'

import { router } from 'router'
import { useRefreshToken } from 'hooks/api/authHooks'
import { useAllRolePolicies } from 'hooks/api/roleHooks'

import { refreshJwtAndFetchPolicies } from 'utils'

import ThemeProvider from 'components/ThemeProvider/ThemeProvider'

// 10 mins
const INTERVAL_TIMEOUT = 10 * 60 * 1000

export function App() {
  const { trigger: refreshTokenTrigger } = useRefreshToken()
  const { trigger: allRolePoliciesTrigger } = useAllRolePolicies()

  useEffect(() => {
    const handleRedirect = (path) => {
      window.location.href = path
    }

    const intervalId = setInterval(() => {
      refreshJwtAndFetchPolicies(refreshTokenTrigger, allRolePoliciesTrigger, handleRedirect)
    }, INTERVAL_TIMEOUT)

    return () => clearInterval(intervalId)
  }, [refreshTokenTrigger, allRolePoliciesTrigger])

  return (
    <ThemeProvider>
      <CookiesProvider>
        <CssBaseline />
        <RouterProvider router={router} />
      </CookiesProvider>
    </ThemeProvider>
  )
}
