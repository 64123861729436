import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { Button, FormControl, Grid, TextField, Typography } from '@mui/material'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import dayjs from 'dayjs'

import { useCreateLocation } from 'hooks/api/locationHooks'

import { prepareCourtsForUpdate } from 'utils'

import { MainWrapper } from 'components/layout/MainWrapper/MainWrapper'
import { useThemeSwitcher } from 'components/ThemeProvider/ThemeProvider'

import { CustomTimePicker } from 'components/CustomPicker'
import { LocationCourts } from 'components/LocaitonCourts/LocationCourts'
import { CountryCitySelector } from 'components/CountryCitySelector/CountryCitySelector'
import { LocationPicker } from 'components/Map/Map'

/* eslint-disable react/jsx-props-no-spreading */
export function CreateLocation() {
  const { themeMode } = useThemeSwitcher()

  const navigate = useNavigate()

  const center = {
    lat: 40.40303919701655,
    lng: -3.70513916015625,
  }

  const [courts, setCourts] = useState([])
  const [locationDetails, setLocationDetails] = useState({
    lat: center.lat,
    lng: center.lng,
  })
  const [errorMessage, setErrorMessage] = useState('')

  const { trigger: createLocationTrigger } = useCreateLocation()

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      openingTime: dayjs(Date.now()),
      closingTime: dayjs(Date.now()),
    },
  })

  const handleLocationCreate = (data) => {
    let preparedCourts = []
    if (courts) {
      preparedCourts = prepareCourtsForUpdate(courts)
    }

    createLocationTrigger(
      {
        name: data.name,
        address: data.address,
        country: locationDetails.countryName,
        city: locationDetails.cityName,
        latitude: locationDetails.lat,
        longitude: locationDetails.lng,
        timezone: locationDetails.timezone,
        opening_time: data.openingTime,
        closing_time: data.closingTime,
        courts: preparedCourts,
      },
      {
        onSuccess: () => {
          navigate('/')
        },
        onError: (data) => setErrorMessage(data.info.error),
      }
    )
  }

  return (
    <MainWrapper
      maxWidth="md"
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    >
      <FormControl
        component="form"
        onSubmit={handleSubmit(handleLocationCreate)}
        fullWidth
      >
        <Grid container spacing={2} mb={4} alignItems="flex-start">
          <Grid item xs={12} textAlign="center">
            <Typography variant="h5">Create location</Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              style={{ width: '100%' }}
              error={!!errors.name}
              label="Location name"
              {...register('name', { required: true })}
            />
          </Grid>

          <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' } }} />
          <Grid item md={6} container spacing={2} alignItems="flex-start">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={6}>
                <CustomTimePicker
                  sx={{ width: '100%' }}
                  label="Opening Time *"
                  control={control}
                  name="openingTime"
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTimePicker
                  sx={{ width: '100%' }}
                  label="Closing Time *"
                  control={control}
                  name="closingTime"
                />
              </Grid>
            </LocalizationProvider>

            <Grid item xs={12}>
              <CountryCitySelector setLocationDetails={setLocationDetails} />
            </Grid>
          </Grid>
          <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' } }} />

          <Grid item container spacing={2} xs={12} sx={{ textAlign: 'center' }}>
            <Grid item xs={12}>
              <LocationPicker
                locationDetails={locationDetails}
                setLocationDetails={setLocationDetails}
                themeMode={themeMode}
              />
            </Grid>
          </Grid>

          <Grid item container spacing={2} xs={12} sx={{ textAlign: 'center' }}>
            <Grid item xs={12}>
              <TextField
                required
                style={{ width: '100%' }}
                error={!!errors.address}
                label="Address"
                {...register('address', { required: true })}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <LocationCourts
              courts={courts}
              setCourts={setCourts}
              readOnly={false}
            />
          </Grid>

          <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }} />
          <Grid item xs={12} md={4}>
            <Button style={{ width: '100%' }} type="submit" variant="contained">
              Create
            </Button>
          </Grid>
          <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }} />
        </Grid>
      </FormControl>
    </MainWrapper>
  )
}
