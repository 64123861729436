import React from 'react'

import { RoundRobinTeamCreated } from 'pages/Tournament/RoundRobin/RoundRobinCreated/RoundRobinTeamCreated'
import { RoundRobinTeamInProgress } from 'pages/Tournament/RoundRobin/RoundRobinInProgress/RoundRobinTeamInProgress'
import { RoundRobinTeamFinished } from 'pages/Tournament/RoundRobin/RoundRobinFinished/RoundRobinTeamFinished'
import { NotFound } from 'pages/NotFound/NotFound'

import { MainWrapper } from 'components/layout/MainWrapper/MainWrapper'

import { useRoundRobinTeam } from 'hooks/api/tournamentHooks'

function RoundRobinTeamComponent({ tournamentId }) {
  const {
    data: RoundRobinData,
    isLoading,
    mutate,
  } = useRoundRobinTeam(tournamentId)

  if (isLoading) {
    return <MainWrapper>loading...</MainWrapper>
  }

  switch (RoundRobinData.status) {
    case 'created':
      return <RoundRobinTeamCreated data={RoundRobinData} mutate={mutate} />
    case 'in_progress':
      return <RoundRobinTeamInProgress data={RoundRobinData} mutate={mutate} />
    case 'finished':
      return <RoundRobinTeamFinished data={RoundRobinData} mutate={mutate} />
    default:
      return <NotFound message='Tournament not found' />
  }
}

export function RoundRobin({ tournamentType, tournamentId }) {
  switch (tournamentType) {
    case 'round_robin_team':
      return <RoundRobinTeamComponent tournamentId={tournamentId} />
    default:
      return <NotFound message='Tournament not found' />
  }
}
