import React from 'react'
import { useParams } from 'react-router-dom'

import { Americano } from 'pages/Tournament/Americano/Americano'
import { RoundRobin } from 'pages/Tournament/RoundRobin/RoundRobin'
import { NotFound } from 'pages/NotFound/NotFound'
import { useTournamentType } from 'hooks/api/tournamentHooks'

import { MainWrapper } from 'components/layout/MainWrapper/MainWrapper'

export function Tournament() {
  const { tournamentId } = useParams()
  const { data, isLoading, mutate } = useTournamentType(tournamentId)
  const tournamentType = data?.type

  if (isLoading) {
    return <MainWrapper>loading...</MainWrapper>
  }

  if (data) {
    switch (tournamentType) {
      case 'americano_single':
      case 'americano_team':
        return (
          <Americano
            tournamentType={tournamentType}
            tournamentId={tournamentId}
            mutate={mutate}
          />
        )
      case 'round_robin_team':
        return (
          <RoundRobin
            tournamentType={tournamentType}
            tournamentId={tournamentId}
            mutate={mutate}
          />
        )
      default:
        return <NotFound message='Tournament not found' />
    }
  } else {
    return <NotFound message='Tournament not found' />
  }
}
