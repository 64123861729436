import React from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material'

import styles from 'styles/main.module.scss'

export function Directions({
  isDirectionsDialogOpen,
  closeDirectionsDialog,
  latitude = 0,
  longitude = 0,
}) {
  const googleMapLink = `https://maps.google.com/?q=${latitude},${longitude}`

  const wazeMapLink = `https://waze.com/ul?ll=${latitude},${longitude}&navigate=yes`

  return (
    <Dialog
      open={isDirectionsDialogOpen}
      onClose={closeDirectionsDialog}
      fullWidth
    >
      <DialogTitle>Directions</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Link
              className={styles.link}
              to={googleMapLink}
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: 'block', width: '100%' }}
            >
              <Button
                style={{ width: '100%' }}
                type="button"
                variant="contained"
              >
                Open in Google Maps
              </Button>
            </Link>
          </Grid>
          <Grid item xs={12} md={6}>
            <Link
              className={styles.link}
              to={wazeMapLink}
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: 'block', width: '100%' }}
            >
              <Button
                style={{ width: '100%' }}
                type="button"
                variant="contained"
              >
                Open in Waze Maps
              </Button>
            </Link>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeDirectionsDialog}
          color="primary"
          variant="contained"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
