import React from 'react'
import { Link } from 'react-router-dom'
import { Grid, Paper, Typography } from '@mui/material'

import styles from './OrganizationBlock.module.scss'

export function OrganizationBlock({ organization, themeMode }) {
  const themeClass = themeMode === 'dark' ? styles.darkTheme : styles.lightTheme

  return (
    <Link
      to={`/organizations/${organization.id}`}
      className={styles.organizationBlockLink}
    >
      <Paper className={`${styles.organizationBlock} ${themeClass}`}>
        <Typography
          variant="h6"
          noWrap
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {organization.name}
        </Typography>
        <Grid className={styles.organizationType}>
          <Typography variant="body2">
            {organization.has_permanent_location ? 'club' : 'community'}
          </Typography>
        </Grid>
      </Paper>
    </Link>
  )
}
