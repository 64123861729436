import React, { useState, useRef } from 'react'
import { useDropzone } from 'react-dropzone'
import AvatarEditor from 'react-avatar-editor'
import imageCompression from 'browser-image-compression'
import {
  Grid,
  Button,
  Badge,
  Avatar,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slider,
  Typography,
  useMediaQuery,
  Box,
  Stack,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'

/* eslint-disable react/jsx-props-no-spreading */
export function AvatarUpload({ gender, currentAvatarURL, onUpload, onReset }) {
  const [image, setImage] = useState(null)
  const editorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [scale, setScale] = useState(1)

  const isSmallScreen = useMediaQuery('(max-width:600px)')
  const editorSize = isSmallScreen ? 200 : 300
  const borderSize = 50
  const borderRadius = editorSize / 2

  const defaultAvatarURL = gender === 'female' ? '/default-female-avatar.png' : '/default-male-avatar.png'

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      setImage(URL.createObjectURL(acceptedFiles[0]))
    },
  })

  const handleSave = async () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas()
      canvas.toBlob(async (blob) => {
        const compressedBlob = await imageCompression(blob, {
          maxSizeMB: 1,
          maxWidthOrHeight: 1000,
          useWebWorker: true,
        })
        onUpload(compressedBlob)
        setOpen(false)
        setImage(null)
      })
    }
  }

  const handleReset = () => {
    setImage(null)
    onReset()
    setOpen(false)
  }

  const handleScaleChange = (_, newValue) => {
    setScale(newValue)
  }

  return (
    <Grid>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          <IconButton
            size="small"
            color="primary"
            onClick={() => setOpen(true)}
            sx={{
              backgroundColor: 'primary.main',
              '&:hover': {
                backgroundColor: 'primary.dark',
              },
            }}
          >
            <EditIcon sx={{ color: 'white' }} fontSize="small" />
          </IconButton>
        }
      >
        <Avatar
          alt="Current Avatar"
          src={currentAvatarURL || defaultAvatarURL}
          sx={{
            width: 100,
            height: 100,
            margin: '0 auto',
            border: '0.1px solid lightgray',
          }}
        />
      </Badge>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Update Avatar</DialogTitle>
        <DialogContent dividers>
          {image ? (
            <Grid container direction="column" alignItems="center">
              <AvatarEditor
                ref={editorRef}
                image={image}
                width={editorSize}
                height={editorSize}
                border={borderSize}
                borderRadius={borderRadius}
                scale={scale}
              />
              <Typography id="scale-slider" gutterBottom>
                Zoom
              </Typography>
              <Slider
                value={scale}
                min={1}
                max={3}
                step={0.1}
                onChange={handleScaleChange}
                aria-labelledby="scale-slider"
                sx={{ width: editorSize + borderSize * 2 }}
              />
              <Stack direction="row" spacing={2} sx={{ mt: 2, mb: 2 }}>
                <Button onClick={() => setImage(null)} variant="outlined">
                  Cancel Selected Image
                </Button>
                <Button
                  onClick={handleSave}
                  color="success"
                  variant="contained"
                >
                  Save
                </Button>
              </Stack>
            </Grid>
          ) : (
            <Box
              {...getRootProps({ className: 'dropzone' })}
              sx={{
                border: '2px dashed #ccc',
                padding: 2,
                textAlign: 'center',
                cursor: 'pointer',
              }}
            >
              <input {...getInputProps()} />
              <Typography variant="body1">
                Drag &apos;n&apos; drop an image here, or click to select one
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReset} color="error" variant="contained">
            Reset to Default
          </Button>
          <Button
            onClick={() => setOpen(false)}
            color="primary"
            variant="contained"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}
