import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { Grid, TextField, Button } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import dayjs from 'dayjs'

import { Map } from 'components/Map/Map'
import { CustomDatePicker, CustomTimePicker } from 'components/CustomPicker'
import { Directions } from 'components/Directions/Directions'

import styles from 'styles/main.module.scss'

/* eslint-disable react/jsx-props-no-spreading */
export function TournamentInfo({
  tournamentData,
  organizationData,
  themeMode,
}) {
  const { register, watch, control } = useFormContext()

  const [openDirectionsDialog, setOpenDirectionsDialog] = useState(false)

  const handleCloseDirectionsDialog = () => {
    setOpenDirectionsDialog(false)
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2} item xs={12}>
            <Grid item xs={12}>
              <Link
                className={styles.organizationLink}
                to={`/organizations/${tournamentData.organization_id}`}
              >
                <TextField
                  style={{ width: '100%' }}
                  label={
                    organizationData?.has_permanent_location
                      ? 'Club'
                      : 'Community'
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                  value={organizationData?.name || ''}
                />
              </Link>
            </Grid>

            {tournamentData?.score_type === 'points' && (
              <Grid item xs={6}>
                <TextField
                  style={{ width: '100%' }}
                  label="Points to play"
                  InputProps={{
                    readOnly: true,
                  }}
                  {...register('americanoMaxScore', { required: true })}
                />
              </Grid>
            )}

            <Grid item xs={tournamentData?.score_type === 'points' ? 6 : 12}>
              <TextField
                style={{ width: '100%' }}
                label="Courts number"
                value={tournamentData.courts.length || 0}
                InputProps={{
                  readOnly: true,
                }}
                type="number"
              />
            </Grid>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={12}>
                <CustomDatePicker
                  sx={{ width: '100%' }}
                  label="Tournament date"
                  control={control}
                  name="startTime"
                  value={dayjs(watch('startTime'))}
                  readOnly
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTimePicker
                  sx={{ width: '100%' }}
                  label="Start Time"
                  control={control}
                  name="startTime"
                  value={dayjs(watch('startTime'))}
                  readOnly
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTimePicker
                  sx={{ width: '100%' }}
                  label="End time"
                  control={control}
                  name="endTime"
                  value={dayjs(watch('endTime'))}
                  readOnly
                />
              </Grid>
            </LocalizationProvider>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Map
            themeMode={themeMode}
            locationDetails={{
              lat: tournamentData.location.latitude,
              lng: tournamentData.location.longitude,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            style={{ width: '100%' }}
            label="Address"
            multiline
            value={tournamentData.location.address}
            inputProps={{
              readOnly: true,
            }}
            maxRows={10}
          />
        </Grid>

        <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }} />
        <Grid item xs={12} md={4} alignSelf="flex-start">
          <Button
            style={{ width: '100%' }}
            onClick={() => setOpenDirectionsDialog(true)}
            type="button"
            variant="contained"
          >
            Directions
          </Button>
        </Grid>
        <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }} />

        <Grid item xs={12}>
          <TextField
            style={{ width: '100%' }}
            label="Description"
            multiline
            {...register('description')}
            inputProps={{
              readOnly: true,
            }}
            maxRows={10}
          />
        </Grid>
      </Grid>

      <Directions
        latitude={tournamentData.location.latitude}
        longitude={tournamentData.location.longitude}
        isDirectionsDialogOpen={openDirectionsDialog}
        closeDirectionsDialog={handleCloseDirectionsDialog}
      />
    </>
  )
}
