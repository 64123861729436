import React from 'react'
import { Avatar, Box, Typography } from '@mui/material'

export function UserAvatar({ gender, alt = '', src = null, sx = null }) {
  let srcCopy = src

  if (src === null) {
    if (gender === 'female') {
      srcCopy = '/default-female-avatar.png'
    } else {
      srcCopy = '/default-male-avatar.png'
    }
  }

  return <Avatar alt={alt} src={srcCopy} sx={sx} />
}

export function UserAvatarWithName({ user, bold = false }) {
  let defaultAvatarURL
  if (user?.gender === 'female') {
    defaultAvatarURL = '/default-female-avatar.png'
  } else {
    defaultAvatarURL = '/default-male-avatar.png'
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 60,
      }}
    >
      <Avatar
        alt={`${user.name} ${user.surname}`}
        src={user.avatar_url || defaultAvatarURL}
        sx={{
          width: 50,
          height: 50,
          border: '1px solid black',
          backgroundColor: 'white',
        }}
      />
      <Typography
        variant="caption"
        sx={{
          fontSize: '0.7rem',
          mt: 0.5,
          width: 'max-content',
          textAlign: 'center',
          whiteSpace: 'nowrap',
          margin: '0 auto',
          fontWeight: bold ? 'bold' : 'normal',
        }}
      >
        {user.name.length > 8 ? `${user.name.substring(0, 6)}...` : user.name}
      </Typography>
      <Typography
        variant="caption"
        sx={{
          fontSize: '0.7rem',
          mt: 0.5,
          width: 'max-content',
          textAlign: 'center',
          whiteSpace: 'nowrap',
          margin: '0 auto',
          fontWeight: bold ? 'bold' : 'normal',
        }}
      >
        {user.surname && user.surname.length > 8
          ? `${user.surname.substring(0, 6)}...`
          : user.surname || <br />}
      </Typography>
    </Box>
  )
}
