import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useCookies } from 'react-cookie'

import { Button, FormControl, Grid, TextField, Typography } from '@mui/material'
import GoogleIcon from '@mui/icons-material/Google'

import { useLogin, useRefreshToken } from 'hooks/api/authHooks'
import { useAllRolePolicies } from 'hooks/api/roleHooks'
import { MainWrapper } from 'components/layout/MainWrapper/MainWrapper'

import { refreshJwtAndFetchPolicies, encodeToBase64 } from 'utils'

import styles from 'styles/main.module.scss'
import loginStyles from './Login.module.scss'

/* eslint-disable react/jsx-props-no-spreading */
export function Login() {
  const [, setCookies] = useCookies(['user'])

  const [step, setStep] = useState(0)
  const [errorMessage, setErrorMessage] = useState('')

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    setError,
    watch,
  } = useForm()

  const watchLogin = watch('login')

  const navigate = useNavigate()

  const { trigger: loginTrigger } = useLogin()
  const { trigger: refreshTokenTrigger } = useRefreshToken()
  const { trigger: allRolePoliciesTrigger } = useAllRolePolicies()

  const handleRedirect = (path) => {
    window.location.href = path
  }

  const handleLogin = (data) => {
    const payload = {
      login: data.login,
      password: data.password,
    }
    loginTrigger(payload, {
      onSuccess: (data) => {
        setCookies('user', encodeToBase64(JSON.stringify(data)), {
          path: '/',
        })
        refreshJwtAndFetchPolicies(refreshTokenTrigger, allRolePoliciesTrigger, handleRedirect)
        navigate('/')
      },
      onError: (data) => {
        if (!data?.info?.error) {
          setErrorMessage(data)
        }

        setErrorMessage(data.info.error)
      }
    })
  }

  const handleGoogleLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_DOMAIN}/api/auth/google`
  }

  const handleNextStep = () => {
    if (!dirtyFields.login) {
      setError('login', { type: 'required', message: '' })
    } else {
      // TODO: Search user by login option
      const payload = {
        login: watchLogin,
      }

      loginTrigger(payload, {
        onSuccess: (data) => {
          const payload = data.info
          // TODO: remove this
          if (payload === 'no password provided') {
            setStep((s) => s + 1)
          } else {
            navigate('/')
          }
        },
        onError: (data) => {
          const payload = data.info
          switch (payload.error) {
            case 'no password provided':
              setStep((s) => s + 1)
              break

            case 'user has no password':
            case 'user not found':
              navigate('/register')
              break

            default:
              setErrorMessage(payload.error)
              break
          }
        },
      })
    }
  }
  const handlePrevStep = () => setStep((s) => s - 1)

  return (
    <MainWrapper
      maxWidth="sm"
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    >
      <FormControl
        component="form"
        onSubmit={handleSubmit(handleLogin)}
        fullWidth
        noValidate
      >
        <Grid container spacing={2} mt={{ xs: 5, md: 20 }}>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h5">Login</Typography>
          </Grid>

          {
            [
              <>
                <Grid item xs={12}>
                  <TextField
                    style={{ width: '100%' }}
                    error={!!errors.login}
                    label="Username/ Phone/ Email/"
                    {...register('login', {
                      required: true,
                      setValueAs: (v) => v.toLowerCase(),
                    })}
                    onChange={(e) => {
                      const transformedValue = e.target.value
                        .replace(/\s+/g, '_')
                        .toLowerCase()
                      e.target.value = transformedValue
                      register('login').onChange(e)
                    }}
                  />
                </Grid>

                <Grid item xs={3} />
                <Grid item xs={6}>
                  <Button
                    // disabled={!isLoginFilled}
                    style={{ width: '100%' }}
                    onClick={handleNextStep}
                    type="button"
                    variant="contained"
                  >
                    Next step
                  </Button>
                </Grid>
                <Grid item xs={3} />

                <Grid item textAlign="center" xs={12}>
                  <Button
                    startIcon={<GoogleIcon />}
                    variant="outlined"
                    onClick={handleGoogleLogin}
                    className={loginStyles.googleButton}
                  >
                    Login with Google
                  </Button>
                </Grid>
              </>,

              <>
                {/* TODO: login data migrate to password textfield with one grid.
                For now dont know how to fix it without second grid */}
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <TextField
                      style={{ width: '100%' }}
                      error={!!errors.password}
                      required
                      type="password"
                      label="Password"
                      {...register('password', { required: true })}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Button
                    style={{ width: '100%' }}
                    onClick={handlePrevStep}
                    type="button"
                    variant="contained"
                  >
                    Prev step
                  </Button>
                </Grid>

                <Grid item xs={6}>
                  <Button
                    style={{ width: '100%' }}
                    type="submit"
                    variant="contained"
                  >
                    Login
                  </Button>
                </Grid>
              </>,
            ][step]
          }

          {/* Empty grid for 'remember password' */}
          <Grid item xs={6} />
          <Grid item xs={6} textAlign="right">
            <Link to="/register" className={styles.greyLink}>
              No account? Register
            </Link>
          </Grid>
        </Grid>
      </FormControl>
    </MainWrapper>
  )
}
