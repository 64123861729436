import React from 'react'
import { Avatar, Box, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

export function AvailableAvatar({
  label = 'Available',
  disableSecondLableLine = false,
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 60,
      }}
    >
      <Avatar
        sx={{
          width: 50,
          height: 50,
          border: '1px solid',
          borderColor: 'primary.main',
          backgroundColor: 'white',
        }}
      >
        <AddIcon sx={{ color: 'primary.main' }} fontSize="small" />
      </Avatar>
      <Typography
        variant="caption"
        sx={{
          fontSize: '0.7rem',
          mt: 0.5,
          width: 'max-content',
          textAlign: 'center',
          whiteSpace: 'nowrap',
          margin: '0 auto',
          color: 'primary.main',
        }}
      >
        {label}
      </Typography>
      {!disableSecondLableLine && (
        <Typography
          variant="caption"
          sx={{
            fontSize: '0.7rem',
            mt: 0.5,
            width: 'max-content',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            margin: '0 auto',
            color: 'primary.main',
          }}
        >
          <br />
        </Typography>
      )}
    </Box>
  )
}
