import React from 'react'
import { useParams } from 'react-router-dom'

import { useUserById } from 'hooks/api/userHooks'
import { useTournaments } from 'hooks/api/tournamentHooks'

import { UserBase } from './UserBase'
import { NotFound } from '../NotFound/NotFound'

export function User() {
  const { userId } = useParams()
  const { data: userData } = useUserById(userId)
  const { data: userFinishedTournamentsData } = useTournaments({
    user_id: userId,
    status: 'finished',
    order: 'desc',
    limit: 100,
  })

  return userData ? (
    <UserBase
      userData={userData}
      userFinishedTournamentsData={userFinishedTournamentsData?.data}
    />
  ) : (
    <NotFound message='User not found' />
  )
}
