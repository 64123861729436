async function defaultResponseHandler(res) {
  const t = await res.text()
  const j = t ? JSON.parse(t) : undefined

  if (res.ok) {
    return j
  }

  const error = new Error(`fetch ${res.url}`)
  error.url = res.url
  error.info = j
  error.status = res.status

  throw error
}

export const getFetcher = (url) =>
  fetch(`/api${url}`, {
    method: 'GET',
    credentials: 'include',
  }).then(defaultResponseHandler)

export const postFetcher = (url, { arg }) => {
  const isFormData = arg instanceof FormData

  return fetch(`/api${url}`, {
    method: 'POST',
    body: isFormData ? arg : JSON.stringify(arg),
    credentials: 'include',
    headers: isFormData ? {} : { 'Content-Type': 'application/json' },
  }).then(defaultResponseHandler)
}

export const deleteFetcher = (url, { arg }) =>
  fetch(`/api${url}`, {
    method: 'DELETE',
    body: JSON.stringify(arg),
    credentials: 'include',
  }).then(defaultResponseHandler)

export const putFetcher = (url, { arg }) =>
  fetch(`/api${url}`, {
    method: 'PUT',
    body: JSON.stringify(arg),
    credentials: 'include',
  }).then(defaultResponseHandler)
