import React from 'react'
import { Link } from 'react-router-dom'

import { Grid, Typography, Paper } from '@mui/material'
import { UserAvatarWithName } from 'components/Avatar/UserAvatar'

import { ScoreDisplay } from 'components/ScoreDisplay/ScoreDisplay'

import styles from 'styles/main.module.scss'

export function RoundsSchedule({
  organizationCourts,
  round,
  roundNumber,
  viewMode,
  themeMode,
}) {
  return (
    <>
      <Grid item xs={12} mt={4}>
        <Typography variant="h6" sx={{ textAlign: 'center' }}>
          Round {roundNumber + 1}
        </Typography>
      </Grid>
      <Grid container spacing={2} mb={8} mt={1} justifyContent="center">
        {round.courts.map((court, courtNumber) => (
          <RoundCourtsSchedule
            key={courtNumber}
            organizationCourts={organizationCourts}
            court={court}
            courtNumber={courtNumber}
            team1Score={court.result.team1_score}
            team2Score={court.result.team2_score}
            viewMode={viewMode}
            themeMode={themeMode}
          />
        ))}
      </Grid>
    </>
  )
}

export function RoundCourtsSchedule({
  organizationCourts,
  court,
  courtNumber,
  team1Score,
  team2Score,
  viewMode,
  themeMode,
}) {
  const themeClass = themeMode === 'dark' ? styles.darkTheme : styles.lightTheme

  const courtName = organizationCourts[courtNumber].name.includes('court')
    ? organizationCourts[courtNumber].name
    : `Court ${organizationCourts[courtNumber].name}`

  return (
    <Grid item xs={12} md={6}>
      <Paper
        sx={{
          padding: '16px',
          width: '100%',
          maxWidth: '800px',
          backgroundColor: '#f5f5f5',
          borderRadius: '12px',
        }}
        className={themeClass}
      >
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={12} sx={{ textAlign: 'left' }}>
            <Typography variant="h6">{courtName}</Typography>
          </Grid>

          <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' } }} />
          <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
            <ScoreDisplay team1Score={team1Score} team2Score={team2Score} />
          </Grid>
          <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' } }} />

          <Grid
            item
            xs={6}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {viewMode ? (
              <>
                <Grid item>
                  <UserAvatarWithName
                    key={court.teams[0].players[0].id}
                    user={court.teams[0].players[0]}
                  />
                </Grid>
                {court.teams[0].players[1] && (
                  <Grid item style={{ marginLeft: '16px' }}>
                    <UserAvatarWithName
                      key={court.teams[0].players[1].id}
                      user={court.teams[0].players[1]}
                    />
                  </Grid>
                )}
              </>
            ) : (
              <>
                <Link
                  className={styles.inheritColorLink}
                  to={`/users/${court.teams[0].players[0].id}`}
                >
                  <Grid item>
                    <UserAvatarWithName
                      key={court.teams[0].players[0].id}
                      user={court.teams[0].players[0]}
                    />
                  </Grid>
                </Link>
                {court.teams[0].players[1] && (
                  <Link
                    className={styles.inheritColorLink}
                    to={`/users/${court.teams[0].players[1].id}`}
                  >
                    <Grid item style={{ marginLeft: '16px' }}>
                      <UserAvatarWithName
                        key={court.teams[0].players[1].id}
                        user={court.teams[0].players[1]}
                      />
                    </Grid>
                  </Link>
                )}
              </>
            )}
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {viewMode ? (
              <>
                <Grid item>
                  <UserAvatarWithName
                    key={court.teams[1].players[0].user_id}
                    user={court.teams[1].players[0]}
                  />
                </Grid>
                {court.teams[1].players[1] && (
                  <Grid item style={{ marginLeft: '16px' }}>
                    <UserAvatarWithName
                      key={court.teams[1].players[1].user_id}
                      user={court.teams[1].players[1]}
                    />
                  </Grid>
                )}
              </>
            ) : (
              <>
                <Link
                  className={styles.inheritColorLink}
                  to={`/users/${court.teams[1].players[0].user_id}`}
                >
                  <Grid item>
                    <UserAvatarWithName
                      key={court.teams[1].players[0].user_id}
                      user={court.teams[1].players[0]}
                    />
                  </Grid>
                </Link>
                {court.teams[1].players[1] && (
                  <Link
                    className={styles.inheritColorLink}
                    to={`/users/${court.teams[1].players[1].user_id}`}
                  >
                    <Grid item style={{ marginLeft: '16px' }}>
                      <UserAvatarWithName
                        key={court.teams[1].players[1].user_id}
                        user={court.teams[1].players[1]}
                      />
                    </Grid>
                  </Link>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}
