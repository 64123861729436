import React, { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from '@mui/material'

import { useSearchLocations } from 'hooks/api/locationHooks'

import { LocationBlock } from 'components/LocationBlock/LocationBlock'

/* eslint-disable react/jsx-props-no-spreading */
export function SelectLocationDialog({ open, onClose, themeMode, callback }) {
  const [searchLocationName, setSearchLocationName] = useState('')
  const [locations, setLocations] = useState([])
  const [errorMessage, setErrorMessage] = useState('')

  const limit = 3

  const { trigger: searchLocationsTrigger } = useSearchLocations({
    searchLocationName,
    limit,
    offset: 0,
  })

  const searchLocations = () => {
    searchLocationsTrigger(
      {},
      {
        onSuccess: (locations) => {
          setLocations(locations)
        },
        onError: (e) => setErrorMessage(e.info.error),
      }
    )
  }

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      searchLocations()
    }, 1000)

    return () => clearTimeout(searchTimeout)
  }, [searchLocationName])

  const handleOnClose = () => {
    setLocations([])
    setSearchLocationName('')
    onClose()
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      sx={{
        marginTop: {
          xs: 0,
          md: 15,
        },
        '& .MuiDialog-container': {
          alignItems: 'start',
        },
      }}
      open={open}
      onClose={handleOnClose}
    >
      <Collapse in={Boolean(errorMessage)}>
        <Alert
          severity="error"
          onClose={() => {
            setErrorMessage('')
          }}
        >
          Error: {errorMessage}
        </Alert>
      </Collapse>

      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} mt={1}>
            <TextField
              id="outlined-search"
              label="Club Name"
              type="search"
              variant="outlined"
              value={searchLocationName}
              onChange={(e) => setSearchLocationName(e.target.value)}
              fullWidth
            />
          </Grid>
          {locations && (
            <Grid item container spacing={2}>
              {locations?.data?.map((location) => (
                <Grid
                  key={location.id}
                  item
                  xs={12}
                  onClick={() => {
                    callback(location)
                    handleOnClose()
                  }}
                >
                  <LocationBlock
                    key={location.id}
                    location={location}
                    themeMode={themeMode}
                    withoutLink
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
