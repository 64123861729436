import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Cookies } from 'react-cookie'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { useForm, FormProvider } from 'react-hook-form'
import { AiOutlineUser, AiOutlineTeam } from 'react-icons/ai'
import EditIcon from '@mui/icons-material/Edit'
import UpgradeIcon from '@mui/icons-material/Upgrade'
import dayjs from 'dayjs'
import { useCurrentUser } from 'hooks/cookies'
import {
  useUserBasicStatistic,
  useUserAdvancedStatistic,
} from 'hooks/api/statisticHooks'
import {
  useUpdateUser,
  useUploadAvatar,
  useResetAvatar,
} from 'hooks/api/userHooks'
import { useRefreshToken } from 'hooks/api/authHooks'
import {
  useCancelSubscription,
  useSubscriptionRemainingTime,
} from 'hooks/api/stripeHooks'

import { useResponsive } from 'hooks/responsive'

import {
  capitalizeWords,
  getUserPlaceInTournament,
  getUserRoleIdFromToken,
  isMethodAllowed,
} from 'utils'

import { MainWrapper } from 'components/layout/MainWrapper/MainWrapper'
import { useThemeSwitcher } from 'components/ThemeProvider/ThemeProvider'
import { UserFinishedTournamentBlock } from 'components/TournamentBlock/TournamentBlock'
import { PlanCard } from 'components/PlanCard/PlanCard'
import { UsernameField } from 'components/Fields/Username'
import { GenderField } from 'components/Fields/Gender'
import { AvatarUpload } from 'components/AvatarUpload/AvatarUpload'
import { UserAvatar } from 'components/Avatar/UserAvatar'

import products from 'productsConfig'

import styles from 'styles/main.module.scss'

const cookiesGlobal = new Cookies()

/* eslint-disable react/jsx-props-no-spreading */
export function UserBase({ userData, userFinishedTournamentsData }) {
  const { isScreenMdOrMore } = useResponsive()
  const { themeMode } = useThemeSwitcher()
  const themeClass = themeMode === 'dark' ? styles.darkTheme : styles.lightTheme
  const navigate = useNavigate()
  const [user] = useCurrentUser()
  const userRoleId = getUserRoleIdFromToken(cookiesGlobal.get('jwt'))
  const isRoleBasic =
    userRoleId === products.player_basic.connected_role_id ||
    userRoleId === products.administrator_basic.connected_role_id
  const isMyPage = userData?.id === user?.id

  const { data: userStatisticData } = useUserBasicStatistic(userData?.id)
  const { trigger: loadAdvancedStatistic, data: userAdvancedStatisticData } =
    useUserAdvancedStatistic(userData?.id)

  const defaultValues = {
    readOnly: true,
    name: userData?.name,
    surname: userData?.surname,
    username: userData?.username,
    avatar_url: userData?.avatar_url,
    phone: userData?.phone,
    email: userData?.email,
    about: userData?.about,
    gender: userData?.gender,
    is_played_before: userFinishedTournamentsData?.length > 0,
    statistic: {
      tournaments_played: userStatisticData?.tournaments_played || 0,
      games_played: userStatisticData?.games_played || 0,
      first_tournament: userStatisticData?.first_tournament || '',
      most_played_organization: {
        count: userStatisticData?.most_played_organization_count || 0,
        name: userStatisticData?.most_played_organization_name || '',
        has_permanent_location:
          userStatisticData?.most_played_organization_has_permanent_location ||
          false,
      },
    },
  }

  const methods = useForm({
    defaultValues,
  })

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    reset,
    watch,
  } = methods

  const collectChanges = () => {
    const currentValues = {
      name: watch('name'),
      surname: watch('surname'),
      username: watch('username'),
      phone: watch('phone'),
      email: watch('email'),
      about: watch('about'),
      gender: watch('gender'),
    }

    const changes = Object.keys(currentValues).reduce((acc, key) => {
      if (currentValues[key] !== userData[key]) {
        acc[key] = currentValues[key]
      }
      return acc
    }, {})

    return changes
  }

  const [errorMessage, setErrorMessage] = useState('')
  const [openDialog, setOpenDialog] = useState(false)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [remainingTimeData, setRemainingTimeData] = useState({})

  const requiredFieldAreFilled = watch('name')

  const { trigger: updateUserTrigger } = useUpdateUser()
  const { trigger: refreshTokenTrigger } = useRefreshToken()
  const { trigger: cancelSubscriptionTrigger } = useCancelSubscription()
  const { data: remainingTime } = useSubscriptionRemainingTime()

  const { uploadAvatar } = useUploadAvatar()
  const { trigger: resetAvatarTrigger } = useResetAvatar()

  useEffect(() => {
    if (!remainingTime) {
      return
    }

    setRemainingTimeData(remainingTime)
  }, [remainingTime])

  useEffect(() => {
    if (!userStatisticData) {
      return
    }

    setValue('is_played_before', userFinishedTournamentsData?.length > 0)
    setValue('statistic', {
      tournaments_played: userStatisticData.tournaments_played,
      games_played: userStatisticData.games_played,
      first_tournament: userStatisticData.first_tournament,
      most_played_organization: {
        count: userStatisticData.most_played_organization_count,
        name: userStatisticData.most_played_organization_name,
        has_permanent_location:
          userStatisticData.most_played_organization_has_permanent_location,
      },
    })
  }, [userStatisticData, userFinishedTournamentsData])

  useEffect(() => {
    const advancedStatisticRoute = `/api/statistic/user/${userData?.id}/advanced`

    if (isMethodAllowed('GET', advancedStatisticRoute)) {
      loadAdvancedStatistic()
    }
  }, [userData?.id, loadAdvancedStatistic])

  const handleUpdateUserProfile = () => {
    setValue('readOnly', false)
  }

  const handleCancelUpdateUserProfile = () => {
    reset({
      ...defaultValues,
    })
  }

  const handleGoPremium = () => {
    navigate('/plans')
  }

  const handleUpdateUser = () => {
    const changes = collectChanges()

    updateUserTrigger(
      {
        id: userData?.id,
        ...changes,
      },
      {
        onSuccess: () => {
          refreshTokenTrigger({ onError: () => navigate('/login') })
          setValue('readOnly', true)
        },
        onError: (data) => setErrorMessage(data.info.error),
      }
    )

    window.location.reload()
  }

  const handleCancelSubscription = async () => {
    await cancelSubscriptionTrigger(
      {},
      {
        onSuccess: (data) => {
          setRemainingTimeData(data)
        },
        onError: (error) => {
          console.error('Error cancelling subscription:', error)
        },
      }
    )
    setOpenConfirmDialog(false)
    setOpenDialog(false)
  }

  const handleUploadAvatar = async (file) => {
    try {
      const response = await uploadAvatar(file)
      setValue('avatar_url', response.avatar_url)
    } catch (error) {
      setErrorMessage("Couldn't upload avatar, try again later")
    }
  }

  const handleResetAvatar = () => {
    resetAvatarTrigger(
      {},
      {
        onSuccess: () => {
          setValue('avatar_url', null)
        },
        onError: () => {
          setErrorMessage("Couldn't reset avatar, try again later")
        },
      }
    )
  }

  const currentPlan = Object.values(products).find(
    (product) => product.connected_role_id === userRoleId
  )

  return (
    <MainWrapper
      maxWidth="md"
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    >
      <FormProvider {...methods}>
        <FormControl
          component="form"
          onSubmit={handleSubmit(handleUpdateUser)}
          fullWidth
        >
          <Grid mb={5} mt={2} container spacing={2}>
            <Grid mb={2} container spacing={2} item xs={12} alignItems="center">
              <Grid item md sx={{ display: { xs: 'none', md: 'block' } }} />
              <Grid item xs={4} md={2} sx={{ textAlign: 'center' }}>
                {!watch('readOnly') ? (
                  <AvatarUpload
                    gender={userData.gender}
                    currentAvatarURL={watch('avatar_url')}
                    onUpload={handleUploadAvatar}
                    onReset={handleResetAvatar}
                  />
                ) : (
                  <UserAvatar
                    gender={userData.gender}
                    alt={`${userData.name} ${userData.surname}`}
                    src={watch('avatar_url')}
                    sx={{
                      width: 100,
                      height: 100,
                      margin: '0 auto',
                      border: '0.1px solid lightgray',
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={8} md={3}>
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                  {userData.name} {userData.surname}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: 'gray',
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word',
                  }}
                >
                  @{userData.username}
                </Typography>
              </Grid>
              <Grid item md sx={{ display: { xs: 'none', md: 'block' } }} />
            </Grid>

            {!watch('readOnly') && (
              <Grid container spacing={2} item xs={12} md={6}>
                <Grid item xs={12}>
                  <TextField
                    required={!watch('readOnly')}
                    style={{ width: '100%' }}
                    label="Name"
                    error={!!errors.name}
                    helperText={errors.name ? errors.name.message : ''}
                    InputProps={
                      watch('readOnly')
                        ? {
                            readOnly: true,
                          }
                        : {}
                    }
                    {...register('name', { required: 'Name is required' })}
                  />
                </Grid>
                {watch('surname') && (
                  <Grid item xs={12}>
                    <TextField
                      style={{ width: '100%' }}
                      label="Surname"
                      InputProps={
                        watch('readOnly')
                          ? {
                              readOnly: true,
                            }
                          : {}
                      }
                      {...register('surname')}
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <UsernameField initialUsername={userData.username} />
                </Grid>

                <Grid item xs={12}>
                  <GenderField initialGender={userData.gender} />
                </Grid>

                <>
                  <Grid item xs={12}>
                    <TextField
                      style={{ width: '100%' }}
                      label="Phone"
                      {...register('phone')}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      style={{ width: '100%' }}
                      label="Email"
                      {...register('email')}
                    />
                  </Grid>
                </>
              </Grid>
            )}

            {(!watch('readOnly') || watch('about')) && (
              <Grid item xs={12} md={watch('readOnly') ? 12 : 6}>
                <TextField
                  sx={{
                    width: '100%',
                    height: '100%',
                    '& .MuiInputBase-root': {
                      height: '100%',
                      alignItems: 'start',
                    },
                  }}
                  label="About"
                  multiline
                  InputProps={
                    watch('readOnly')
                      ? {
                          readOnly: true,
                        }
                      : {}
                  }
                  {...register('about')}
                />
              </Grid>
            )}

            {!watch('readOnly') && (
              <Grid item xs={12}>
                <Typography variant="h5" sx={{ textAlign: 'center' }}>
                  Current Subscription Plan
                </Typography>
                {currentPlan && (
                  <Grid container spacing={2} mt={1}>
                    <Grid
                      item
                      md={3}
                      sx={{ display: { xs: 'none', md: 'block' } }}
                    />
                    <Grid item xs={12} md={6}>
                      <PlanCard
                        product={currentPlan}
                        duration="monthly"
                        userRoleId={userRoleId}
                        actionButtonLabel="Change Plan"
                        actionButtonColor="primary"
                        actionButtonHandler={() => setOpenDialog(true)}
                        forceActionButton
                        remainingTimeData={remainingTimeData}
                        setRemainingTimeData={setRemainingTimeData}
                        showPrice={false}
                      />
                    </Grid>
                    <Grid
                      item
                      md={3}
                      sx={{ display: { xs: 'none', md: 'block' } }}
                    />
                  </Grid>
                )}
              </Grid>
            )}

            {isMyPage &&
              (watch('readOnly') ? (
                <>
                  <Grid
                    item
                    md={isRoleBasic ? 3 : 4.5}
                    sx={{ display: { xs: 'none', md: 'block' } }}
                  />
                  <Grid item xs={isRoleBasic ? 6 : 12} md={3}>
                    <Button
                      disabled={!requiredFieldAreFilled}
                      style={{ width: '100%' }}
                      onClick={handleUpdateUserProfile}
                      type="button"
                      startIcon={<EditIcon />}
                      variant="contained"
                    >
                      edit
                    </Button>
                  </Grid>
                  {isRoleBasic && (
                    <Grid item xs={6} md={3}>
                      <Button
                        style={{ width: '100%' }}
                        onClick={handleGoPremium}
                        type="button"
                        color="success"
                        startIcon={<UpgradeIcon />}
                        variant="contained"
                      >
                        Go Premium
                      </Button>
                    </Grid>
                  )}
                  <Grid
                    item
                    md={isRoleBasic ? 3 : 4.5}
                    sx={{ display: { xs: 'none', md: 'block' } }}
                  />
                </>
              ) : (
                <>
                  <Grid
                    item
                    md={3}
                    sx={{ display: { xs: 'none', md: 'block' } }}
                  />
                  <Grid item xs={6} md={3}>
                    <Button
                      style={{ width: '100%' }}
                      onClick={handleCancelUpdateUserProfile}
                      type="button"
                      variant="contained"
                    >
                      cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Button
                      disabled={
                        !requiredFieldAreFilled ||
                        !Object.keys(collectChanges()).length ||
                        !!errors.username
                      }
                      style={{ width: '100%' }}
                      onClick={handleUpdateUser}
                      type="submit"
                      variant="contained"
                    >
                      submit
                    </Button>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    sx={{ display: { xs: 'none', md: 'block' } }}
                  />
                </>
              ))}

            {watch('readOnly') && (
              <>
                <PlayerStatistic
                  is_played_before={watch('is_played_before')}
                  basicStatisticData={watch('statistic')}
                  advancedStatisticData={userAdvancedStatisticData}
                />

                <Grid item xs={12}>
                  <Typography variant="h5" sx={{ textAlign: 'center' }}>
                    Finished Tournaments
                  </Typography>
                </Grid>

                {userFinishedTournamentsData?.length > 0 ? (
                  <Grid item xs={12}>
                    {isScreenMdOrMore ? (
                      <TableContainer component={Paper} className={themeClass}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Place</TableCell>
                              <TableCell>Name</TableCell>
                              <TableCell>Organization</TableCell>
                              <TableCell>Type</TableCell>
                              <TableCell>Date</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {userFinishedTournamentsData?.map((tournament) => (
                              <TableRow
                                key={tournament.id}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>
                                  <Grid
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'flex-start',
                                    }}
                                  >
                                    {getUserPlaceInTournament(
                                      tournament.leaderboard,
                                      userData?.id
                                    )}{' '}
                                    of{' '}
                                    {tournament.type.includes('team')
                                      ? tournament.users.length / 2
                                      : tournament.users.length}
                                    {tournament.type.includes('team') ? (
                                      <AiOutlineTeam
                                        style={{
                                          fontSize: '1rem',
                                          marginLeft: '4px',
                                        }}
                                      />
                                    ) : (
                                      <AiOutlineUser
                                        style={{
                                          fontSize: '1rem',
                                          marginLeft: '4px',
                                        }}
                                      />
                                    )}
                                  </Grid>
                                </TableCell>
                                <TableCell>
                                  <Link
                                    className={styles.link}
                                    to={`/tournament/${tournament.id}`}
                                  >
                                    {tournament.name}
                                  </Link>
                                </TableCell>
                                <TableCell>
                                  <Link
                                    className={styles.link}
                                    to={`/organizations/${tournament.organization_id}`}
                                  >
                                    {tournament.organization_name}
                                  </Link>
                                </TableCell>
                                <TableCell>
                                  {capitalizeWords(
                                    tournament.type.replaceAll('_', ' ')
                                  )}
                                </TableCell>
                                <TableCell>
                                  {dayjs(tournament.start_time).format(
                                    'DD.MM.YYYY'
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      userFinishedTournamentsData?.map((tournament) => (
                        <Grid item xs={12} md={4} mb={1} key={tournament.id}>
                          <UserFinishedTournamentBlock
                            tournament={tournament}
                            user_id={userData?.id}
                            themeMode={themeMode}
                          />
                        </Grid>
                      ))
                    )}
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={12}
                    sx={{ textAlign: 'center', color: 'grey' }}
                  >
                    <Typography variant="body1">
                      No finished tournaments
                    </Typography>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </FormControl>
      </FormProvider>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Change Plan</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            Do you want to change your plan? You can go to the{' '}
            <Button
              component={Link}
              to="/plans"
              sx={{
                textTransform: 'none',
                padding: 0,
                minWidth: 'auto',
                color: 'primary.main',
              }}
            >
              plans page
            </Button>{' '}
            or cancel your current subscription.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenConfirmDialog(true)}
            type="button"
            color="error"
            variant="contained"
          >
            Cancel Subscription
          </Button>
          <Button
            onClick={() => setOpenDialog(false)}
            type="button"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <DialogTitle>
          Are you sure you want to cancel your subscription?
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            Subscription will be canceled after the paid period ends. It can be
            renewed during this time.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancelSubscription}
            type="button"
            color="error"
            variant="contained"
          >
            Confirm Cancellation
          </Button>
          <Button
            onClick={() => setOpenConfirmDialog(false)}
            type="button"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </MainWrapper>
  )
}

export function PlayerStatistic({
  is_played_before,
  basicStatisticData,
  advancedStatisticData = null,
}) {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ textAlign: 'center' }}>
          Player Statistic
        </Typography>
      </Grid>

      <Grid item xs={6} md={3}>
        <TextField
          style={{ width: '100%' }}
          label="Tournaments Played"
          value={is_played_before ? basicStatisticData.tournaments_played : 0}
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField
          style={{ width: '100%' }}
          label="Games Played"
          value={is_played_before ? basicStatisticData.games_played : 0}
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        {is_played_before ? (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDateTimePicker
              sx={{ width: '100%' }}
              label="First Tournament"
              value={
                basicStatisticData.first_tournament
                  ? dayjs(basicStatisticData.first_tournament)
                  : null
              }
              format="DD.MM.YYYY"
              readOnly
            />
          </LocalizationProvider>
        ) : (
          <TextField
            style={{ width: '100%' }}
            label="First Tournament"
            value="-"
            InputProps={{
              readOnly: true,
            }}
          />
        )}
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField
          style={{ width: '100%' }}
          label={`Most Played ${
            basicStatisticData.most_played_organization?.has_permanent_location
              ? 'Club'
              : 'Community'
          } (${basicStatisticData.most_played_organization?.count})`}
          value={
            is_played_before
              ? `${basicStatisticData.most_played_organization?.name}`
              : '-'
          }
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>

      {advancedStatisticData && (
        <>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ textAlign: 'center', marginTop: 2 }}>
              Advanced Statistics
            </Typography>
          </Grid>

          <Grid item xs={3} md={4.5} />
          <Grid item xs={6} md={3}>
            <TextField
              style={{ width: '100%' }}
              label="Medals"
              value={`${advancedStatisticData.medals_won.gold}🥇 ${advancedStatisticData.medals_won.silver}🥈 ${advancedStatisticData.medals_won.bronze}🥉`}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={3} md={4.5} />

          {advancedStatisticData.americano_single && (
            <>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  sx={{ textAlign: 'center', marginTop: 2 }}
                >
                  Americano Single
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  style={{ width: '100%' }}
                  label="Medals"
                  value={`${advancedStatisticData.americano_single.medals_won.gold}🥇 ${advancedStatisticData.americano_single.medals_won.silver}🥈 ${advancedStatisticData.americano_single.medals_won.bronze}🥉`}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  style={{ width: '100%' }}
                  label="Points Of Max Possible"
                  value={`${(
                    advancedStatisticData.americano_single
                      .percentage_of_points_taken * 100
                  ).toFixed(2)}%`}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  style={{ width: '100%' }}
                  label="Game Win Rate"
                  value={`${(
                    advancedStatisticData.americano_single.game_win_rate * 100
                  ).toFixed(2)}%`}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  style={{ width: '100%' }}
                  label="Total Points Scored"
                  value={
                    advancedStatisticData.americano_single.total_points_scored
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </>
          )}
          {advancedStatisticData.americano_team && (
            <>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  sx={{ textAlign: 'center', marginTop: 2 }}
                >
                  Americano Team
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  style={{ width: '100%' }}
                  label="Medals"
                  value={`${advancedStatisticData.americano_team.medals_won.gold}🥇 ${advancedStatisticData.americano_team.medals_won.silver}🥈 ${advancedStatisticData.americano_team.medals_won.bronze}🥉`}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  style={{ width: '100%' }}
                  label="Points Of Max Possible"
                  value={`${(
                    advancedStatisticData.americano_team
                      .percentage_of_points_taken * 100
                  ).toFixed(2)}%`}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  style={{ width: '100%' }}
                  label="Game Win Rate"
                  value={`${(
                    advancedStatisticData.americano_team.game_win_rate * 100
                  ).toFixed(2)}%`}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  style={{ width: '100%' }}
                  label="Total Points Scored"
                  value={
                    advancedStatisticData.americano_team.total_points_scored
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </>
          )}
          {advancedStatisticData.round_robin && (
            <>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  sx={{ textAlign: 'center', marginTop: 2 }}
                >
                  Round Robin
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  style={{ width: '100%' }}
                  label="Medals"
                  value={`${advancedStatisticData.round_robin.medals_won.gold}🥇 ${advancedStatisticData.round_robin.medals_won.silver}🥈 ${advancedStatisticData.round_robin.medals_won.bronze}🥉`}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  style={{ width: '100%' }}
                  label="Game Win Rate"
                  value={`${(
                    advancedStatisticData.round_robin.game_win_rate * 100
                  ).toFixed(2)}%`}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  style={{ width: '100%' }}
                  label="Total Matches Played"
                  value={advancedStatisticData.round_robin.total_matches_played}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </>
          )}
        </>
      )}
    </>
  )
}
