import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Grid, Pagination, Typography, TextField } from '@mui/material'
import { useLocations, useSearchLocations } from 'hooks/api/locationHooks'

import { useCurrentUser } from 'hooks/cookies'
import { MainWrapper } from 'components/layout/MainWrapper/MainWrapper'
import { LocationBlock } from 'components/LocationBlock/LocationBlock'
import { useThemeSwitcher } from 'components/ThemeProvider/ThemeProvider'

export function Locations({ isOwner }) {
  const { themeMode } = useThemeSwitcher()

  const limit = 6
  const [currentPage, setCurrentPage] = useState(1)
  const [currentSearchPage, setCurrentSearchPage] = useState(1)

  const [searchList, setSearchList] = useState([])
  const [searchLocationName, setSearchLocationName] = useState('')
  const [showSearchResults, setShowSearchResults] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [total, setTotal] = useState(0)
  const [searchTotal, setSearchTotal] = useState(0)

  const offset = (currentPage - 1) * limit
  const searchOffset = (currentSearchPage - 1) * limit

  const count = Math.ceil(total / limit)
  const searchCount = Math.ceil(searchTotal / limit)

  const [user] = useCurrentUser()
  const navigate = useNavigate()

  const { trigger: searchLocationTrigger } = useSearchLocations({
    searchLocationName,
    limit,
    offset: searchOffset,
  })

  const { data } = useLocations({
    isOwner,
    adminId: user?.id,
    limit,
    offset,
    is_deleted: false,
  })

  useEffect(() => {
    if (data?.total) {
      setTotal(data.total)
    }
  }, [data])

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      if (searchLocationName.length > 2) {
        searchLocationTrigger(
          {},
          {
            onSuccess: (locationsData) => {
              setSearchList(locationsData)
              setSearchTotal(locationsData.total)
              setShowSearchResults(true)
            },
            onError: (data) => setErrorMessage(data.info.error),
          }
        )
      } else {
        setSearchList([])
        setShowSearchResults(false)
      }
    }, 200)

    return () => clearTimeout(searchTimeout)
  }, [searchLocationName, searchLocationTrigger, currentSearchPage])

  const handleClick = () => {
    navigate('/create-location')
  }

  const handlePageChange = (_, page) => {
    setCurrentPage(page)
  }

  const handleSearchPageChange = (_, page) => {
    setCurrentSearchPage(page)
  }

  return (
    <MainWrapper maxWidth="md" errorMessage={errorMessage}>
      <Grid mb={4} container spacing={2}>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography variant="h5">Locations</Typography>
        </Grid>
        {user?.id && (
          <>
            <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }} />
            <Grid item xs={12} md={4}>
              <Button
                style={{ width: '100%' }}
                onClick={() => handleClick()}
                type="submit"
                variant='contained'
              >
                Create Location
              </Button>
            </Grid>
            <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }} />
          </>
        )}

        <Grid item xs={12}>
          <TextField
            id="outlined-search"
            label="Search Locations"
            type="search"
            variant="outlined"
            value={searchLocationName}
            onChange={(e) => setSearchLocationName(e.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item container spacing={2} xs={12}>
          {showSearchResults ? (
            <Grid item xs={12}>
              <LocationList
                locations={searchList.data}
                page={currentSearchPage}
                count={searchCount}
                onChange={handleSearchPageChange}
                themeMode={themeMode}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              {data?.data.length > 0 ? (
                <LocationList
                  locations={data.data}
                  page={currentPage}
                  count={count}
                  onChange={handlePageChange}
                  themeMode={themeMode}
                />
              ) : (
                <Grid item xs={12} sx={{ textAlign: 'center', color: 'grey' }}>
                  <Typography variant="body1">
                    No locations available
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </MainWrapper>
  )
}

function LocationList({ locations, count, page, onChange, themeMode }) {
  return locations.length > 0 ? (
    <Grid container spacing={2}>
      {locations.map((location) => (
        <Grid item xs={12} md={4} key={location.id} sx={{ display: 'flex' }}>
          <LocationBlock location={location} themeMode={themeMode} />
        </Grid>
      ))}

      <Grid item xs={12}>
        <Pagination
          count={count}
          page={page}
          shape="rounded"
          onChange={onChange}
          siblingCount={0}
          size="medium"
        />
      </Grid>
    </Grid>
  ) : (
    <Grid item xs={12} sx={{ textAlign: 'center', color: 'grey' }}>
      <Typography variant="body1">No locations found</Typography>
    </Grid>
  )
}
