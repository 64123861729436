import React from 'react'
import { useParams } from 'react-router-dom'

import { CircularProgress, Box } from '@mui/material'

import { useLocation } from 'hooks/api/locationHooks'

import { LocationBase } from './LocationBase'
import { NotFound } from '../NotFound/NotFound'

export function Location() {
  const { locationId } = useParams()
  const { data: locationData, isValidating: isLocationDataValidating } =
    useLocation(locationId)

  if (!locationData || isLocationDataValidating) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (!locationData || !locationData.id) {
    return <NotFound message="Location not found" />
  }

  return <LocationBase locationData={locationData} />
}
