import React from 'react'
import { Navigate } from 'react-router-dom'

import { useCurrentUser } from 'hooks/cookies'
import { useUserById } from 'hooks/api/userHooks'
import { useTournaments } from 'hooks/api/tournamentHooks'

import { MainWrapper } from 'components/layout/MainWrapper/MainWrapper'
import { UserBase } from './UserBase'

export function Profile() {
  const [user] = useCurrentUser()

  // TODO: React does not like conditional hooks call. Need to find a way not to trigger api and redirect instantly
  const { data: userData, isLoading: userDataIsLoading } = useUserById(
    user?.id || '0'
  )
  const { data: userFinishedTournamentsData } = useTournaments({
    user_id: user?.id || '0',
    status: 'finished',
    order: 'desc',
    limit: 100,
  })

  if (userDataIsLoading) {
    return <MainWrapper />
  }

  if (!userData) {
    return <Navigate to="/" />
  }

  return (
    <UserBase
      userData={userData}
      userFinishedTournamentsData={userFinishedTournamentsData?.data}
    />
  )
}
