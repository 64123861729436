import React from 'react'
import { useParams } from 'react-router-dom'

import { CircularProgress, Box } from '@mui/material'

import {
  useOrganization,
  useOrganizationStatistic,
} from 'hooks/api/organizationHooks'

import { NotFound } from '../NotFound/NotFound'
import { OrganizationBase } from './OrganizationBase'

export function Organization() {
  const { organizationId } = useParams()
  const { data: organizationData, isValidating: isOrganizationDataValidating } =
    useOrganization(organizationId)
  const {
    data: organizationStatisticData,
    isValidating: isOrganizationStatisticDataValidating,
  } = useOrganizationStatistic(organizationId)

  if (
    !organizationData ||
    !organizationStatisticData ||
    isOrganizationDataValidating ||
    isOrganizationStatisticDataValidating
  )
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    )

  if (!organizationData || !organizationData.id) {
    return <NotFound message="Organization not found" />
  }

  return (
    <OrganizationBase
      organizationData={organizationData}
      organizationStatisticData={organizationStatisticData}
    />
  )
}
