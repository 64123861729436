import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import { getFetcher, postFetcher, putFetcher, deleteFetcher } from './fetchers'

export function useLocations({
  isOwner = false,
  adminId = null,
  limit = 10,
  offset = 0,
  is_deleted = false,
}) {
  const params = { limit, offset, is_deleted }
  if (isOwner && adminId) {
    params.location_admin_id = adminId
  }

  const searchParams = new URLSearchParams(params)
  return useSWR(`/location?${searchParams}`, getFetcher)
}

export function useSearchLocations({searchLocationName = null, limit = 10, offset = 0, is_deleted = false}) {
  const params = { limit, offset, is_deleted };
  if (searchLocationName) {
    params.search_string = searchLocationName;
  }

  const searchParams = new URLSearchParams(params);
  return useSWRMutation(`/location?${searchParams}`, getFetcher)
}

export function useLocation(locationId) {
  return useSWR(locationId ? `/location/${locationId}` : null, getFetcher)
}

export function useLocationStatistic(locationId) {
  return useSWR(`/location/${locationId}/statistic`, getFetcher)
}

export function useCreateLocation() {
  return useSWRMutation('/location', postFetcher)
}

export function useUpdateLocation() {
  return useSWRMutation('/location/', putFetcher)
}

export function useCreateLocationAddress() {
  return useSWRMutation('/location/address', postFetcher)
}

export function useUpdateLocationAddress() {
  return useSWRMutation('/location/address', putFetcher)
}

export function useDeleteLocationAddress() {
  return useSWRMutation('/location/address', deleteFetcher)
}

export function usePseudoDeleteLocationAddress() {
  return useSWRMutation('/location/address/pseudo_delete', deleteFetcher)
}
