import React from 'react'
import { Link } from 'react-router-dom'

import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

import { UserAvatarWithName } from 'components/Avatar/UserAvatar'

import styles from 'styles/main.module.scss'

export function AmericanoSingleLeaderboardTable({
  leaderboard,
  viewMode,
  themeMode,
}) {
  const themeClass = themeMode === 'dark' ? styles.darkTheme : styles.lightTheme

  let place = 1

  return (
    <TableContainer component={Paper} className={themeClass}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">🥇</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="center">Points</TableCell>
            <TableCell align="center">W</TableCell>
            <TableCell align="center">D</TableCell>
            <TableCell align="center">L</TableCell>
          </TableRow>
        </TableHead>
        {leaderboard && (
          <TableBody>
            {leaderboard.map((player, index) => {
              const previousPlayer = leaderboard[index - 1]
              const currentPlayer = player

              if (
                previousPlayer &&
                previousPlayer.americano_data.points !==
                  currentPlayer.americano_data.points
              ) {
                place = index + 1
              }

              return (
                <TableRow key={player.user_id}>
                  <TableCell align="center">{place}</TableCell>
                  <TableCell align="left">
                    {viewMode ? (
                      <Grid>
                        <UserAvatarWithName key={player.user_id} user={player} />
                      </Grid>
                    ) : (
                      <Link
                        className={styles.reverseLink}
                        to={`/users/${player.user_id}`}
                      >
                        <UserAvatarWithName key={player.user_id} user={player} />
                      </Link>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {player.americano_data.points}
                  </TableCell>
                  <TableCell align="center">
                    {player.americano_data.wins}
                  </TableCell>
                  <TableCell align="center">
                    {player.americano_data.draws}
                  </TableCell>
                  <TableCell align="center">
                    {player.americano_data.loses}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  )
}

export function AmericanoTeamLeaderboardTable({
  leaderboard,
  viewMode,
  themeMode,
}) {
  const themeClass = themeMode === 'dark' ? styles.darkTheme : styles.lightTheme

  let place = 1

  return (
    <TableContainer component={Paper} className={themeClass}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">🥇</TableCell>
            <TableCell align="left">Team Members</TableCell>
            <TableCell align="center">Points</TableCell>
            <TableCell align="center">W</TableCell>
            <TableCell align="center">D</TableCell>
            <TableCell align="center">L</TableCell>
          </TableRow>
        </TableHead>
        {leaderboard && (
          <TableBody>
            {leaderboard.map((leaderboardElement, teamIndex) => {
              const previousTeam = leaderboard[teamIndex - 1]?.team[0]
              const currentTeam = leaderboardElement.team[0]

              if (
                previousTeam &&
                previousTeam.americano_data.points !==
                  currentTeam.americano_data.points
              ) {
                place = teamIndex + 1
              }

              return (
                <TableRow key={teamIndex}>
                  <TableCell align="center">{place}</TableCell>
                  <TableCell align="left">
                    {viewMode ? (
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        {leaderboardElement.team.map((member) => (
                          <UserAvatarWithName key={member.user_id} user={member} />
                        ))}
                      </Box>
                    ) : (
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        {leaderboardElement.team.map((member) => (
                          <Link
                            to={`/users/${member.user_id}`}
                            key={member.user_id}
                            className={styles.reverseLink}
                          >
                            <UserAvatarWithName user={member} />
                          </Link>
                        ))}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {currentTeam.americano_data.points}
                  </TableCell>
                  <TableCell align="center">
                    {currentTeam.americano_data.wins}
                  </TableCell>
                  <TableCell align="center">
                    {currentTeam.americano_data.draws}
                  </TableCell>
                  <TableCell align="center">
                    {currentTeam.americano_data.loses}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  )
}

export function RoundRobinLeaderboardTable({
  leaderboard,
  viewMode,
  themeMode,
}) {
  const themeClass = themeMode === 'dark' ? styles.darkTheme : styles.lightTheme

  let place = 1

  return (
    <TableContainer component={Paper} className={themeClass}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">🥇</TableCell>
            <TableCell align="left">Team Members</TableCell>
            <TableCell align="center">W</TableCell>
            <TableCell align="center">D</TableCell>
            <TableCell align="center">L</TableCell>
            <TableCell align="center">Diff</TableCell>
          </TableRow>
        </TableHead>
        {leaderboard && (
          <TableBody>
            {leaderboard.map((leaderboardElement, teamIndex) => {
              const previousTeam = leaderboard[teamIndex - 1]?.team[0]
              const currentTeam = leaderboardElement.team[0]

              if (
                previousTeam &&
                (previousTeam.round_robin_data.wins !==
                  currentTeam.round_robin_data.wins ||
                  previousTeam.round_robin_data.games_difference !==
                    currentTeam.round_robin_data.games_difference)
              ) {
                place = teamIndex + 1
              }

              return (
                <TableRow key={teamIndex}>
                  <TableCell align="center">{place}</TableCell>
                  <TableCell align="left">
                    {viewMode ? (
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        {leaderboardElement.team.map((member) => (
                          <UserAvatarWithName key={member.user_id} user={member} />
                        ))}
                      </Box>
                    ) : (
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        {leaderboardElement.team.map((member) => (
                          <Link
                            to={`/users/${member.user_id}`}
                            key={member.user_id}
                            className={styles.reverseLink}
                          >
                            <UserAvatarWithName user={member} />
                          </Link>
                        ))}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {leaderboardElement.team[0].round_robin_data.wins}
                  </TableCell>
                  <TableCell align="center">
                    {leaderboardElement.team[0].round_robin_data.draws}
                  </TableCell>
                  <TableCell align="center">
                    {leaderboardElement.team[0].round_robin_data.loses}
                  </TableCell>
                  <TableCell align="center">
                    {
                      leaderboardElement.team[0].round_robin_data
                        .games_difference
                    }
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  )
}
