import React, { createContext, useContext, useState, useMemo, useEffect } from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

const ThemeSwitcherContext = createContext();

export const useThemeSwitcher = () => useContext(ThemeSwitcherContext);

function ThemeProvider({ children }) {
  const [themeMode, setThemeMode] = useState(localStorage.getItem('themeMode') || 'light');

  useEffect(() => {
    localStorage.setItem('themeMode', themeMode);
  }, [themeMode]);

  const theme = createTheme({
    palette: {
      mode: themeMode,
    },
  });

  const toggleTheme = () => {
    setThemeMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  const contextValue = useMemo(() => ({
    themeMode,
    toggleTheme,
  }), [themeMode, toggleTheme]);

  return (
    <ThemeSwitcherContext.Provider value={contextValue}>
      <MuiThemeProvider theme={theme}>
        {children}
      </MuiThemeProvider>
    </ThemeSwitcherContext.Provider>
  );
}

export default ThemeProvider;
