import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'

import styled from '@emotion/styled'

import {
  AppBar,
  Box,
  Button,
  CardMedia,
  Container,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  Toolbar,
  Typography,
} from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu'

import { useCurrentUser } from 'hooks/cookies'
import { useLogout } from 'hooks/api/authHooks'
import { isAdmin } from 'utils'

import { useThemeSwitcher } from 'components/ThemeProvider/ThemeProvider'

import styles from './Header.module.scss'

const menuItemsDefault = [
  { to: '/organizations', name: 'Organizations' },
  { to: '/tournaments', name: 'Tournaments' },
]

const menuItemsOrganizationAdmin = [
  { to: '/organizations', name: 'Organizations' },
  { to: '/tournaments', name: 'Tournaments' },
  { to: '/create-tournament', name: 'Create Tournament' },
]

const menuItemsAdmin = [
  { to: '/locations', name: 'Locations' },
  { to: '/organizations', name: 'Organizations' },
  { to: '/tournaments', name: 'Tournaments' },
  { to: '/users', name: 'Users' },
  { to: '/create-tournament', name: 'Create Tournament' },
]

const StyledButton = styled(Button)({
  my: 2,
  color: 'white',
})

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff'
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#FFD580',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff'
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}))

export function Header() {
  const [user] = useCurrentUser()
  const [, , clearCookies] = useCookies(['user'])

  const [anchorElNav, setAnchorElNav] = useState(null)
  const [anchorElUser, setAnchorElUser] = useState(null)

  const navigate = useNavigate()
  const { trigger } = useLogout()

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleOpenUserMenu = (e) => {
    setAnchorElUser(e.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleLogout = () => {
    trigger({
      onSuccess: () => {
        navigate('/')
      },
      onError: (data) => {
        console.log(data)
      },
    })

    clearCookies('user', {
      path: '/',
    })

    localStorage.removeItem('allowedPolicies')
    localStorage.removeItem('discardedPolicies')
  }

  const { themeMode, toggleTheme } = useThemeSwitcher()

  const handleThemeChange = (event) => {
    toggleTheme(event.target.checked ? 'dark' : 'light')
  }

  const admin = isAdmin(user)
  const organizationAdmin = user?.administered_organizations?.data?.length >= 1

  const hasOrganization = organizationAdmin && user?.administered_organizations?.data?.length === 1
  const hasMoreThanOneOrganization = organizationAdmin && user?.administered_organizations?.data?.length > 1

  let menuItems
  if (admin) {
    menuItems = menuItemsAdmin
  } else if (organizationAdmin) {
    menuItems = menuItemsOrganizationAdmin
  } else {
    menuItems = menuItemsDefault
  }

  return (
    <AppBar
      position="static"
      sx={{
        bgcolor: themeMode === 'dark' ? '' : '#383838',
      }}
    >
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Box
            sx={{
              flexGrow: { xs: 200, md: 0 },
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Link
              to="/"
              className={styles.logo}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Grid item>
                <CardMedia
                  component="img"
                  image={
                    themeMode === 'dark'
                      ? '/logo_dark_theme.png'
                      : '/logo_dark_theme.png'
                  }
                  alt="logo"
                  sx={{ maxWidth: '300px', height: 'auto' }}
                />
              </Grid>
            </Link>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              anchorEl={anchorElNav}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {menuItems.map(({ to, name }) => (
                <MenuItem
                  key={name}
                  component={Link}
                  to={to}
                  onClick={handleCloseNavMenu}
                >
                  {name}
                </MenuItem>
              ))}
              <Divider />
              {user ? (
                [
                  <MenuItem
                    key="Profile"
                    component={Link}
                    to="/profile"
                    onClick={handleCloseUserMenu}
                  >
                    Profile
                  </MenuItem>,
                  hasOrganization && (
                    <MenuItem
                      key="MyOrganization"
                      component={Link}
                      to={`/organizations/${user?.administered_organizations?.data[0].id}`}
                      onClick={handleCloseUserMenu}
                    >
                      My Organization
                    </MenuItem>
                  ),
                  hasMoreThanOneOrganization && (
                    <MenuItem
                      key="MyOrganizations"
                      component={Link}
                      to="/profile/organizations"
                      onClick={handleCloseUserMenu}
                    >
                      My Organizations
                    </MenuItem>
                  ),
                  <MenuItem key="Logout" onClick={handleLogout}>
                    Logout
                  </MenuItem>,
                  <MenuItem key="Theme switch" onClick={handleCloseUserMenu}>
                    <MaterialUISwitch
                      checked={themeMode === 'dark'}
                      onChange={handleThemeChange}
                      name="themeSwitch"
                    />
                  </MenuItem>,
                ]
              ) : (
                <MenuItem
                  key="Login"
                  component={Link}
                  to="/login"
                  onClick={handleCloseUserMenu}
                >
                  Login
                </MenuItem>
              )}
              {user ? null : (
                <MenuItem key="Theme switch" onClick={handleCloseUserMenu}>
                  <MaterialUISwitch
                    checked={themeMode === 'dark'}
                    onChange={handleThemeChange}
                    name="themeSwitch"
                  />
                </MenuItem>
              )}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {menuItems.map(({ to, name }) => (
              <Link key={name} to={to}>
                <StyledButton>{name}</StyledButton>
              </Link>
            ))}
          </Box>

          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {user ? (
              <>
                <StyledButton
                  aria-label="account of current user"
                  aria-controls="menu-user"
                  aria-haspopup="true"
                  endIcon={<MenuIcon />}
                  onClick={handleOpenUserMenu}
                >
                  <Typography textTransform="none">
                    {user.name} {user.surname}
                  </Typography>
                </StyledButton>
                <Menu
                  id="menu-user"
                  anchorEl={anchorElUser}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem
                    component={Link}
                    to="/profile"
                    onClick={handleCloseUserMenu}
                  >
                    Profile
                  </MenuItem>
                  {hasOrganization && (
                    <MenuItem
                      component={Link}
                      to={`/organizations/${user?.administered_organizations?.data[0].id}`}
                      onClick={handleCloseUserMenu}
                    >
                      My Organization
                    </MenuItem>
                  )}
                  {hasMoreThanOneOrganization && (
                    <MenuItem
                      component={Link}
                      to="/profile/organizations"
                      onClick={handleCloseUserMenu}
                    >
                      My Organizations
                    </MenuItem>
                  )}
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  <MenuItem key="Theme switch">
                    <MaterialUISwitch
                      checked={themeMode === 'dark'}
                      onChange={handleThemeChange}
                      name="themeSwitch"
                    />
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <MaterialUISwitch
                  checked={themeMode === 'dark'}
                  onChange={handleThemeChange}
                  name="themeSwitch"
                />
                <Link to="/login">
                  <StyledButton>Login</StyledButton>
                </Link>
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
