export default {
  player_basic: {
    name: 'Player Free',
    connected_role_id: 1,
    monthly_price: {
      price: 0,
    },
    yearly_price: {
      price: 0,
    },
    image:
      '/player_free_plan.jpg',
    description: `* Participate in all types of tournaments
* Store game history
* Basic statistic`,
  },

  player_premium: {
    name: 'Player Pro',
    connected_role_id: 5,
    monthly_price: {
      price_id: process.env.REACT_APP_STRIPE_MONTHLY_PRICE_ID_1,
      price: 1599,
    },
    yearly_price: {
      price_id: process.env.REACT_APP_STRIPE_YEARLY_PRICE_ID_1,
      price: 15999,
    },
    image:
      '/player_pro_plan.jpg',
    description: `All from the Free plan, plus:
* Join tournaments without service fee (later)
* Advanced statistic`,
  },

  administrator_basic: {
    name: 'Organizer Basic',
    connected_role_id: 3,
    monthly_price: {
      price: 0,
    },
    yearly_price: {
      price: 0,
    },
    image:
      '/org_free_plan.jpeg',
    description: `* Own a community or club
* Basic statistic
* Create and manage type of tournaments:
  - Americano Singles
  - Americano Doubles
  - Round Robin`,
  },

  administrator_advanced: {
    name: 'Organizer Pro',
    connected_role_id: 4,
    monthly_price: {
      price_id: process.env.REACT_APP_STRIPE_MONTHLY_PRICE_ID_2,
      price: 4999,
    },
    yearly_price: {
      price_id: process.env.REACT_APP_STRIPE_YEARLY_PRICE_ID_2,
      price: 49999,
    },
    image:
      '/org_pro_plan.jpeg',
    description:
      `All from the Free plan, plus:
* Create and manage type of tournaments:
  - Mixed Doubles (later)
  - Mixed Americano (later)
  - Mexicano (later)
  - King Of The Court Doubles (later)
  - Knockout tournaments (later)
* Advanced statistic
      `,
  },
}
