import React, { useState, useEffect } from 'react'
import { Alert, Collapse, Container, Grid } from '@mui/material'

import { Header } from '../Header/Header'

export function MainWrapper({
  maxWidth = 'lg',
  errorMessage,
  setErrorMessage,
  children,
  scale = 1,
}) {
  const [isAlertOpen, setIsAlertOpen] = useState(Boolean(errorMessage))

  const handleCloseAlert = () => {
    setErrorMessage('')
    setIsAlertOpen(false)
  }

  useEffect(() => {
    setIsAlertOpen(Boolean(errorMessage))
  }, [errorMessage])

  useEffect(() => {
    const defaultFontSize = 16 // usually browsers default to 16px
    document.documentElement.style.fontSize = `${defaultFontSize * scale}px`
  }, [scale])

  return (
    <>
      <Header />
      <Container fixed maxWidth={maxWidth}>
        <Collapse
          in={isAlertOpen}
          onExited={handleCloseAlert}
          sx={{
            marginTop: {
              xs: 2,
              md: 4,
            },
            marginBottom: {
              xs: 0,
              md: 2,
            },
          }}
        >
          <Alert severity="error" onClose={handleCloseAlert}>
            Error: {errorMessage}
          </Alert>
        </Collapse>
        {children}
      </Container>
    </>
  )
}

export function MainWrapperViewMode({
  errorMessage = '',
  setErrorMessage,
  children,
  scale = 1,
}) {
  const [isAlertOpen, setIsAlertOpen] = useState(Boolean(errorMessage))

  const handleCloseAlert = () => {
    setErrorMessage('')
    setIsAlertOpen(false)
  }

  useEffect(() => {
    setIsAlertOpen(Boolean(errorMessage))
  }, [errorMessage])

  useEffect(() => {
    const defaultFontSize = 16 // usually browsers default to 16px
    document.documentElement.style.fontSize = `${defaultFontSize * scale}px`
  }, [scale])

  return (
    <Grid>
      <Collapse
        in={isAlertOpen}
        onExited={handleCloseAlert}
        sx={{ margin: '1em' }}
      >
        <Alert
          severity="error"
          onClose={handleCloseAlert}
          sx={{ fontSize: '1.5em' }}
        >
          Error: {errorMessage}
        </Alert>
      </Collapse>
      {children}
    </Grid>
  )
}
