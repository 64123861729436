import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from '@mui/material'
import {
  useSetOrUpdateTournamentMatch,
  useResetTournamentMatch,
} from 'hooks/api/tournamentHooks'

import { UserAvatarWithName } from 'components/Avatar/UserAvatar'
import { ScoreDisplay } from 'components/ScoreDisplay/ScoreDisplay'

import styles from 'styles/main.module.scss'

export function RoundCourt({
  isAdmin,
  roundNumber,
  courtNumber,
  tournamentData,
  mutateData,
  scoreType = 'points',
  setErrorMessage,
  themeMode,
}) {
  const themeClass = themeMode === 'dark' ? styles.darkTheme : styles.lightTheme
  const maxGamesPoints = 9

  const { trigger: setOrUpdateTournamentMatchTrigger } =
    useSetOrUpdateTournamentMatch()
  const { trigger: resetTournamentMatchTrigger } = useResetTournamentMatch()

  const court = tournamentData.schedule.rounds[roundNumber].courts[courtNumber]
  const courtName = tournamentData.courts[courtNumber].name.includes('Court')
    ? tournamentData.courts[courtNumber].name
    : `Court ${tournamentData.courts[courtNumber].name}`

  const cycleNumber = Math.floor(
    roundNumber /
      (tournamentData.schedule.rounds.length / tournamentData.cycle_count)
  )

  const [team1Score, setTeam1Score] = useState(court.result.team1_score || 0)
  const [team2Score, setTeam2Score] = useState(court.result.team2_score || 0)

  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedTeam, setSelectedTeam] = useState(null)
  const [scoreSelected, setScoreSelected] = useState(false)

  const { americano_max_score } = tournamentData

  useEffect(() => {
    setTeam1Score(court.result.team1_score || 0)
    setTeam2Score(court.result.team2_score || 0)
  }, [
    tournamentData,
    roundNumber,
    court.result.team1_score,
    court.result.team2_score,
  ])

  useEffect(() => {
    if (!dialogOpen && scoreSelected) {
      setOrUpdateTournamentMatchTrigger(
        {
          tournament_id: tournamentData.id,
          cycle_number: cycleNumber,
          court: {
            ...court,
            result: {
              team1_score: team1Score,
              team2_score: team2Score,
            },
          },
        },
        {
          onSuccess: () => {
            mutateData()
          },
          onError: (data) => {
            setErrorMessage(data)
          },
        }
      )
      setSelectedTeam(null)
      setScoreSelected(false)
    }
  }, [dialogOpen, scoreSelected])

  const handleAddCourtResult = (team, value) => {
    if (value === '') {
      if (scoreType === 'games') {
        if (team) {
          setTeam2Score('')
        } else {
          setTeam1Score('')
        }
      } else {
        setTeam1Score('')
        setTeam2Score('')
      }

      return
    }

    const formattedValue = Number(value)

    if (
      (scoreType === 'points' &&
        formattedValue !== '' &&
        formattedValue > americano_max_score) ||
      formattedValue < 0
    ) {
      return
    }

    if (scoreType === 'points' && !team) {
      setTeam1Score(formattedValue)
      setTeam2Score(americano_max_score - formattedValue)
    } else if (scoreType === 'points' && team) {
      setTeam2Score(formattedValue)
      setTeam1Score(americano_max_score - formattedValue)
    } else if (!team) {
      setTeam1Score(formattedValue)
    } else {
      setTeam2Score(formattedValue)
    }
  }

  const handleDialogOpen = (team) => {
    setSelectedTeam(team)
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  const handleScoreSelect = (score) => {
    handleAddCourtResult(selectedTeam, score)
    setScoreSelected(true)
    handleDialogClose()
  }

  const handleReset = () => {
    setTeam1Score(0)
    setTeam2Score(0)
    resetTournamentMatchTrigger(
      {
        tournament_id: tournamentData.id,
        cycle_number: cycleNumber,
        match_id: court.match_id,
      },
      {
        onError: (data) => {
          setErrorMessage(data)
        },
      }
    )
    handleDialogClose()
  }

  return (
    <Grid item xs={12} md={6}>
      <Paper
        sx={{
          padding: '16px',
          width: '100%',
          maxWidth: '800px',
          backgroundColor: '#f5f5f5',
          borderRadius: '12px',
        }}
        className={themeClass}
      >
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={12} sx={{ textAlign: 'left' }}>
            <Typography variant="h6">{courtName}</Typography>
          </Grid>

          {isAdmin ? (
            <Grid container mt={2}>
              <Grid
                item
                xs={6}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => handleDialogOpen(false)}
                  sx={{
                    minHeight: '50px',
                    minWidth: '50px',
                    margin: '0 0 0 16px',
                    padding: '4px 8px',
                    borderColor: 'primary.main',
                    color: 'primary.main',
                    '&:hover': {
                      backgroundColor: '#e3f2fd',
                    },
                    fontSize: '1.4rem',
                  }}
                >
                  {team1Score}
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => handleDialogOpen(true)}
                  sx={{
                    minHeight: '50px',
                    minWidth: '50px',
                    margin: '0 0 0 16px',
                    padding: '4px 8px',
                    borderColor: 'primary.main',
                    color: 'primary.main',
                    '&:hover': {
                      backgroundColor: '#e3f2fd',
                    },
                    fontSize: '1.4rem',
                  }}
                >
                  {team2Score}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' } }} />
              <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
                <ScoreDisplay team1Score={team1Score} team2Score={team2Score} />
              </Grid>
              <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' } }} />
            </>
          )}

          <Grid
            item
            xs={6}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid item>
              <UserAvatarWithName
                key={court.teams[0].players[0].user_id}
                user={court.teams[0].players[0]}
              />
            </Grid>
            {court.teams[0].players[1] && (
              <Grid item style={{ marginLeft: '16px' }}>
                <UserAvatarWithName
                  key={court.teams[0].players[1].user_id}
                  user={court.teams[0].players[1]}
                />
              </Grid>
            )}
          </Grid>

          <Grid
            item
            xs={6}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid item>
              <UserAvatarWithName
                key={court.teams[1].players[0].user_id}
                user={court.teams[1].players[0]}
              />
            </Grid>
            {court.teams[1].players[1] && (
              <Grid item style={{ marginLeft: '16px' }}>
                <UserAvatarWithName
                  key={court.teams[1].players[1].user_id}
                  user={court.teams[1].players[1]}
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          PaperProps={{
            style: {
              pointerEvents: 'auto',
            },
          }}
        >
          <DialogTitle>Select Score</DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              {Array.from(
                {
                  length:
                    scoreType === 'points'
                      ? americano_max_score + 1
                      : maxGamesPoints + 1,
                },
                (_, i) => (
                  <Grid item key={i}>
                    <Button
                      variant="contained"
                      onClick={() => handleScoreSelect(i)}
                      sx={{
                        minHeight: '50px',
                        minWidth: '50px',
                        padding: '4px 8px',
                        borderColor: 'primary.main',
                        fontSize: '1.4rem',
                      }}
                    >
                      {i}
                    </Button>
                  </Grid>
                )
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleReset} color="error">
              Reset Scores
            </Button>
            <Button
              variant="contained"
              onClick={handleDialogClose}
              color="primary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Grid>
  )
}

export function PlayersSkippingRound({ playersWhoSkipARound, themeMode }) {
  if (!playersWhoSkipARound || playersWhoSkipARound.length === 0) {
    return null
  }

  const themeClass = themeMode === 'dark' ? styles.darkTheme : styles.lightTheme

  return (
    <Paper
      className={themeClass}
      sx={{
        padding: '16px',
        width: '100%',
        maxWidth: '800px',
        backgroundColor: '#f5f5f5',
        borderRadius: '12px',
      }}
    >
      <Typography variant="h6" style={{ textAlign: 'center' }}>
        Players Skipping This Round
      </Typography>

      <Grid item container spacing={2} mt={0} justifyContent="center">
        {playersWhoSkipARound.map((player, index) => (
          <Grid
            item
            key={index}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Typography
              variant="subtitle1"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Link
                className={styles.reverseLink}
                to={`/users/${player.id}`}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <UserAvatarWithName key={player.user_id} user={player} />
              </Link>
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Paper>
  )
}
