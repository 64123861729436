import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export const useResponsive = () => {
  const theme = useTheme();
  
  return {
    isScreenMdOrMore: useMediaQuery(theme.breakpoints.up('md')),
  };
}
