import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import EditIcon from '@mui/icons-material/Edit'

import dayjs from 'dayjs'

import { useCurrentUser } from 'hooks/cookies'
import { useTournaments } from 'hooks/api/tournamentHooks'
import { useRefreshToken } from 'hooks/api/authHooks'
import { useAllRolePolicies } from 'hooks/api/roleHooks'
import { useUpdateOrganization } from 'hooks/api/organizationHooks'
import { isAdmin, isOrganizationAdmin, getValidUrl, refreshJwtAndFetchPolicies } from 'utils'

import { MainWrapper } from 'components/layout/MainWrapper/MainWrapper'
import { CustomTimePicker } from 'components/CustomPicker'
import { TournamentBlock } from 'components/TournamentBlock/TournamentBlock'
import { PulsingIcon } from 'components/PulsingIcon/PulsingIcon'
import { useThemeSwitcher } from 'components/ThemeProvider/ThemeProvider'
import { SelectTournamentType } from 'components/SelectTournamentType/SelectTournamentType'
import { Map } from 'components/Map/Map'
import { Directions } from 'components/Directions/Directions'
import { LocationSelector } from 'components/LocationSelector/LocationSelector'

import styles from 'styles/main.module.scss'

/* eslint-disable react/jsx-props-no-spreading */
export function OrganizationBase({
  organizationData,
  organizationStatisticData,
}) {
  const { themeMode } = useThemeSwitcher()
  const themeClass = themeMode === 'dark' ? styles.darkTheme : styles.lightTheme

  const [user] = useCurrentUser()
  const admin = isAdmin(user)
  const organizationAdmin = isOrganizationAdmin(user, organizationData?.id)
  const adminOrOrganizationAdmin = admin || organizationAdmin

  const limit = 2
  const [currentPageCreated, setCurrentPageCreated] = useState(1)
  const [totalCreated, setTotalCreated] = useState(0)
  const offsetCreated = (currentPageCreated - 1) * limit
  const countCreated = Math.ceil(totalCreated / limit)

  const [currentPageInProgress, setCurrentPageInProgress] = useState(1)
  const [totalInProgress, setTotalInProgress] = useState(0)
  const offsetInProgress = (currentPageInProgress - 1) * limit
  const countInProgress = Math.ceil(totalInProgress / limit)

  const [currentPageFinished, setCurrentPageFinished] = useState(1)
  const [totalFinished, setTotalFinished] = useState(0)
  const offsetFinished = (currentPageFinished - 1) * limit
  const countFinished = Math.ceil(totalFinished / limit)

  const [openDialog, setOpenDialog] = useState(false)
  const [openDirectionsDialog, setOpenDirectionsDialog] = useState(false)

  const [readOnly, setReadOnly] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')

  const currentDate = dayjs().format('DD.MM.YYYY')

  const { data: createdOrganizationTournamentsData } = useTournaments({
    status: 'created',
    organization_id: organizationData?.id,
    after_date: currentDate,
    order: 'asc',
    limit,
    offset: offsetCreated,
    is_deleted: false,
  })

  const { data: inProgressOrganizationTournamentsData } = useTournaments({
    status: 'in_progress',
    organization_id: organizationData?.id,
    order: 'asc',
    limit,
    offset: offsetInProgress,
    is_deleted: false,
  })

  const { data: finishedOrganizationTournamentsData } = useTournaments({
    status: 'finished',
    organization_id: organizationData?.id,
    order: 'desc',
    limit,
    offset: offsetFinished,
    is_deleted: false,
  })

  useEffect(() => {
    if (createdOrganizationTournamentsData?.total) {
      setTotalCreated(createdOrganizationTournamentsData.total)
    }

    if (inProgressOrganizationTournamentsData?.total) {
      setTotalInProgress(inProgressOrganizationTournamentsData.total)
    }

    if (finishedOrganizationTournamentsData?.total) {
      setTotalFinished(finishedOrganizationTournamentsData.total)
    }
  }, [
    createdOrganizationTournamentsData,
    inProgressOrganizationTournamentsData,
    finishedOrganizationTournamentsData,
  ])

  const handlePageCreatedChange = (_, page) => {
    setCurrentPageCreated(page)
  }

  const handlePageInProgressChange = (_, page) => {
    setCurrentPageInProgress(page)
  }

  const handlePageFinishedChange = (_, page) => {
    setCurrentPageFinished(page)
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      name: organizationData?.name || '',
      website: organizationData?.website || '',
      phone: organizationData?.phone || '',
      description: organizationData?.description || '',
      hasPermanentLocation: organizationData?.has_permanent_location || false,
      locationId: organizationData?.location_id || null,
      location: organizationData?.location || null,
    },
  })

  const collectChanges = () => {
    const currentValues = {
      name: watch('name'),
      website: watch('website'),
      phone: watch('phone'),
      description: watch('description'),
      has_permanent_location: watch('hasPermanentLocation'),
      location_id: watch('locationId'),
    }

    const changes = Object.keys(currentValues).reduce((acc, key) => {
      if (currentValues[key] !== organizationData[key]) {
        acc[key] = currentValues[key]
      }
      return acc
    }, {})

    return changes
  }

  const { trigger: updateOrganizationTrigger } = useUpdateOrganization()

  const { trigger: refreshTokenTrigger } = useRefreshToken()
  const { trigger: allRolePoliciesTrigger } = useAllRolePolicies()

  const handleRedirect = (path) => {
    window.location.href = path
  }

  const handleReadOnlyChange = () => {
    setReadOnly(!readOnly)
  }

  const handleUpdateOrganization = () => {
    const changes = collectChanges()

    if (!watch('hasPermanentLocation') && !changes.hasPermanentLocation) {
      changes.location_id = null
    }

    updateOrganizationTrigger(
      {
        id: organizationData.id,
        ...changes,
      },
      {
        onSuccess: () => {
          refreshJwtAndFetchPolicies(refreshTokenTrigger, allRolePoliciesTrigger, handleRedirect)
          // with set readonly i get error: ResizeObserver loop completed with undelivered notifications.
          // setReadOnly(true)
          window.location.reload()
        },
        onError: (data) => setErrorMessage(data.info.error),
      }
    )
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const toggleDialog = () => {
    setOpenDialog(!openDialog)
  }

  const handleCloseDirectionsDialog = () => {
    setOpenDirectionsDialog(false)
  }

  return (
    <MainWrapper
      maxWidth="md"
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    >
      <FormControl
        component="form"
        onSubmit={handleSubmit(handleUpdateOrganization)}
        fullWidth
      >
        {readOnly ? (
          <Grid container spacing={2} mb={4} alignItems="flex-start">
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Typography variant="h5">{watch('name')}</Typography>
            </Grid>

            <Grid item container spacing={2} xs={12}>
              {watch('phone') && (
                <Grid
                  item
                  alignSelf="flex-start"
                  xs={12}
                  md={watch('website') ? 6 : 12}
                >
                  <Link
                    className={styles.link}
                    to={`tel:${watch('phone')}`}
                    style={{ display: 'block', width: '100%' }}
                  >
                    <TextField
                      style={{ width: '100%' }}
                      error={!!errors.phone}
                      label="Phone"
                      {...register('phone')}
                      InputProps={{
                        readOnly,
                      }}
                    />
                  </Link>
                </Grid>
              )}

              {watch('website') && (
                <Grid item xs={12} md={watch('phone') ? 6 : 12}>
                  <Link
                    className={styles.link}
                    to={getValidUrl(watch('website'))}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ display: 'block', width: '100%' }}
                  >
                    <TextField
                      style={{ width: '100%' }}
                      error={!!errors.website}
                      label="Website"
                      {...register('website')}
                      InputProps={{
                        readOnly,
                      }}
                    />
                  </Link>
                </Grid>
              )}
            </Grid>

            {watch('hasPermanentLocation') && (
              <Grid item container spacing={2} xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid item xs={6} alignSelf="flex-start">
                    <CustomTimePicker
                      sx={{ width: '100%' }}
                      label="Opening Time *"
                      control={control}
                      name="openingTime"
                      value={dayjs(watch('location')?.opening_time)}
                      readOnly={readOnly}
                    />
                  </Grid>
                  <Grid item xs={6} alignSelf="flex-start">
                    <CustomTimePicker
                      sx={{ width: '100%' }}
                      label="Closing Time *"
                      control={control}
                      name="closingTime"
                      value={dayjs(watch('location')?.closing_time)}
                      readOnly={readOnly}
                    />
                  </Grid>
                </LocalizationProvider>

                <Grid item xs={12} alignSelf="flex-start">
                  <TextField
                    style={{ width: '100%' }}
                    label="Courts number"
                    value={watch('location').courts?.length || 0}
                    InputProps={{
                      readOnly: true,
                    }}
                    type="number"
                  />
                </Grid>
              </Grid>
            )}

            {watch('hasPermanentLocation') && (
              <>
                <Grid item xs={12} md={6}>
                  <Map
                    locationDetails={{
                      lat: watch('location').latitude,
                      lng: watch('location').longitude,
                    }}
                    themeMode={themeMode}
                  />
                </Grid>

                <Grid item xs={12} alignSelf="flex-start">
                  <TextField
                    style={{ width: '100%' }}
                    label="Address"
                    value={watch('location').address}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} alignSelf="flex-start">
                  <Button
                    style={{ width: '100%' }}
                    onClick={() => setOpenDirectionsDialog(true)}
                    type="button"
                    variant="contained"
                  >
                    Directions
                  </Button>
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <TextField
                fullWidth
                error={!!errors.description}
                label="Description"
                multiline
                {...register('description')}
                readOnly
                maxRows={10}
              />
            </Grid>

            {adminOrOrganizationAdmin && (
              <>
                <Grid
                  item
                  md={2}
                  sx={{ display: { xs: 'none', md: 'block' } }}
                />
                <Grid item xs={12} md={4}>
                  <Button
                    style={{ width: '100%' }}
                    onClick={(e) => {
                      e.preventDefault()
                      handleReadOnlyChange()
                    }}
                    type="button"
                    startIcon={<EditIcon />}
                    variant="contained"
                  >
                    edit info
                  </Button>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Button
                    style={{ width: '100%' }}
                    onClick={toggleDialog}
                    type="button"
                    variant="contained"
                  >
                    Create Tournament
                  </Button>
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ display: { xs: 'none', md: 'block' } }}
                />
              </>
            )}

            {inProgressOrganizationTournamentsData?.data.length > 0 && (
              <>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="h5">Live tournaments</Typography>
                    <PulsingIcon color="error" fontSize="medium" />
                  </Box>
                </Grid>
                {inProgressOrganizationTournamentsData?.data.map(
                  (tournament) => (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      key={`tournament-in-progress-${tournament.id}`}
                    >
                      <TournamentBlock
                        tournament={tournament}
                        startTimeFormat="DD.MM.YYYY hh:mm A"
                        themeMode={themeMode}
                      />
                    </Grid>
                  )
                )}
                <Grid item xs={12}>
                  <Pagination
                    count={countInProgress}
                    page={currentPageInProgress}
                    shape="rounded"
                    onChange={handlePageInProgressChange}
                    siblingCount={0}
                    size="medium"
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Typography variant="h5">Open tournaments</Typography>
            </Grid>
            {createdOrganizationTournamentsData?.data.length > 0 ? (
              <>
                {createdOrganizationTournamentsData?.data.map((tournament) => (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    key={`open-tournament-${tournament.id}`}
                  >
                    <TournamentBlock
                      tournament={tournament}
                      startTimeFormat="DD.MM.YYYY hh:mm A"
                      themeMode={themeMode}
                    />
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Pagination
                    count={countCreated}
                    page={currentPageCreated}
                    shape="rounded"
                    onChange={handlePageCreatedChange}
                    siblingCount={0}
                    size="medium"
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={12} sx={{ textAlign: 'center', color: 'grey' }}>
                <Typography variant="body1">No open tournaments</Typography>
              </Grid>
            )}

            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Typography variant="h5">Finished tournaments</Typography>
            </Grid>
            {finishedOrganizationTournamentsData?.data.length > 0 ? (
              <>
                {finishedOrganizationTournamentsData?.data.map((tournament) => (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    key={`finished-tournament-${tournament.id}`}
                  >
                    <TournamentBlock
                      tournament={tournament}
                      startTimeFormat="DD.MM.YYYY hh:mm A"
                      themeMode={themeMode}
                    />
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Pagination
                    count={countFinished}
                    page={currentPageFinished}
                    shape="rounded"
                    onChange={handlePageFinishedChange}
                    siblingCount={0}
                    size="medium"
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={12} sx={{ textAlign: 'center', color: 'grey' }}>
                <Typography variant="body1">No finished tournaments</Typography>
              </Grid>
            )}

            {organizationStatisticData && (
              <>
                <Grid item xs={12} mt={2} sx={{ textAlign: 'center' }}>
                  <Typography variant="h5">Statistic</Typography>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    style={{ width: '100%' }}
                    label="Total tournaments"
                    value={organizationStatisticData?.total_tournaments || 0}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    style={{ width: '100%' }}
                    label="Unique players"
                    value={organizationStatisticData?.unique_players || 0}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} mt={2} sx={{ textAlign: 'center' }}>
                  <Typography variant="h5">Top players</Typography>
                </Grid>

                <Grid item xs={12}>
                  <TableContainer component={Paper} className={themeClass}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Place</TableCell>
                          <TableCell align="center">Name</TableCell>
                          <TableCell align="center">🥇</TableCell>
                          <TableCell align="center">🥈</TableCell>
                          <TableCell align="center">🥉</TableCell>
                          <TableCell align="center">Total</TableCell>
                          <TableCell align="center">
                            Tournaments played
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {organizationStatisticData?.top_players != null && (
                        <TableBody>
                          {organizationStatisticData?.top_players.map(
                            (top_player, index) => (
                              <TableRow key={index}>
                                <TableCell align="center">
                                  {index + 1}
                                </TableCell>
                                <TableCell align="center">
                                  <Link
                                    className={styles.link}
                                    to={`/users/${top_player.user_id}`}
                                  >
                                    {top_player.name} {top_player.surname}
                                  </Link>
                                </TableCell>
                                <TableCell align="center">
                                  {top_player.gold_medals}
                                </TableCell>
                                <TableCell align="center">
                                  {top_player.silver_medals}
                                </TableCell>
                                <TableCell align="center">
                                  {top_player.bronze_medals}
                                </TableCell>
                                <TableCell align="center">
                                  {top_player.gold_medals +
                                    top_player.silver_medals +
                                    top_player.bronze_medals}
                                </TableCell>
                                <TableCell align="center">
                                  {top_player.tournaments_played}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Grid>
              </>
            )}
          </Grid>
        ) : (
          <Grid container spacing={2} mb={4} alignItems="flex-start">
            <Grid item xs={12}>
              <TextField
                required
                style={{ width: '100%' }}
                error={!!errors.name}
                label="Organization name"
                InputProps={{
                  readOnly,
                }}
                {...register('name', { required: true })}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                style={{ width: '100%' }}
                error={!!errors.phone}
                label="Phone"
                {...register('phone')}
                InputProps={{
                  readOnly,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                style={{ width: '100%' }}
                error={!!errors.website}
                label="Website"
                {...register('website')}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Checkbox
                {...register('hasPermanentLocation')}
                defaultChecked={organizationData?.has_permanent_location}
              />
              <Typography variant="body1">Club with fixed location</Typography>
            </Grid>

            {watch('hasPermanentLocation') && (
              <>
                <Grid
                  item
                  md={3}
                  sx={{ display: { xs: 'none', md: 'block' } }}
                />
                <Grid item xs={12} md={6}>
                  <LocationSelector
                    themeMode={themeMode}
                    currentLocation={watch('location')}
                    onLocationSelect={(location) => {
                      setValue('locationId', location.id, {
                        shouldValidate: true,
                      })
                      setValue('location', location, { shouldValidate: true })
                      setValue('hasPermanentLocation', true, {
                        shouldValidate: true,
                      })
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={3}
                  sx={{ display: { xs: 'none', md: 'block' } }}
                />
              </>
            )}

            <Grid item xs={12}>
              <TextField
                sx={{
                  width: '100%',
                  height: '100%',
                  '& .MuiInputBase-root': {
                    height: '100%',
                  },
                }}
                error={!!errors.description}
                label="Description"
                multiline
                {...register('description')}
                maxRows={20}
                InputProps={{
                  readOnly,
                }}
              />
            </Grid>

            {adminOrOrganizationAdmin && (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ display: { xs: 'none', md: 'block' } }}
                />
                <Grid item xs={6} md={4}>
                  <Button
                    style={{ width: '100%' }}
                    onClick={(e) => {
                      e.preventDefault()
                      handleReadOnlyChange()
                    }}
                    type="button"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </Grid>

                <Grid item xs={6} md={4}>
                  <Button
                    style={{ width: '100%' }}
                    type="submit"
                    disabled={!Object.keys(collectChanges()).length}
                    variant="contained"
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid
                  item
                  md={2}
                  sx={{ display: { xs: 'none', md: 'block' } }}
                />
              </>
            )}
          </Grid>
        )}
      </FormControl>

      <SelectTournamentType
        openSelectTournamentTypeDialog={openDialog}
        handleCloseSelectTournamentTypeDialog={handleCloseDialog}
        organizationId={organizationData?.id}
      />

      <Directions
        latitude={organizationData?.location?.latitude}
        longitude={organizationData?.location?.longitude}
        isDirectionsDialogOpen={openDirectionsDialog}
        closeDirectionsDialog={handleCloseDirectionsDialog}
      />
    </MainWrapper>
  )
}
