import React from 'react'
import { createBrowserRouter } from 'react-router-dom'

import {
  Organization,
  Organizations,
  CreateTournament,
  CreateAmericanoSingle,
  CreateAmericanoTeam,
  CreateRoundRobinTeam,
  CreateOrganization,
  CreateUser,
  Login,
  NotFound,
  Profile,
  Register,
  Root,
  Tournament,
  Tournaments,
  User,
  Users,
  PolicyPage,
  TermsAndConditionsPage,
  Location,
  CreateLocation,
  Locations,
  Plans,
} from 'pages'

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
  },
  {
    path: '/privacy-policy',
    element: <PolicyPage />,
  },
  {
    path: '/terms-and-conditions',
    element: <TermsAndConditionsPage />,
  },
  {
    path: '/organizations',
    element: <Organizations />,
  },
  {
    path: '/locations',
    element: <Locations />,
  },
  {
    path: '/tournaments',
    element: <Tournaments />,
  },
  {
    path: '/users',
    element: <Users />,
  },
  {
    path: '/admin/users',
    element: <Users isAdmin />,
  },
  {
    path: '/users/:userId',
    element: <User />,
  },
  {
    path: '/profile',
    element: <Profile />,
  },
  {
    path: '/profile/organizations',
    element: <Organizations isOwner />,
  },
  {
    path: '/create-user',
    element: <CreateUser />,
  },
  {
    path: '/locations/:locationId',
    element: <Location />,
  },
  {
    path: '/create-location',
    element: <CreateLocation />,
  },
  {
    path: '/create-tournament',
    element: <CreateTournament />,
  },
  {
    path: '/organizations/:organizationId',
    element: <Organization />,
  },
  {
    path: '/create-organization',
    element: <CreateOrganization />,
  },
  {
    path: '/organizations/:organizationId/new-americano-single',
    element: <CreateAmericanoSingle />,
  },
  {
    path: '/organizations/:organizationId/new-americano-team',
    element: <CreateAmericanoTeam />,
  },
  {
    path: '/organizations/:organizationId/new-round-robin-team',
    element: <CreateRoundRobinTeam />,
  },
  {
    path: '/tournament/:tournamentId',
    element: <Tournament />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/register',
    element: <Register />,
  },
  {
    path: '/plans',
    element: <Plans />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
])
