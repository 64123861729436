import React from 'react'

import { AmericanoSingleCreated } from 'pages/Tournament/Americano/AmericanoCreated/AmericanoSingleCreated'
import { AmericanoTeamCreated } from 'pages/Tournament/Americano/AmericanoCreated/AmericanoTeamCreated'
import { AmericanoSingleInProgress } from 'pages/Tournament/Americano/AmericanoInProgress/AmericanoSingleInProgress'
import { AmericanoTeamInProgress } from 'pages/Tournament/Americano/AmericanoInProgress/AmericanoTeamInProgress'
import { AmericanoSingleFinished } from 'pages/Tournament/Americano/AmericanoFinished/AmericanoSingleFinished'
import { AmericanoTeamFinished } from 'pages/Tournament/Americano/AmericanoFinished/AmericanoTeamFinished'
import { NotFound } from 'pages/NotFound/NotFound'

import { MainWrapper } from 'components/layout/MainWrapper/MainWrapper'

import { useAmericanoSingle, useAmericanoTeam } from 'hooks/api/tournamentHooks'

function AmericanoSingleComponent({ tournamentId }) {
  const {
    data: americanoData,
    isLoading,
    mutate,
  } = useAmericanoSingle(tournamentId)

  if (isLoading) {
    return <MainWrapper>loading...</MainWrapper>
  }

  switch (americanoData.status) {
    case 'created':
      return <AmericanoSingleCreated data={americanoData} mutate={mutate} />
    case 'in_progress':
      return <AmericanoSingleInProgress data={americanoData} mutate={mutate} />
    case 'finished':
      return <AmericanoSingleFinished data={americanoData} mutate={mutate} />
    default:
      return <NotFound message='Tournament not found' />
  }
}

function AmericanoTeamComponent({ tournamentId }) {
  const {
    data: americanoData,
    isLoading,
    mutate,
  } = useAmericanoTeam(tournamentId)

  if (isLoading) {
    return <MainWrapper>loading...</MainWrapper>
  }

  switch (americanoData.status) {
    case 'created':
      return <AmericanoTeamCreated data={americanoData} mutate={mutate} />
    case 'in_progress':
      return <AmericanoTeamInProgress data={americanoData} mutate={mutate} />
    case 'finished':
      return <AmericanoTeamFinished data={americanoData} mutate={mutate} />
    default:
      return <NotFound message='Tournament not found' />
  }
}

export function Americano({ tournamentType, tournamentId }) {
  switch (tournamentType) {
    case 'americano_single':
      return <AmericanoSingleComponent tournamentId={tournamentId} />
    case 'americano_team':
      return <AmericanoTeamComponent tournamentId={tournamentId} />
    default:
      return <NotFound message='Tournament not found' />
  }
}
