import React, { useState } from 'react'
import { Button, FormControl, Grid, TextField, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'

import { useCreateUser } from 'hooks/api/userHooks'
import { MainWrapper } from 'components/layout/MainWrapper/MainWrapper'
import { UsernameField } from 'components/Fields/Username'

/* eslint-disable react/jsx-props-no-spreading */
export function CreateUser() {
  const [errorMessage, setErrorMessage] = useState('')

  const navigate = useNavigate()
  const { trigger } = useCreateUser()

  const methods = useForm({
    defaultValues: {
      name: '',
      surname: '',
      username: '',
    },
  })

  const {
    handleSubmit,
    formState: { errors },
    watch,
    register,
  } = methods

  const requiredFieldAreFilled = watch('name') && watch('username')

  const handleUserCreate = (data) => {
    trigger(
      {
        ...data,
        is_admin: false,
        is_organization_admin: false,
      },
      {
        onSuccess: () => navigate('/users'),
        onError: (data) => setErrorMessage(data.info.error),
      }
    )
  }

  return (
    <MainWrapper
      maxWidth="sm"
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    >
      <FormProvider {...methods}>
        <FormControl
          component="form"
          onSubmit={handleSubmit(handleUserCreate)}
          fullWidth
        >
          <Grid container spacing={2} mt={{ xs: 5, md: 20 }}>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Typography variant="h5">Create user</Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                required
                style={{ width: '100%' }}
                error={!!errors.name}
                helperText={errors.name ? errors.name.message : ''}
                label="Name"
                {...register('name', { required: 'Name is required' })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                style={{ width: '100%' }}
                label="Surname"
                error={!!errors.surname}
                helperText={errors.surname ? errors.surname.message : ''}
                {...register('surname')}
              />
            </Grid>

            <Grid item xs={12}>
              <UsernameField />
            </Grid>

            <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }} />
            <Grid item xs={12} md={4}>
              <Button
                disabled={
                  !requiredFieldAreFilled || Object.keys(errors).length > 0
                }
                style={{ width: '100%' }}
                type="submit"
                variant="contained"
              >
                Create
              </Button>
            </Grid>
            <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }} />
          </Grid>
        </FormControl>
      </FormProvider>
    </MainWrapper>
  )
}
