import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import { getFetcher, postFetcher, putFetcher, deleteFetcher } from './fetchers'

export function useOrganizations({
  adminId = null,
  has_permanent_location = null,
  limit = 10,
  offset = 0,
}) {
  const params = { limit, offset }
  if (adminId) {
    params.admin_user_id = adminId
  }

  if (has_permanent_location !== null) {
    params.has_permanent_location = has_permanent_location
  }

  const searchParams = new URLSearchParams(params)
  return useSWR(`/organization?${searchParams}`, getFetcher)
}

export function useOrganization(organizationId) {
  return useSWR(`/organization/${organizationId}`, getFetcher)
}

export function useOrganizationStatistic(organizationId) {
  return useSWR(`/organization/${organizationId}/statistic`, getFetcher)
}

export function useCreateOrganization() {
  return useSWRMutation('/organization', postFetcher)
}

export function useUpdateOrganization() {
  return useSWRMutation('/organization/', putFetcher)
}

export function useCreateOrganizationAddress() {
  return useSWRMutation('/organization/address', postFetcher)
}

export function useUpdateOrganizationAddress() {
  return useSWRMutation('/organization/address', putFetcher)
}

export function useDeleteOrganizationAddress() {
  return useSWRMutation('/organization/address', deleteFetcher)
}

export function usePseudoDeleteOrganizationAddress() {
  return useSWRMutation('/organization/address/pseudo_delete', deleteFetcher)
}

export function useCanOrganizatorUpdateUser({ userId }) {
  return useSWRMutation(`/organization/member/can_update/${userId}`, getFetcher)
}

export function useUpdateOrganizationMember() {
  return useSWRMutation('/organization/member', putFetcher)
}
