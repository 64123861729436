import React, { useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Card,
  CardContent,
  CardActions,
} from '@mui/material'
import { Add, Edit, Delete } from '@mui/icons-material'

import styles from './LocationCourts.module.scss'

export function LocationCourts({ courts, setCourts, readOnly = true }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [currentCourt, setCurrentCourt] = useState(null)

  const [dialogMode, setDialogMode] = useState('add')

  const [newCourtName, setNewCourtName] = useState('')
  const [courtType, setCourtType] = useState('indoor')
  const [wallsType, setWallsType] = useState('crystal')
  const [size, setSize] = useState('double')

  const openDialog = (mode, court = null) => {
    setDialogMode(mode)
    setCurrentCourt(court)

    setNewCourtName(court ? court.name : '')
    setCourtType(court ? court.court_type : 'indoor')
    setWallsType(court ? court.walls_type : 'crystal')
    setSize(court ? court.size : 'double')

    setIsDialogOpen(true)
  }

  const closeDialog = () => {
    setIsDialogOpen(false)
  }

  const handleAddOrUpdateCourt = () => {
    if (dialogMode === 'add') {
      const newCourt = {
        id: `temp-${Date.now()}`,
        name: newCourtName,
        court_type: courtType,
        walls_type: wallsType,
        size,
      }
      setCourts([...courts, newCourt])
    } else if (dialogMode === 'edit' && currentCourt) {
      const updatedCourts = courts.map((court) =>
        court.id === currentCourt.id
          ? {
              ...court,
              name: newCourtName,
              court_type: courtType,
              walls_type: wallsType,
              size,
            }
          : court
      )
      setCourts(updatedCourts)
    }

    closeDialog()
  }

  const handleDeleteCourt = (courtId) => {
    setCourts(
      courts
        .map((court) => {
          if (court.id === courtId) {
            if (courtId.toString().startsWith('temp-')) {
              return null
            }

            return { ...court, is_deleted: true }
          }
          return court
        })
        .filter(Boolean)
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Typography variant="h6">Courts</Typography>
      </Grid>
      {courts.length === 0 && (
        <Grid item xs={12} sx={{ textAlign: 'center', color: 'grey' }}>
          <Typography variant="body1">No courts found</Typography>
        </Grid>
      )}
      {courts.map((court) => (
        <Grid item xs={6} md={4} key={court.id}>
          <Card className={styles.locationCourtBlock} variant="outlined">
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                {court.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Type: {court.court_type}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Walls: {court.walls_type}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Size: {court.size}
              </Typography>
            </CardContent>
            {!readOnly && (
              <CardActions disableSpacing className={styles.cardActions}>
                <IconButton
                  aria-label="edit"
                  onClick={() => openDialog('edit', court)}
                >
                  <Edit className={styles.icon} />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleDeleteCourt(court.id)}
                >
                  <Delete className={styles.icon} />
                </IconButton>
              </CardActions>
            )}
          </Card>
        </Grid>
      ))}
      {!readOnly && (
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Button
              onClick={() => openDialog('add')}
              type="button"
              startIcon={<Add />}
              variant="contained"
            >
              Add Court
            </Button>
          </Box>
        </Grid>
      )}

      <Dialog open={isDialogOpen} onClose={closeDialog} fullWidth>
        <DialogTitle>
          {dialogMode === 'add' ? 'Add New Court' : 'Edit Court'}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                label="Court Name"
                type="text"
                fullWidth
                value={newCourtName}
                onChange={(e) => setNewCourtName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Court Type</InputLabel>
                <Select
                  value={courtType}
                  label="Court Type"
                  onChange={(e) => setCourtType(e.target.value)}
                >
                  <MenuItem value="indoor">Indoor</MenuItem>
                  <MenuItem value="outdoor">Outdoor</MenuItem>
                  <MenuItem value="roofed outdoor">Roofed Outdoor</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Walls Type</InputLabel>
                <Select
                  value={wallsType}
                  label="Walls Type"
                  onChange={(e) => setWallsType(e.target.value)}
                >
                  <MenuItem value="crystal">Crystal</MenuItem>
                  <MenuItem value="panoramic">Panoramic</MenuItem>
                  <MenuItem value="wall">Wall</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Size</InputLabel>
                <Select
                  value={size}
                  label="Size"
                  onChange={(e) => setSize(e.target.value)}
                >
                  <MenuItem value="single">Single</MenuItem>
                  <MenuItem value="double">Double</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary" variant="contained">
            Cancel
          </Button>
          <Button
            onClick={handleAddOrUpdateCourt}
            color="primary"
            variant="contained"
          >
            {dialogMode === 'add' ? 'Add' : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}
