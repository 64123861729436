import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import { getFetcher } from './fetchers'

export function useTotalStatistic() {
  return useSWR('/statistic/total', getFetcher)
}

export function useUserBasicStatistic(userId) {
  return useSWR(`/statistic/user/${userId}/basic`, getFetcher)
}

export function useUserAdvancedStatistic(userId) {
  return useSWRMutation(`/statistic/user/${userId}/advanced`, getFetcher)
}
