import React, { useState } from 'react'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import { useContinueSubscription } from 'hooks/api/stripeHooks'

export function PlanCard({
  product,
  duration,
  userRoleId,
  actionButtonLabel = 'Choose this plan',
  actionButtonColor = 'primary',
  actionButtonHandler,
  forceActionButton = false,
  remainingTimeData = null,
  setRemainingTimeData,
  showPrice = true,
}) {
  const isCurrentPlan = userRoleId === product.connected_role_id
  const isCurrentPlanWithRenewal =
    isCurrentPlan && !remainingTimeData?.cancel_at_period_end
  const isCurrentPlanButNoRenewal =
    isCurrentPlan &&
    remainingTimeData &&
    remainingTimeData.cancel_at_period_end &&
    remainingTimeData.remaining_time

  const { trigger: continueSubscriptionTrigger } = useContinueSubscription()
  const [isContinuing, setIsContinuing] = useState(false)

  const handleContinueSubscription = async () => {
    setIsContinuing(true)
    await continueSubscriptionTrigger()
    setIsContinuing(false)
    setRemainingTimeData(null)
  }

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative',
      }}
    >
      <CardMedia
        component="img"
        alt={product.name}
        height="200"
        image={product.image}
      />
      {duration === 'yearly' && product.yearly_price?.price !== 0 && (
        <Box
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
            backgroundColor: 'red',
            color: 'white',
            padding: '4px 8px',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <LocalOfferIcon sx={{ marginRight: 1 }} />
          <Typography variant="body2">2 MONTH OFF</Typography>
        </Box>
      )}
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography gutterBottom variant="h5" component="div">
          {product.name}
        </Typography>
        <Typography variant="h6" color="text.primary">
          <b>Features:</b>
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ whiteSpace: 'pre-wrap' }}
        >
          {product.description}
        </Typography>
        {isCurrentPlanButNoRenewal && (
          <Typography variant="body2" color="error" mt={2}>
            {Math.floor(
              remainingTimeData.remaining_time / (1e9 * 60 * 60 * 24)
            )}{' '}
            day(s) remaining
          </Typography>
        )}
        {showPrice && (
          <Typography variant="h6" color="text.primary" sx={{ mt: 2 }}>
            {duration === 'monthly' && product.monthly_price?.price === 0 && (
              <strong>Free</strong>
            )}
            {duration === 'monthly' && product.monthly_price?.price !== 0 && (
              <>
                AED{' '}
                <strong>
                  {(product.monthly_price.price / 100).toFixed(2)}
                </strong>
                <span style={{ color: 'grey' }}> /per month</span>
              </>
            )}
            {duration === 'yearly' && product.yearly_price?.price === 0 && (
              <strong>Free</strong>
            )}
            {duration === 'yearly' && product.yearly_price?.price !== 0 && (
              <>
                AED{' '}
                <strong>{(product.yearly_price.price / 100).toFixed(2)}</strong>
                <span style={{ color: 'grey' }}> /per year</span>
              </>
            )}
          </Typography>
        )}
      </CardContent>
      <CardActions sx={{ justifyContent: 'center', padding: 2 }}>
        <Grid
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          {isCurrentPlanButNoRenewal && (
            <Button
              variant="contained"
              color="success"
              onClick={handleContinueSubscription}
              disabled={isContinuing}
              sx={{ margin: '0 auto' }}
            >
              {isContinuing ? (
                <CircularProgress size={24} />
              ) : (
                'Continue subscription'
              )}
            </Button>
          )}

          {isCurrentPlanWithRenewal && !forceActionButton && (
            <Button
              variant="contained"
              color="success"
              disabled
              onClick={actionButtonHandler}
              sx={{ margin: '0 auto' }}
            >
              Current Plan
            </Button>
          )}

          {(!isCurrentPlan || forceActionButton) && (
            <Button
              variant="contained"
              color={actionButtonColor}
              onClick={actionButtonHandler}
              sx={{ margin: '0 auto' }}
            >
              {actionButtonLabel}
            </Button>
          )}
        </Grid>
      </CardActions>
    </Card>
  )
}
