import React from 'react'
import { Link } from 'react-router-dom'
import { Grid, Paper, Typography } from '@mui/material'

import styles from './LocationBlock.module.scss'

export function LocationBlock({
  location,
  themeMode,
  withoutLink = false,
  openSelectLocationDialog = null,
}) {
  const themeClass = themeMode === 'dark' ? styles.darkTheme : styles.lightTheme

  if (location == null) {
    if (openSelectLocationDialog) {
      return (
        <Paper
          className={`${styles.locationBlock} ${themeClass}`}
          onClick={openSelectLocationDialog}
        >
          <Typography variant="h6" sx={{ textAlign: 'center' }}>
            Select Location
          </Typography>
        </Paper>
      )
    }

    return null
  }

  const countCourtsByType = () => {
    const courtTypes = {}

    location?.courts?.forEach((court) => {
      const courtType = court.court_type
      if (courtTypes[courtType]) {
        courtTypes[courtType] += 1
      } else {
        courtTypes[courtType] = 1
      }
    })

    return courtTypes
  }

  const courtTypes = countCourtsByType()

  const blockWithoutLink = (
    <Paper
      className={`${styles.locationBlock} ${themeClass}`}
      onClick={openSelectLocationDialog}
    >
      <Typography variant="h6">{location?.name || ''}</Typography>
      <Grid className={styles.locationType}>
        {courtTypes && Object.keys(courtTypes).length > 0 && (
          <Grid>
            {Object.entries(courtTypes).map(([courtType, count]) => (
              <Typography key={courtType} variant="body2">
                {count} {courtType} court{count > 1 ? 's' : ''}
              </Typography>
            ))}
          </Grid>
        )}

        <Typography variant="body2" mt={1}>
          📍{location?.city}, {location?.country}
        </Typography>
      </Grid>
    </Paper>
  )

  return withoutLink ? (
    blockWithoutLink
  ) : (
    <Link to={`/locations/${location.id}`} className={styles.locationBlockLink}>
      {blockWithoutLink}
    </Link>
  )
}
