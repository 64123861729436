import React, { useEffect, useRef, useState } from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import { Grid, CircularProgress, Box } from '@mui/material'
import styles from './Map.module.scss'

const defaultOptions = {
  panControl: true,
  zoomControl: true,
  fullscreenControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  clickableIcons: false,
  keyboardShortcuts: false,
  scrollwheel: false,
  disableDoubleClickZoom: false,
}

function useMap({ locationDetails, setLocationDetails, themeMode }) {
  const mapRef = useRef(null)
  const [mapKey, setMapKey] = useState(Math.random())
  const [markerKey, setMarkerKey] = useState(Math.random())
  const [showMarker, setShowMarker] = useState(false)
  const [zoom, setZoom] = useState(13)

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  })

  const onMapDragEnd = () => {
    const newCenter = mapRef.current.getCenter()
    const newZoom = mapRef.current.getZoom()
    setLocationDetails({
      ...locationDetails,
      lat: newCenter.lat(),
      lng: newCenter.lng(),
    })
    setZoom(newZoom)
  }

  useEffect(() => {
    setMapKey(Math.random())
  }, [themeMode])

  useEffect(() => {
    setMarkerKey(Math.random())
    setShowMarker(false)
    if (isLoaded) {
      setTimeout(() => {
        setShowMarker(true)
      }, 100)
    }
  }, [mapKey, isLoaded])

  return {
    mapKey,
    markerKey,
    showMarker,
    zoom,
    mapRef,
    isLoaded,
    loadError,
    onMapDragEnd,
  }
}

export function LocationPicker({
  locationDetails,
  setLocationDetails,
  themeMode,
}) {
  const {
    mapKey,
    markerKey,
    showMarker,
    zoom,
    mapRef,
    isLoaded,
    loadError,
    onMapDragEnd,
  } = useMap({ locationDetails, setLocationDetails, themeMode })

  if (loadError)
    return <Grid className={styles.gridContainer}>Error loading maps</Grid>
  if (!isLoaded)
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    )

  return (
    <Grid className={styles.gridContainer}>
      <GoogleMap
        key={mapKey}
        mapContainerStyle={{ height: '100%', width: '100%' }}
        center={{ lat: locationDetails.lat, lng: locationDetails.lng }}
        zoom={zoom}
        options={{
          ...defaultOptions,
          mapId:
            themeMode === 'dark'
              ? process.env.REACT_APP_GOOGLE_DARK_MAP_ID
              : process.env.REACT_APP_GOOGLE_LIGHT_MAP_ID,
        }}
        onLoad={(map) => {
          mapRef.current = map
        }}
        onDragEnd={onMapDragEnd}
      >
        {showMarker && (
          <Marker
            key={markerKey}
            position={{ lat: locationDetails.lat, lng: locationDetails.lng }}
          />
        )}
      </GoogleMap>
    </Grid>
  )
}

export function Map({ locationDetails = { lat: 0, lng: 0 }, themeMode }) {
  const {
    mapKey,
    markerKey,
    showMarker,
    zoom,
    mapRef,
    isLoaded,
    loadError,
    onMapDragEnd,
  } = useMap({ locationDetails, themeMode })

  if (loadError)
    return <Grid className={styles.gridContainer}>Error loading maps</Grid>
  if (!isLoaded)
    return (
      <Grid
        className={styles.gridContainer}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Box>
          <CircularProgress />
        </Box>
      </Grid>
    )

  return (
    <Grid className={styles.gridContainer}>
      <GoogleMap
        key={mapKey}
        mapContainerClassName={styles.mapContainer}
        center={{ lat: locationDetails.lat, lng: locationDetails.lng }}
        zoom={zoom}
        options={{
          ...defaultOptions,
          draggable: false,
          mapId:
            themeMode === 'dark'
              ? process.env.REACT_APP_GOOGLE_DARK_MAP_ID
              : process.env.REACT_APP_GOOGLE_LIGHT_MAP_ID,
        }}
        onLoad={(map) => {
          mapRef.current = map
        }}
        onDragEnd={onMapDragEnd}
      >
        {showMarker && (
          <Marker
            key={markerKey}
            position={{ lat: locationDetails.lat, lng: locationDetails.lng }}
          />
        )}
      </GoogleMap>
    </Grid>
  )
}
