import React, { useState } from 'react'
import { Grid } from '@mui/material'

import { LocationBlock } from 'components/LocationBlock/LocationBlock'
import { SelectLocationDialog } from 'components/SelectLocationDialog/SelectLocationDialog'

export function LocationSelector({
  currentLocation,
  onLocationSelect,
  themeMode,
}) {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const openDialog = () => setIsDialogOpen(true)
  const closeDialog = () => setIsDialogOpen(false)
  const handleLocationSelect = (location) => {
    onLocationSelect(location)
    closeDialog()
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <LocationBlock
          location={currentLocation}
          themeMode={themeMode}
          withoutLink
          openSelectLocationDialog={openDialog}
        />
      </Grid>
      <SelectLocationDialog
        open={isDialogOpen}
        onClose={closeDialog}
        themeMode={themeMode}
        callback={handleLocationSelect}
      />
    </Grid>
  )
}
