import React, { useRef, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import dayjs from 'dayjs'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  List,
  ListItem,
  TextField,
  Typography,
} from '@mui/material'

import EditIcon from '@mui/icons-material/Edit'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { LocalizationProvider } from '@mui/x-date-pickers'

import {
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share'

import {
  useAddUserToTournament,
  useAddUserToTournamentWaitlist,
  useRemoveUserFromTournament,
  useRemoveUserFromTournamentWaitlist,
  useStartTournament,
  useUpdateTournament,
  usePseudoDeleteTournament,
} from 'hooks/api/tournamentHooks'

import { useOrganization } from 'hooks/api/organizationHooks'

import { isAdmin } from 'utils'
import { useCurrentUser } from 'hooks/cookies'

import { EasyRegisterDialog } from 'components/EasyRegisterDialog'
import { MainWrapper } from 'components/layout/MainWrapper/MainWrapper'
import {
  TournamentRegisteredTeams,
  TournamentRegisteredWaitlistTeams,
  TournamentRegisteredLeftPlayers,
} from 'components/TournamentRegisteredPlayers/TournamentRegisteredPlayers'
import { useThemeSwitcher } from 'components/ThemeProvider/ThemeProvider'
import { DeleteTournamentDialog } from 'components/DeleteTournamentDialog/DeleteTournamentDialog'
import { CustomDatePicker, CustomTimePicker } from 'components/CustomPicker'
import { TournamentInfo } from 'components/TournamentInfo/TournamentInfo'
import { LocationBlock } from 'components/LocationBlock/LocationBlock'
import { LocationSelector } from 'components/LocationSelector/LocationSelector'
import { LocationCourtsSelector } from 'components/LocaitonCourts/LocationCourtsSelector'
import { RegisterUserToTournamentDialog } from 'components/RegisterUserToTournamentDialog/RegisterUserToTournamentDialog'

/* eslint-disable react/jsx-props-no-spreading */
export function AmericanoTeamCreated({ data: tournamentData, mutate }) {
  const tournamentType = 'americano_team'
  const navigate = useNavigate()

  const { themeMode } = useThemeSwitcher()

  const {
    organization_id,
    organization_name,
    max_users,
    teams,
    waitlist_teams,
    left_users,
  } = tournamentData

  const usersNumber = teams.flatMap((team) => team.users).length

  const { data: organizationData } = useOrganization(organization_id)

  const { tournamentId } = useParams()
  const tournamentIdInt = parseInt(tournamentId, 10)
  const [currentUser] = useCurrentUser()

  const admin = isAdmin(currentUser)
  const organizationAdmin =
    currentUser?.administered_organizations?.data?.length >= 1
  const adminOrOrganizationAdmin = admin || organizationAdmin

  const maxUsersOrZero = max_users || 0
  const maxTeams = maxUsersOrZero / 2

  const [easyRegisterOpen, setEasyRegisterOpen] = useState(false)
  const [easyRegisterWaitlistOpen, setEasyRegisterWaitlistOpen] =
    useState(false)
  const [selectUserOpen, setSelectUserOpen] = useState(false)
  const [selectWaitlistUserOpen, setSelectWaitlistUserOpen] = useState(false)

  const [courts, setCourts] = useState(tournamentData.courts || [])
  const [openStartTournamentModal, setOpenStartTournamentModal] =
    useState(false)

  const [openDeleteTournamentModal, setOpenDeleteTournamentModal] =
    useState(false)

  const [errorMessage, setErrorMessage] = useState('')

  const userGroupIdRef = useRef(0)

  const updateUserGroupId = (newValue) => {
    userGroupIdRef.current = newValue
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    setValue,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      readOnly: true,

      location_id: tournamentData?.location?.id || null,
      location: tournamentData?.location || null,
      organization: organizationData || null,
      hasPermanentLocation: organizationData?.has_permanent_location,

      name: tournamentData?.name || '',
      type: tournamentData?.type || tournamentType,
      maxTeams: maxTeams || 0,
      cycleCount: tournamentData?.cycle_count || 1,
      americanoMaxScore: tournamentData?.americano_max_score || 0,
      startTime: tournamentData?.start_time
        ? tournamentData?.start_time
        : Date.now(),
      endTime: tournamentData?.end_time ? tournamentData?.end_time : Date.now(),
      description: tournamentData?.description || '',
    },
  })

  useEffect(() => {
    setValue('organization', organizationData || null)
  }, [organizationData])

  const collectChanges = () => {
    const currentValues = {
      name: watch('name'),
      americano_max_score: watch('americanoMaxScore'),
      cycle_count: watch('cycleCount'),
      start_time: watch('startTime'),
      end_time: watch('endTime'),
      description: watch('description'),
      max_users: watch('maxTeams') * 2,
      location_id: watch('locationId'),
      courts,
    }

    const changes = Object.keys(currentValues).reduce((acc, key) => {
      if (currentValues[key] !== tournamentData[key]) {
        acc[key] = currentValues[key]
      }
      return acc
    }, {})

    return changes
  }

  const { trigger: updateTournamentTrigger } = useUpdateTournament()
  const { trigger: pseudoDeleteTournamentTrigger } = usePseudoDeleteTournament()
  const { trigger: startAmericanoTournamentTrigger } = useStartTournament()
  const { trigger: addUserToTournamentTrigger } = useAddUserToTournament()
  const { trigger: addUserToTournamentWaitlistTrigger } =
    useAddUserToTournamentWaitlist()
  const { trigger: removeUserFromTournamentTrigger } =
    useRemoveUserFromTournament()
  const { trigger: removeUserFromTournamentWaitlistTrigger } =
    useRemoveUserFromTournamentWaitlist()

  const handleStartTournament = () => {
    const tournamentIDInt = parseInt(tournamentId, 10)
    startAmericanoTournamentTrigger(
      {
        id: tournamentIDInt,
      },
      {
        onSuccess: () => mutate(),
        onError: (data) => {
          if (data.info.error.includes('tournament preset with ')) {
            setErrorMessage(
              `Incorrect number of courts for the registered number of teams.
              Please add or remove some courts on the edit page and try again.`
            )
          }
        },
      }
    )
  }

  const handleAddUserToTournament = (
    user = currentUser,
    anAdminAddedThePlayer = false
  ) => {
    if (!user) {
      setEasyRegisterOpen(true)
      return
    }

    addUserToTournamentTrigger(
      {
        tournament_id: Number(tournamentId),
        user_id: Number(user.id),
        an_admin_added_the_player: anAdminAddedThePlayer,
        // group_id == 0 is new group
        group_id: userGroupIdRef.current || 0,
      },
      {
        onSuccess: () => mutate(),
        onError: (data) => setErrorMessage(data.info.error),
      }
    )
  }

  const handleAddUserToTournamentWaitlist = (
    user = currentUser,
    anAdminAddedThePlayer = false
  ) => {
    if (!user) {
      setEasyRegisterWaitlistOpen(true)
      return
    }

    addUserToTournamentWaitlistTrigger(
      {
        tournament_id: Number(tournamentId),
        user_id: Number(user.id),
        an_admin_added_the_player: anAdminAddedThePlayer,
        // group_id == 0 is new group
        group_id: userGroupIdRef.current || 0,
      },
      {
        onSuccess: () => mutate(),
        onError: (data) => setErrorMessage(data.info.error),
      }
    )
  }

  const handleRemoveUserFromTournament = (user) => {
    removeUserFromTournamentTrigger(
      {
        tournament_id: Number(tournamentId),
        user_id: user.id,
      },
      {
        onSuccess: () => mutate(),
        onError: (data) => setErrorMessage(data.info.error),
      }
    )
  }

  const handleRemoveUserFromTournamentWaitlist = (user) => {
    removeUserFromTournamentWaitlistTrigger(
      {
        tournament_id: Number(tournamentId),
        user_id: user.id,
      },
      {
        onSuccess: () => mutate(),
        onError: (data) => setErrorMessage(data.info.error),
      }
    )
  }

  const handleUpdateTournament = () => {
    const changes = collectChanges()

    updateTournamentTrigger(
      {
        id: Number(tournamentId),
        ...changes,
      },
      {
        onSuccess: () => {
          mutate()
          setValue('readOnly', true)
        },
        onError: (data) => setErrorMessage(data.info),
      }
    )
  }

  const handleCancelUpdate = () => {
    reset()
  }

  const handlePseudoDeleteTournament = () => {
    const tournamentIDInt = parseInt(tournamentId, 10)

    pseudoDeleteTournamentTrigger(
      {
        id: tournamentIDInt,
      },
      {
        onSuccess: () => {
          navigate('/')
        },
      }
    )
  }

  const handleOpenStartTournamentModal = () => {
    setOpenStartTournamentModal(true)
  }

  const handleCloseStartTournamentModal = () => {
    setOpenStartTournamentModal(false)
  }

  const handleConfirmStartTournament = () => {
    handleStartTournament()
    setOpenStartTournamentModal(false)
  }

  const handleOpenDeleteTournamentModal = () => {
    setOpenDeleteTournamentModal(true)
  }

  const handleCloseDeleteTournamentModal = () => {
    setOpenDeleteTournamentModal(false)
  }

  const handleConfirmDeleteTournament = () => {
    handlePseudoDeleteTournament()
    setOpenDeleteTournamentModal(false)
  }

  const isUserRegistered = currentUser
    ? teams.some((team) => team.users.some((u) => u.id === currentUser.id))
    : false

  const tournamentAddress = tournamentData?.location?.name || organization_name
  const domain = process.env.REACT_APP_DOMAIN
  // mock for messagers
  const url = ' '

  const filledTeams = teams.filter((team) => team.users.length > 0)
  const emptySpaceNumber = Math.max(maxTeams - filledTeams.length, 0)

  const descriptionText = watch('description')
    ? `\n${watch('description')}\n`
    : ''

  const teamNames = teams
    .map((team) => {
      const teamMembers = team.users
        .map((user) => `${user.name} ${user.surname}`)
        .join(' / ')
      return `🎾 ${teamMembers}`
    })
    .join('\n')

  const waitlistTeamNames =
    waitlist_teams?.length > 0
      ? `
      waitlist
${waitlist_teams
  ?.map((team) => {
    const teamMembers = team.users
      .map((user) => `${user.name} ${user.surname}`)
      .join(' / ')
    return `🎾 ${teamMembers}`
  })
  .concat(['🎾 '])
  .join('\n')}`
      : `
      waitlist
🎾
`

  const emptySlots =
    emptySpaceNumber > 0 ? Array(emptySpaceNumber).fill('🎾 ').join('\n') : ''

  const title = `*Americano Team Tournament*

📍 ${tournamentAddress} 🎾
📆 ${dayjs(watch('startTime')).format('dddd DD MMMM')}      
⏰ ${dayjs(watch('startTime')).format('hh:mm a')} - ${dayjs(
    watch('endTime')
  ).format('hh:mm a')}
${descriptionText}
${teamNames}
${emptySlots}
${waitlistTeamNames}

👇🏼Join the tournament👇🏼
${domain}/tournament/${tournamentId}`

  return (
    <MainWrapper
      maxWidth="md"
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    >
      <FormProvider
        register={register}
        handleSubmit={handleSubmit}
        control={control}
        watch={watch}
        reset={reset}
        setValue={setValue}
      >
        <FormControl
          component="form"
          onSubmit={handleSubmit(handleUpdateTournament)}
          fullWidth
        >
          {watch('readOnly') ? (
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} sx={{ textAlign: 'center' }} mb={2}>
                <Typography variant="h5">{watch('name')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <TournamentInfo
                  tournamentData={tournamentData}
                  organizationData={organizationData}
                  themeMode={themeMode}
                />
              </Grid>

              {adminOrOrganizationAdmin && (
                <>
                  <Grid
                    item
                    md={4}
                    sx={{ display: { xs: 'none', md: 'block' } }}
                  />
                  <Grid item xs={12} md={4}>
                    <Button
                      fullWidth
                      onClick={() => setValue('readOnly', false)}
                      type="button"
                      startIcon={<EditIcon />}
                      variant="contained"
                    >
                      Edit
                    </Button>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    sx={{ display: { xs: 'none', md: 'block' } }}
                  />
                </>
              )}

              <Grid
                item
                xs={12}
                sx={{
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="h5">Share the tournament</Typography>
              </Grid>
              <Grid
                container
                spacing={1}
                item
                xs={12}
                sx={{
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Grid item>
                  <WhatsappShareButton url={url} title={title}>
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                </Grid>
                <Grid item>
                  <TelegramShareButton url={url} title={title}>
                    <TelegramIcon size={32} round />
                  </TelegramShareButton>
                </Grid>
                <Grid item>
                  <TwitterShareButton url={url} title={title}>
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                </Grid>
              </Grid>

              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Typography variant="h5">Registered Teams</Typography>
              </Grid>

              <Grid item xs={12} md={12}>
                <TournamentRegisteredTeams
                  teams={teams}
                  waitlistTeams={waitlist_teams}
                  maxTeams={watch('maxTeams')}
                  isAdmin={adminOrOrganizationAdmin}
                  currentUser={currentUser}
                  tournamentId={tournamentIdInt}
                  organizationId={organization_id}
                  exceptionIds={[
                    ...teams.flatMap((team) => team.users.map((u) => u.id)),
                    ...waitlist_teams.flatMap((team) =>
                      team.users.map((u) => u.id)
                    ),
                  ]}
                  setSelectUserOpen={setSelectUserOpen}
                  isUserRegistered={isUserRegistered}
                  handleAddUserToTournament={handleAddUserToTournament}
                  handleRemoveUserFromTournament={
                    handleRemoveUserFromTournament
                  }
                  updateUserGroupId={updateUserGroupId}
                  themeMode={themeMode}
                />
              </Grid>

              {watch('maxTeams') === teams.length && (
                <>
                  <Grid item xs={12} mt={1} sx={{ textAlign: 'center' }}>
                    <Typography variant="h5">Waitlist</Typography>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TournamentRegisteredWaitlistTeams
                      waitlistTeams={waitlist_teams}
                      isAdmin={adminOrOrganizationAdmin}
                      currentUser={currentUser}
                      updateUserGroupId={updateUserGroupId}
                      setSelectWaitlistUserOpen={setSelectWaitlistUserOpen}
                      isUserRegistered={isUserRegistered}
                      handleAddUserToTournamentWaitlist={
                        handleAddUserToTournamentWaitlist
                      }
                      handleRemoveUserFromTournamentWaitlist={
                        handleRemoveUserFromTournamentWaitlist
                      }
                      exceptionIds={[
                        ...teams.flatMap((team) => team.users.map((u) => u.id)),
                        ...waitlist_teams.flatMap((team) =>
                          team.users.map((u) => u.id)
                        ),
                      ]}
                      themeMode={themeMode}
                    />
                  </Grid>
                </>
              )}

              {left_users && left_users.length > 0 && (
                <>
                  <Grid item xs={12} mt={1} sx={{ textAlign: 'center' }}>
                    <Typography variant="h5">Players Left</Typography>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TournamentRegisteredLeftPlayers
                      leftPlayers={left_users}
                      themeMode={themeMode}
                    />
                  </Grid>
                </>
              )}

              {adminOrOrganizationAdmin && (
                <>
                  <Grid
                    item
                    md={4}
                    sx={{ display: { xs: 'none', md: 'block' } }}
                  />
                  <Grid item xs={12} md={4}>
                    <Button
                      style={{ width: '100%' }}
                      onClick={handleOpenStartTournamentModal}
                      type="button"
                      variant="contained"
                    >
                      Start tournament
                    </Button>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    sx={{ display: { xs: 'none', md: 'block' } }}
                  />
                </>
              )}
            </Grid>
          ) : (
            // TODO: move to separate component
            <Grid container spacing={2} mb={4} alignItems="flex-start">
              <Grid item xs={12}>
                <TextField
                  required={!watch('readOnly')}
                  style={{ width: '100%' }}
                  error={!!errors.name}
                  label="Tournament name"
                  InputProps={{
                    readOnly: watch('readOnly'),
                  }}
                  {...register('name', { required: true })}
                />
              </Grid>

              <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' } }} />
              <Grid item container spacing={2} xs={12} md={6}>
                <Grid item xs={12}>
                  {watch('organization')?.has_permanent_location ? (
                    <LocationBlock
                      location={watch('location')}
                      themeMode={themeMode}
                      withoutLink
                    />
                  ) : (
                    <LocationSelector
                      themeMode={themeMode}
                      currentLocation={watch('location')}
                      onLocationSelect={(location) => {
                        setValue('locationId', location.id, {
                          shouldValidate: true,
                        })
                        setValue('location', location, { shouldValidate: true })
                        setValue('hasPermanentLocation', true, {
                          shouldValidate: true,
                        })

                        if (
                          watch('locationId') !== tournamentData?.location?.id
                        ) {
                          setCourts([])
                        }
                      }}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' } }} />

              {(organizationData?.location || watch('location')) && (
                <Grid item xs={12}>
                  <LocationCourtsSelector
                    maxCourts={4}
                    courts={watch('location').courts}
                    initialSelectedCourts={courts}
                    onCourtToggle={(court, isSelected) => {
                      if (isSelected) {
                        setCourts([...courts, court])
                      } else {
                        setCourts(courts.filter((c) => c.id !== court.id))
                      }
                    }}
                  />
                </Grid>
              )}

              <Grid item container spacing={2} xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid item xs={12}>
                    <CustomDatePicker
                      sx={{ width: '100%' }}
                      label="Tournament date *"
                      control={control}
                      name="startTime"
                      value={dayjs(watch('startTime'))}
                      readOnly={watch('readOnly')}
                      ignoreValidation
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTimePicker
                      sx={{ width: '100%' }}
                      label="Start Time *"
                      control={control}
                      name="startTime"
                      value={dayjs(watch('startTime'))}
                      readOnly={watch('readOnly')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTimePicker
                      sx={{ width: '100%' }}
                      label="End time *"
                      control={control}
                      name="endTime"
                      value={dayjs(watch('endTime'))}
                      readOnly={watch('readOnly')}
                    />
                  </Grid>
                </LocalizationProvider>
              </Grid>

              <Grid item container spacing={2} xs={12} md={6}>
                <Grid
                  item
                  xs={12}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <TextField
                    required
                    style={{ flex: 1 }}
                    error={!!errors.maxTeams}
                    helperText={
                      !!errors.maxTeams &&
                      `Please, enter valid number in range (${
                        courts.length * 2
                      } - ${courts.length * 2 + 1}), or change the courts`
                    }
                    label={`Max Teams (${courts.length * 2} - ${
                      courts.length * 2 + 1
                    })`}
                    type="number"
                    {...register('maxTeams', {
                      required: true,
                      min: courts.length * 2,
                      max: courts.length * 2 + 1,
                      valueAsNumber: true,
                    })}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    required
                    style={{ width: '100%' }}
                    error={!!errors.cycleCount}
                    helperText={
                      !!errors.cycleCount &&
                      'Please, enter number equal or more than 1'
                    }
                    label="Schedule Repetition"
                    type="number"
                    {...register('cycleCount', {
                      required: true,
                      min: 1,
                      valueAsNumber: true,
                    })}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    required={!watch('readOnly')}
                    style={{ width: '100%' }}
                    error={!!errors.americanoMaxScore}
                    helperText={
                      !!errors.maxScore &&
                      'Please, enter number equal or more than 1'
                    }
                    label="Points to play"
                    {...register('americanoMaxScore', {
                      required: true,
                      valueAsNumber: true,
                    })}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  sx={{
                    width: '100%',
                    height: '100%',
                    '& .MuiInputBase-root': {
                      height: '100%',
                      alignItems: 'start',
                    },
                  }}
                  error={!!errors.description}
                  label="Description"
                  multiline
                  {...register('description')}
                  inputProps={{
                    readOnly: watch('readOnly'),
                    sx: {
                      maxHeight: '100%',
                    },
                  }}
                  maxRows={10}
                />
              </Grid>

              {adminOrOrganizationAdmin && (
                <>
                  <Grid
                    item
                    md={4}
                    sx={{ display: { xs: 'none', md: 'block' } }}
                  />
                  <Grid item xs={12} md={4} mb={2}>
                    <Button
                      color="error"
                      fullWidth
                      onClick={handleOpenDeleteTournamentModal}
                      variant="contained"
                    >
                      Delete tournament
                    </Button>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    sx={{ display: { xs: 'none', md: 'block' } }}
                  />

                  <Grid item xs={2} />
                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      onClick={handleCancelUpdate}
                      type="submit"
                      variant="contained"
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      type="submit"
                      disabled={
                        !Object.keys(collectChanges()).length ||
                        Object.keys(errors).length > 0 ||
                        parseInt(watch('maxUsers'), 10) < usersNumber
                      }
                      variant="contained"
                    >
                      Submit
                    </Button>
                  </Grid>
                  <Grid item xs={2} />
                </>
              )}
            </Grid>
            // TODO: move to separate component
          )}
        </FormControl>
      </FormProvider>

      <EasyRegisterDialog
        callback={handleAddUserToTournament}
        open={easyRegisterOpen}
        onClose={() => setEasyRegisterOpen(false)}
      />

      <EasyRegisterDialog
        callback={handleAddUserToTournamentWaitlist}
        open={easyRegisterWaitlistOpen}
        onClose={() => {
          setEasyRegisterWaitlistOpen(false)
        }}
      />

      <RegisterUserToTournamentDialog
        organizationId={organization_id}
        registeredPlayersNumber={usersNumber}
        waitlistRegisteredPlayersNumber={
          waitlist_teams?.flatMap((team) => team.users.map((u) => u.id)).length
        }
        callback={handleAddUserToTournament}
        exceptionIds={[
          ...teams.flatMap((team) => team.users.map((u) => u.id)),
          ...waitlist_teams.flatMap((team) => team.users.map((u) => u.id)),
        ]}
        open={selectUserOpen}
        onClose={() => setSelectUserOpen(false)}
      />
      <RegisterUserToTournamentDialog
        organizationId={organization_id}
        registeredPlayersNumber={usersNumber}
        waitlistRegisteredPlayersNumber={
          waitlist_teams?.flatMap((team) => team.users.map((u) => u.id)).length
        }
        callback={handleAddUserToTournamentWaitlist}
        exceptionIds={[
          ...teams.flatMap((team) => team.users.map((u) => u.id)),
          ...waitlist_teams.flatMap((team) => team.users.map((u) => u.id)),
        ]}
        open={selectWaitlistUserOpen}
        onClose={() => setSelectWaitlistUserOpen(false)}
      />
      <Dialog
        open={openStartTournamentModal}
        onClose={handleCloseStartTournamentModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to start the tournament?
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Once started, the following will be locked:
          </Typography>
          <List>
            <ListItem>
              <Typography variant="body2">- Teams and their number</Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2">- Courts and their number</Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2">
                - The maximum number of points
              </Typography>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseStartTournamentModal}
            color="primary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmStartTournament}
            color="primary"
            variant="contained"
            autoFocus
          >
            Start
          </Button>
        </DialogActions>
      </Dialog>

      <DeleteTournamentDialog
        openDeleteTournamentModal={openDeleteTournamentModal}
        handleCloseDeleteTournamentModal={handleCloseDeleteTournamentModal}
        handleConfirmDeleteTournament={handleConfirmDeleteTournament}
      />
    </MainWrapper>
  )
}
